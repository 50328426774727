import React, {Suspense, lazy} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {shallowEqual, useSelector} from 'react-redux'

import {FallbackView} from '../../_metronic/partials'
// import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
import {ClientWrapper} from '../pages/client/ClientWrapper'
import {ItemWrapper} from '../pages/item/ItemWrapper'
import {OrderWrapper} from '../pages/order/OrderWrapper'
import DebitDashboardPage from '../modules/debit-dashboard/DebitDashboardPage'
import MyProfilePage from '../modules/my-profile/MyProfilePage'
import OrderPage from '../modules/orders/OrdersPage'
import TransactionsPage from '../modules/transactions/TransactionsPage'
import ClientPage from '../modules/client/ClientPage'
import MasterWalletPage from '../modules/master-wallet/MasterWalletPage'
import {UserModel} from '../modules/auth/models/UserModel'
import {RootState} from '../../setup'
import PayInsPage from '../modules/pay-ins/PayInsPage'
import { Page } from '../modules/roles/models/role-model'
import ActivatedPageRoute from './ActivatedPageRoute'
import { PAGE_PERMISSION } from '../modules/roles/constant/role-default'
import ActivePageBasedRedirect from './ActivePageBasedRedirect'

type Props = {
  activePages: Page[]
};


const PrivateRoutes: React.FC<Props> = ({ activePages = [] }) => {
  const BuilderPageWrapper = lazy(() => import('../pages/layout-builder/BuilderPageWrapper'))
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UserPage = lazy<React.FC>(() => import('../modules/users/UserPage'))
  const RolePage = lazy<React.FC>(() => import('../modules/roles/RolePage'))
  const MasterWalletPage = lazy<React.FC>(() => import('../modules/master-wallet/MasterWalletPage'))
  const PayInsPage = lazy<React.FC>(() => import('../modules/pay-ins/PayInsPage'))
  const TransactionPage = lazy<React.FC>(() => import('../modules/transactions/TransactionsPage'))
  const OrderPage = lazy<React.FC>(() => import('../modules/orders/OrdersPage'))

  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel

  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        {/* <Route path='/dashboard1' component={DashboardWrapper} /> */}
        <Route path='/item' component={ItemWrapper} />
        {/* <Route path='/order' component={OrderWrapper} /> */}

        <ActivatedPageRoute
          path='/client'
          component={ClientPage}
          permission={PAGE_PERMISSION.Clients_ViewPage}
          activePages={activePages} />

        <ActivatedPageRoute
          path='/user'
          component={UserPage}
          permission={PAGE_PERMISSION.User_ViewPage}
          activePages={activePages} />

        <ActivatedPageRoute
          path='/role'
          component={RolePage}
          permission={PAGE_PERMISSION.Administrator_Roles_ViewPage}
          activePages={activePages} />


        <ActivatedPageRoute
          path='/order'
          component={OrderPage}
          permission={PAGE_PERMISSION.Orders_ViewPage}
          activePages={activePages} />

        <Route path='/my-profile' render={() => <MyProfilePage />} />

        <ActivatedPageRoute
          path='/master-wallet'
          component={MasterWalletPage}
          permission={PAGE_PERMISSION.MasterWallet}
          activePages={activePages} />

        <ActivatedPageRoute
          path='/transaction'
          component={TransactionPage}
          permission={PAGE_PERMISSION.Transactions_ViewPage}
          activePages={activePages} />

        <ActivatedPageRoute
          path='/payins'
          component={PayInsPage}
          permission={PAGE_PERMISSION.PayIns_ViewPage}
          activePages={activePages} />

        <Route
          path='/dashboard'
          render={() => {
            return user.isCreatedByDebit ? (
              <DebitDashboardPage />
            ) : (
              <Redirect to='/error/404' />
            );
          }}
        />

        <Route path='/builder' component={BuilderPageWrapper} />
        <Route path='/crafted/pages/profile' component={ProfilePage} />
        <Route path='/crafted/pages/wizards' component={WizardsPage} />
        <Route path='/crafted/widgets' component={WidgetsPage} />
        <Route path='/crafted/account' component={AccountPage} />
        <Route path='/apps/chat' component={ChatPage} />
        <Route path='/menu-test' component={MenuTestPage} />

        {!user.isCreatedByDebit && (
          <>
            {/* <Redirect from='/auth' to='/client' />
            <Redirect exact from='/' to='/client' /> */}
            <Route path='/auth' component={ActivePageBasedRedirect} />
            <Route exact path='/' component={ActivePageBasedRedirect}/>
          </>
        )}
        {user.isCreatedByDebit && (
          <>
            <Redirect from='/auth' to='/dashboard' />
            <Redirect exact from='/' to='/dashboard' />
          </>
        )}
        <Redirect to='error/404' />
      </Switch>
    </Suspense>
  )
}

export default PrivateRoutes;
