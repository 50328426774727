import {useIntl} from 'react-intl'
import * as yup from 'yup'

const DirectdebitFormValidationSchema = () => {
  const intl = useIntl()

  return yup.object().shape({
    firstName: yup
      .string()
      .required(intl.formatMessage({id: 'ECOMMERCE.VALIDATION.FIRSTNAMEREQUIRED'})),
    lastName: yup
      .string()
      .required(intl.formatMessage({id: 'ECOMMERCE.VALIDATION.LASTNAMEREQUIRED'})),
    email: yup
      .string()
      .email(intl.formatMessage({id: 'ECOMMERCE.VALIDATION.WRONGEMAILFORMAT'}))
      .max(50, intl.formatMessage({id: 'ECOMMERCE.VALIDATION.MAXIMUMSIMBOL'}))
      .required(intl.formatMessage({id: 'ECOMMERCE.VALIDATION.EMAILREQUIRED'})),
    addressLine1: yup
      .string()
      .required(intl.formatMessage({id: 'ECOMMERCE.VALIDATION.ADDRESSREQUIRED'})),
    suburb: yup.string().required(intl.formatMessage({id: 'ECOMMERCE.VALIDATION.SUBURBREQUIRED'})),
    state: yup.string().required(intl.formatMessage({id: 'ECOMMERCE.VALIDATION.STATEREQUIRED'})),
    postCode: yup
      .string()
      .required(intl.formatMessage({id: 'ECOMMERCE.VALIDATION.POSTCODEREQUIRED'})),
    mobile: yup.mixed().test({
      test: function (value) {
        const digitPattern = /^[0-9]+$/
        if (!value)
          return this.createError({
            message: intl.formatMessage({id: 'ECOMMERCE.VALIDATION.MOBILEREQUIRED'}),
          })
        else if (value.length > 20)
          return this.createError({
            message: intl.formatMessage({id: 'ECOMMERCE.VALIDATION.MOBILEMUSTBE'}),
          })
        else if (!digitPattern.test(value))
          return this.createError({
            message: intl.formatMessage({id: 'ECOMMERCE.VALIDATION.MOBILENUMBERIC'}),
          })
        return true
      },
    }),
    country: yup.mixed().test({
      test: function (value) {
        if (!value)
          return this.createError({
            message: intl.formatMessage({id: 'ECOMMERCE.VALIDATION.COUNTRYREQUIRED'}),
          })
        return true
      },
    }),
    bankName: yup
      .string()
      .required(intl.formatMessage({id: 'ECOMMERCE.VALIDATION.BANKNAMEREQUIRED'})),
    bankAccountName: yup
      .string()
      .required(intl.formatMessage({id: 'ECOMMERCE.VALIDATION.BANKACCOUNTNAMEREQUIRED'})),
    bankAccountNumber: yup
      .string()
      .required(intl.formatMessage({id: 'ECOMMERCE.VALIDATION.BANKACCOUNTNUMBERREQUIRED'}))
      .matches(
        /^\d*$/,
        intl.formatMessage({
          id: 'ECOMMERCE.VALIDATION.BANKACCOUNTNUMBERSHOULDONLYCONTAINNUMBERS',
        })
      ),
    bsb1: yup.mixed().test({
      test: function (value) {
        const digitPattern = /^[0-9]+$/
        if (value == null || value === '')
          return this.createError({
            message: intl.formatMessage({id: 'ECOMMERCE.VALIDATION.BSB1REQUIRED'}),
          })
        else if (!digitPattern.test(value))
          return this.createError({
            message: intl.formatMessage({id: 'ECOMMERCE.VALIDATION.BSB1MUSTBENUMERIC'}),
          })
        else if (value.length !== 3)
          return this.createError({
            message: intl.formatMessage({id: 'ECOMMERCE.VALIDATION.BSB1MUSTBEREQUIRED'}),
          })
        return true
      },
    }),
    bsb2: yup.mixed().test({
      test: function (value) {
        const digitPattern = /^[0-9]+$/
        if (value == null || value === '')
          return this.createError({
            message: intl.formatMessage({id: 'ECOMMERCE.VALIDATION.BSB2REQUIRED'}),
          })
        else if (!digitPattern.test(value))
          return this.createError({
            message: intl.formatMessage({id: 'ECOMMERCE.VALIDATION.BSB2MUSTBENUMERIC'}),
          })
        else if (value.length !== 3)
          return this.createError({
            message: intl.formatMessage({id: 'ECOMMERCE.VALIDATION.BSB2MUSTBEREQUIRED'}),
          })
        return true
      },
    }),
    debitAmount: yup
      .number()
      .typeError(intl.formatMessage({id: 'ECOMMERCE.VALIDATION.DEBITAMOUNTINVALID'}))
      .test({
        name: 'debitAmount',
        test: function (value) {
          if (!value || value === 0) {
            return this.createError({
              message: intl.formatMessage({id: 'ECOMMERCE.VALIDATION.DEBITAMOUNTREQUIRED'}),
            })
          }
          return true
        },
      })
      .nullable(),
    paymentCycle: yup
      .number()
      .typeError(intl.formatMessage({id: 'ECOMMERCE.VALIDATION.DEBITAMOUNTINVALID'}))
      .test({
        name: 'paymentCycle',
        test: function (value) {
          if (!value || value === 0) {
            return this.createError({
              message: intl.formatMessage({id: 'ECOMMERCE.VALIDATION.PAYMENTCYCLEREQUIRED'}),
            })
          }

          return true
        },
      })
      .nullable(),
    paymentCycleStartDate: yup
      .date()
      .typeError(intl.formatMessage({id: 'ECOMMERCE.VALIDATION.STARTDATEINVALID'}))
      .min(new Date(1900, 0, 1), intl.formatMessage({id: 'ECOMMERCE.VALIDATION.STARTDATEINVALID'}))
      .max(new Date(2900, 0, 1), intl.formatMessage({id: 'ECOMMERCE.VALIDATION.STARTDATEINVALID'}))
      .required(intl.formatMessage({id: 'ECOMMERCE.VALIDATION.STARTDATEREQUIRED'})),
  })
}

export default DirectdebitFormValidationSchema
