import {FC} from 'react'

import PersonalData from './client-form/PersonalData'
import IdentifyData from './client-form/IdentifyData'
import StatusData from './client-form/StatusData'
import DirectDebit from './client-form/DirectDebit'
import BankDetailsSection from './client-form/BankDetailSection'
import {IClientsData} from '../../models/client'
import PersonalCommonData from './client-form/PersonalCommonData'
import PaymentTerm from './client-form/PaymentTerm'

interface IProps {
  clientData: IClientsData | undefined
  register: any
  control: any
  setValue: any
  intl: any
  errors: any
  watch: any
  companyDetails: boolean
  getValues: any
  isIdentityDataFeatureEnabled: boolean
  isDirectDebitFormFeatureEnabled: boolean
  isUploadFileFeatureEnabled: boolean
  isClientDataFeatureEnabled: boolean
  duplicatedBankIndexes: {clientIndex: number; conflictingIndexes: number[], wrongNameIndexes: number[]}[]
  onSetBankListData(items: any): any
  isAllowedNoBankFeatureEnable: boolean
}

const ClientFormFields: FC<IProps> = ({
  register,
  control,
  intl,
  errors,
  setValue,
  clientData,
  watch,
  companyDetails,
  getValues,
  isIdentityDataFeatureEnabled,
  isDirectDebitFormFeatureEnabled,
  isUploadFileFeatureEnabled,
  isClientDataFeatureEnabled,
  duplicatedBankIndexes,
  onSetBankListData,
  isAllowedNoBankFeatureEnable
}) => {
  return (
    <>
      <PersonalData
        register={register}
        control={control}
        intl={intl}
        errors={errors}
        setValue={setValue}
        clientData={clientData}
        companyDetails={companyDetails}
        isClientDataFeatureEnabled={isClientDataFeatureEnabled}
      />
      {!companyDetails && (
        <PersonalCommonData
          register={register}
          control={control}
          intl={intl}
          errors={errors}
          setValue={setValue}
          clientData={clientData}
        />
      )}
      {!isIdentityDataFeatureEnabled && (
        <>
          <br />
          <hr />
          <IdentifyData
            register={register}
            control={control}
            intl={intl}
            errors={errors}
            setValue={setValue}
            clientData={clientData}
            getValues={getValues}
            watch={watch}
            isUploadFileFeatureEnabled={isUploadFileFeatureEnabled}
          />
        </>
      )}

      {companyDetails && (
        <>
          <br />
          <hr />
          <PersonalCommonData
            register={register}
            control={control}
            intl={intl}
            errors={errors}
            setValue={setValue}
            clientData={clientData}
          />
        </>
      )}

      <br />
      <hr />

      <BankDetailsSection
          register={register}
          control={control}
          intl={intl}
          errors={errors}
          setValue={setValue}
          clientData={clientData}
          duplicatedBankIndexes={duplicatedBankIndexes}
          onSetBankListData={onSetBankListData}
          isAllowedNoBankFeatureEnable={isAllowedNoBankFeatureEnable}
        />
        <br />
        <hr />

      <StatusData
        register={register}
        control={control}
        intl={intl}
        errors={errors}
        setValue={setValue}
        clientData={clientData}
      />
      
      {clientData && isDirectDebitFormFeatureEnabled && (
        <>
          <br />
          <hr />
          <DirectDebit
            clientData={clientData}
            register={register}
            control={control}
            setValue={setValue}
            intl={intl}
            watch={watch}
            errors={errors}
          />
        </>
      )}

      {clientData && isDirectDebitFormFeatureEnabled && (
        <>
          <br />
          <hr />
          <PaymentTerm
            clientData={clientData}
            register={register}
            control={control}
            setValue={setValue}
            intl={intl}
            watch={watch}
            errors={errors}
          />
        </>
      )}
    </>
  )
}

export default ClientFormFields
