import { IAlert } from "../../../shared/components/CustomAlert";
import { GridSetup, IRole } from "../models/role-model";



export const ROLE_FORM_DEFAULT: IRole = {
    id: '',
    name: '',
    displayId: 0,
    
}

export const Initial_GridSetup: GridSetup = {
    sort: [{ field: 'id', dir: 'desc' }],
    skip: 0,
    take: 10,
    filter: {
        logic: "and",
        filters: [],
    }
}

export const TOASTER_DEFAULT: IAlert = {
    message: ``,
    header: ``,
    type: 'success'
}

export enum PAGE_PERMISSION {
  Clients_ViewPage = "Clients.ViewPage",
  Clients_Edit = "Clients.Edit",
  Clients_Add = "Clients.Add",

  Orders_ViewPage = "Orders.ViewPage",
  Orders_Add = "Orders.Add",
  Orders_GenerateCsv = "Orders.GenerateCsv",

  Transactions_ViewPage = "Transactions.ViewPage",
  Transactions_CreateWithdrawal = "Transactions.CreateWithdrawal",
  Transactions_GenerateCsv = "Transactions.GenerateCsv",
  Transactions_Authorise = "Transactions.Authorise",

  MasterWallet = "MasterWallet",

  User_ViewPage = "User.ViewPage",
  User_Edit = "User.Edit",
  User_Add = "User.Add",
  User_Delete = "User.Delete",

  PayIns_ViewPage = "PayIns.ViewPage",
  PayIns_GenerateCsv = "PayIns.GenerateCsv",

  Administrator_Roles_ViewPage = "Administrator.Roles.ViewPage",
  Administrator_Roles_Add = "Administrator.Roles.Add",
  Administrator_Roles_Edit = "Administrator.Roles.Edit",
  Administrator_Roles_Delete = "Administrator.Roles.Delete",
  Administrator_Roles_ViewPermissions = "Administrator.Roles.ViewPermissions",
}
