import {DropDownList} from '@progress/kendo-react-dropdowns'

import {FC} from 'react'
import {Controller} from 'react-hook-form'
import {ErrorMessage} from '@hookform/error-message'
import { countriesData } from '../../../client/constant/countries'

interface IProps {
  register: any
  control: any
  setValue: any
  intl: any
  errors: any
}

const PersonalCommonData: FC<IProps> = ({
  register,
  control,
  setValue,
  intl,
  errors,
}) => {
  // Based on the alternative code structure, hard-coded with several conditional renderings.
  return (
    <div>
      <div className='row align-items-center mt-6 pe-2'>
        <div className='col-lg-6 col-md-6 col-12 mb-4'>
          <label className='form-label' htmlFor='addressLine1'>
            {intl.formatMessage({id: 'ECOMMERCE.FIELD.ADDRESS'})}
          </label>
          <span className='text-danger'>*</span>
          <input
            type='text'
            className={`form-control${errors.addressLine1 ? ' border-danger' : ''}`}
            placeholder={
              intl.formatMessage({id: 'ECOMMERCE.FIELD.ENTER'}) +
              ' ' +
              intl.formatMessage({id: 'ECOMMERCE.FIELD.ADDRESS'})
            }
            {...register('addressLine1')}
          />
          <ErrorMessage errors={errors} name='addressLine1' as={<span className='spanError text-danger' />} />
        </div>
        <div className='col-lg-6 col-md-6 col-12 mb-4'>
          <label className='form-label' htmlFor='addressLine2'>
            {intl.formatMessage({id: 'ECOMMERCE.FIELD.ADDRESS2'})}
          </label>
          <input
            type='text'
            className={`form-control${errors.addressLine2 ? ' border-danger' : ''}`}
            placeholder={
              intl.formatMessage({id: 'ECOMMERCE.FIELD.ENTER'}) +
              ' ' +
              intl.formatMessage({id: 'ECOMMERCE.FIELD.ADDRESS2'})
            }
            {...register('addressLine2')}
          />
        </div>
      </div>
      <div className='row align-items-center mt-6 pe-2'>
        <div className='col-lg-4 col-md-4 col-12 mb-4'>
          <label className='form-label' htmlFor='suburb'>
            {intl.formatMessage({id: 'ECOMMERCE.FIELD.SUBURB'})}
            <span className='text-danger'>*</span>
          </label>
          <input
            type='text'
            className={`form-control${errors.suburb ? ' border-danger' : ''}`}
            placeholder={
              intl.formatMessage({id: 'ECOMMERCE.FIELD.ENTER'}) +
              ' ' +
              intl.formatMessage({id: 'ECOMMERCE.FIELD.SUBURB'})
            }
            {...register('suburb')}
          />
          <ErrorMessage errors={errors} name='suburb' as={<span className='spanError text-danger' />} />
        </div>
        <div className='col-lg-4 col-md-4 col-12 mb-4'>
          <label className='form-label' htmlFor='state'>
            {intl.formatMessage({id: 'ECOMMERCE.FIELD.STATE'})}
            <span className='text-danger'>*</span>
          </label>
          <input
            type='text'
            className={`form-control${errors.state ? ' border-danger' : ''}`}
            placeholder={
              intl.formatMessage({id: 'ECOMMERCE.FIELD.ENTER'}) +
              ' ' +
              intl.formatMessage({id: 'ECOMMERCE.FIELD.STATE'})
            }
            {...register('state')}
          />
          <ErrorMessage errors={errors} name='state' as={<span className='spanError text-danger' />} />
        </div>
        <div className='col-lg-4 col-md-4 col-12 mb-4'>
          <label className='form-label' htmlFor='postCode'>
            {intl.formatMessage({id: 'ECOMMERCE.FIELD.POSTCODE'})}
            <span className='text-danger'>*</span>
          </label>
          <input
            type='text'
            className={`form-control${errors.postCode ? ' border-danger' : ''}`}
            placeholder={
              intl.formatMessage({id: 'ECOMMERCE.FIELD.ENTER'}) +
              ' ' +
              intl.formatMessage({id: 'ECOMMERCE.FIELD.POSTCODE'})
            }
            {...register('postCode')}
          />
          <ErrorMessage errors={errors} name='postCode' as={<span className='spanError text-danger' />} />
        </div>
      </div>
      <div className='row align-items-center mt-6 pe-2'>
        <div className='col-lg-4 col-md-4 col-12 mb-4'>
          <label className='form-label' htmlFor='country'>
            {intl.formatMessage({id: 'ECOMMERCE.FIELD.COUNTRY'})}
            <span className='text-danger'>*</span>
          </label>
          <Controller
            control={control}
            name='country'
            render={({field: {onChange, value}}) => {
              return (
                <DropDownList
                  className={`${errors.country ? ' border-danger' : ''}`}
                  data={countriesData}
                  textField='text'
                  filterable={false}
                  onChange={(e) => {
                    onChange(e.value.id)
                  }}
                  defaultValue={countriesData.find((x) => x.id === value)}
                  style={{width: '100%'}}
                />
              )
            }}
          />
          <ErrorMessage errors={errors} name='country' as={<span className='spanError text-danger' />} />
        </div>
      </div>
    </div>
  )
}

export default PersonalCommonData
