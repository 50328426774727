import { useInternationalization } from '@progress/kendo-react-intl'
import moment from 'moment'
import { PAGE_PERMISSION } from '../../modules/roles/constant/role-default'
import { Page } from '../../modules/roles/models/role-model'

const SQL_DATE_FORMAT = 'yyyyMMdd hh:mm:ss'
const mappings: any = {
  eq: "{0} = '{1}'",
  neq: "{0} != '{1}'",
  isnull: '{0} IS NULL',
  isnotnull: '{0} IS NOT NULL',
  lt: "{0} < '{1}'",
  lte: "{0} <= '{1}'",
  gt: "{0} > '{1}'",
  gte: "{0} >= '{1}'",
  startswith: "{0} LIKE '{1}%'",
  doesnotstartwith: "{0} NOT LIKE '{1}%'",
  contains: "{0} LIKE '%{1}%'",
  doesnotcontain: "{0} NOT LIKE '%{1}%'",
  isempty: "{0} = ''",
  isnotempty: "{0} != ''",
}
const transactionStatus: any = [
  { id: 'awaitingapproval', value: 'Waiting For Approval' },
  { id: 'pending', value: 'Pending' },
  { id: 'batched', value: 'Batched' },
  { id: 'invalid_account_details', value: 'Invalid Account Details' },
  { id: 'completed', value: 'Completed' },
  { id: 'bank_processing', value: 'Bank Processing' },
  { id: 'rejected', value: 'Rejected' },
]

export const hasPermission = (permission: PAGE_PERMISSION, activePages: Page[]) => {
  return activePages.some(page => page.name === permission)
}

export const toSQLExpression = (filter: any) => {
  if (!filter) return
  var { filters } = filter
  var result = ''
  for (let i = 0; i < filters.length; i++) {
    if (i !== 0) {
      result += ` ${filter.logic} `
    }

    var { operator, field, value } = filters[i]
    var mapping = mappings[operator]
    let type: typeof value

    if (type === 'date') {
      value = value.toString(value, SQL_DATE_FORMAT)
    }
    var query = mapping.replace('{0}', field).replace('{1}', value)
    result += query
  }

  return result
}

export const toObjectExpression = (filter: any) => {
  var data: any = {}
    ; ((filter !== null && filter.filters) || []).forEach((filter: any) => {
      var { field, value } = filter
      data[field] = value
    })
  return data
}

export const toLinQExpression = (filter: any) => {
  let data: any = ''
    ; ((filter !== null && filter.filters) || []).forEach((filter: any, index: number) => {
      if (index > 0) data += ' and '
      var { field, value, type } = filter

      if (typeof 0 === type) {
        data += `${field} = (${value})`
      } else if (typeof true === type) {
        if (field === 'InActive') {
          data += `${field}.equals(${value !== 'Active'} || ${value === 'Delivered'})`
        } else data += `${field}.equals(${value === 'Active'} || ${value === 'Delivered'})`
      } else if (typeof new Date() === type) {
        if (value) {
          const newValue = dateFormat(value)
          data += `${field} = DateTime.ParseExact("${newValue}", "dd/MM/yyyy", null)`
        }
      } else data += `${field}.contains("${value}")`
    })
  return data
}

// mm/dd/yyyy date args
export const dateFormat = (date: any) => {
  if (!date) return null
  date = new Date(date)
  return moment(date).format('DD/MM/YYYY')
}

// dd/mm/yyyy to dates
export const dateFormatDDMMYYY = (dateString: any) => {
  if (!dateString) return null
  var dateParts = dateString.split('/')

  return new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]).setSeconds(1)
}

export const validateDate = (date: Date) => {
  if (!moment(date).isValid()) return false

  if (date.getFullYear() < 1900) return false

  return true
}

export const CurrencyFormatter = (value: number) => {
  const intl = useInternationalization()
  if (!value) return intl.formatNumber(0, 'c')

  return intl.formatNumber(value, 'c')
}

export const AmountFormatter = (value: number, currency: string) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'decimal',
    maximumFractionDigits: 2,
  })

  let formattedNumber = formatter.format(value)
  if (formattedNumber.indexOf('.') < 0) {
    formattedNumber = `${formattedNumber}.00`
  }

  const decIndex = formattedNumber.indexOf('.')
  const dec = formattedNumber.substring(decIndex + 1)
  // add extra 0 if decimal is single number
  if (dec.length === 1) {
    formattedNumber = `${formattedNumber}0`
  }

  return `${currency} ${formattedNumber}`
}

export const userCanEdit = (roles?: string[]) => {
  const data = (roles || ['']).find((role: string) => role === 'Administrator')
  return data !== undefined
}

export const unique = (value: any, index: any, self: any) => {
  return self.indexOf(self.find((t: any) => t.clientId === value.clientId)) === index
}
export const filterToObject = (filter: any) => {
  var data = {}
    ; ((filter !== null && filter.filters) || []).forEach((filter: any) => {
      var { field, value } = filter
      if (value)
        data = {
          ...data,
          [field]: value,
        }
    })
  return data
}

export const capitalizeTest = (text: string): string => {
  return (text && text[0].toUpperCase() + text.slice(1)) || text
}

export const serializeMessage = (isJsonString: string) => {
  try {
    return JSON.parse(isJsonString)
  } catch (e) {
    return null
  }
}

export const serializeErrorResponse = (message: string): string => {
  const sMessage = serializeMessage(message)
  let errorMessage = ''

  if (sMessage) {
    if (sMessage.errors && Object.keys(sMessage.errors).length > 0) {
      for (const [key, value] of Object.entries(sMessage.errors)) {
        const nKey = key.replace('principal.', '').replace('principal', '')
        if (nKey !== '') {
          // Append the error message to errorMessage
          errorMessage += `${capitalizeTest(nKey)}: ${value}. `
        }
      }
    }
  } else {
    errorMessage = message
  }

  return errorMessage
}

export const transactionStatusMasking = (status: string) => {
  const resStatus = transactionStatus.find((x: any) => x.id === status)
  if (!resStatus) {
    return status
  }
  return resStatus.value
}
export const downloadFileFromBase64 = (base64PDFData: any, fileName: string, mimeType: string) => {
  var binaryData = atob(base64PDFData)
  var blob = new Blob([new Uint8Array(Array.from(binaryData).map((char) => char.charCodeAt(0)))], {
    type: mimeType,
  })

  // Create a URL for the Blob
  var url = window.URL.createObjectURL(blob)

  // Create a link element for download
  var a = document.createElement('a')
  a.href = url

  // Set the download attribute with the desired filename
  a.download = `${fileName}.csv`

  // Programmatically click the link to trigger the download
  a.click()

  // Clean up by revoking the Blob URL
  window.URL.revokeObjectURL(url)
}

export const blockNonNumericChars = (event: any) => {
  const regex = /\D+/
  const { key } = event
  if (regex.test(key)) {
    event.preventDefault()
  }
}

export const getUTCDate = (startDate: Date) => {
  const currentDate = new Date()
  // if selected picker equals to current date time then convert to utc date
  if (
    currentDate.getDate() == startDate.getDate() &&
    currentDate.getMonth() == startDate.getMonth() &&
    currentDate.getFullYear() == startDate.getFullYear()
  ) {
    const utcDate = new Date(startDate.toISOString())
    const result = new Date(
      utcDate.getFullYear(),
      utcDate.getMonth(),
      utcDate.getDate(),
      currentDate.getUTCHours(),
      currentDate.getUTCMinutes()
    )
    return result
  } else {
    // month/day/year from startDate and time(hour, mins) from currentDateTimeFromJavascript
    return new Date(
      startDate.getFullYear(),
      startDate.getMonth(),
      startDate.getDate(),
      currentDate.getHours(),
      currentDate.getMinutes()
    )
  }
}

export const getErrorMessage = (error: any, defaultErrorMessage?: string) => {
  let errorMessage = null
  if (!errorMessage && error.response && typeof error.response.data === 'string' && error.response.data)
    errorMessage = error.response.data
  if (!errorMessage && error.response && error.response.data && error.response.data.title)
    errorMessage = error.response.data.title
  if (!errorMessage && error.response && error.response.data && error.response.data.message)
    errorMessage = serializeErrorResponse(error.response.data.message)
  if (!errorMessage)
    errorMessage = defaultErrorMessage ?? 'An error occurred'
  return errorMessage;
}

export const deepCopy = (obj: any): any => {
  if (obj === null || typeof obj !== 'object') {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map(deepCopy);
  }

  const newObj: any = {};
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      newObj[key] = deepCopy(obj[key]);
    }
  }

  return newObj;
}

// dd/mm/yyyy to dates
export const getDateWithTimeString = (date: Date) => {

    const mm = (date.getMonth() + 1).toString().padStart(2, '0');
    const dd = date.getDate().toString().padStart(2, '0');
    const yy = date.getFullYear().toString().substr(-2);
    const hr = date.getHours().toString().padStart(2, '0');
    const min = date.getMinutes().toString().padStart(2, '0');
    
   return `${dd}/${mm}/${yy} ${hr}:${min}`;
}
