import React, {useMemo, useState} from 'react'

import {IAlert} from '../../../shared/components/CustomAlert'
import {CLIENT_DATA_FORM_DEFAULT, TOASTER_DEFAULT} from '../constant/client-default'
import {IOption, IClientsData} from '../models/client'

interface IProps {
  selectedData: IClientsData
  setSelectedData: (data: IClientsData) => void
  contextToaster: IAlert
  setContextToaster: (alert: IAlert) => void
  roleOptions: IOption[]
  //   getAllRolesAsync: (...args: any[]) => Promise<void>
  //   fetchingRole: boolean
}

export const ClientContext = React.createContext<IProps>({
  selectedData: CLIENT_DATA_FORM_DEFAULT,
  setSelectedData: (data: IClientsData) => {},
  contextToaster: TOASTER_DEFAULT,
  setContextToaster: (data: IAlert) => {},
  roleOptions: [],
  //   getAllRolesAsync: async (...args: any[]) => {},
  //   fetchingRole: false,
})

export const ClientContextProvider: React.FC = ({children}) => {
  const [selectedData, setSelectedData] = useState<IClientsData>(CLIENT_DATA_FORM_DEFAULT)
  const [contextToaster, setContextToaster] = useState<IAlert>(TOASTER_DEFAULT)
  const [roleOptions, setRoleOptions] = useState<IOption[]>([])

  //   const [{loading: fetchingRole}, getAllRolesAsync] = useAsyncFn(
  //     async (...args) => {
  //       const [keyword] = args
  //       const response = await getAllRoles(keyword)
  //       const roles = transformDataRoles(response.data)
  //       setRoleOptions(roles as any)
  //     },
  //     [setRoleOptions]
  //   )

  const value: IProps = useMemo(() => {
    return {
      selectedData,
      setSelectedData,
      contextToaster,
      setContextToaster,
      roleOptions,
      //   getAllRolesAsync,
      //   fetchingRole,
    }
  }, [
    selectedData,
    setSelectedData,
    contextToaster,
    setContextToaster,
    roleOptions,
    // getAllRolesAsync,
    // fetchingRole,
  ])

  return <ClientContext.Provider value={value}>{children}</ClientContext.Provider>
}
