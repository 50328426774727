import {TabStrip, TabStripSelectEventArguments, TabStripTab} from '@progress/kendo-react-layout'

import {FC, useCallback, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import moment from 'moment'

import {IClientsData} from '../../../models/client'
import {IClientWalletNpp} from '../../../models/client-wallet'
import {IClientVirtualAccount} from '../../../models/client-virtual-account'
import {createVirtualAccount, getBankList} from '../../../api/ClientApi'
import {transformedBankDataList} from '../../../transformer/client-transform'
import {Item} from '../../../models/item'
import { useFeatureFlags } from '../../../../../context/FeatureFlags'

interface IProps {
  clientData?: IClientsData[]
  clientWalletNpp?: IClientWalletNpp
  clientVirtualAccounts?: IClientVirtualAccount[]
}

const bankPageSize = 50

const ClientProfile: FC<IProps> = ({clientData, clientWalletNpp, clientVirtualAccounts}) => {
  const intl = useIntl()
  const {features} = useFeatureFlags()
  const [selected, setSelected] = useState({virtual: 0, bank: 0})
  const [bankListData, setBankListData] = useState<Item[]>([])
  const [isLoading, setIsloading] = useState<boolean>(false)
  const [isPayInsFeatureEnabled, setPayInsFeatureEnabled] = useState(false)

  useEffect(() => {
    if (features.some((feature) => feature.name === 'PayInsFeature')) {
      setPayInsFeatureEnabled(true)
    }
  })
  

  useEffect(() => {
    requestData(0)
  }, [clientData])

  const requestData = useCallback((skip: number) => {
    fetchDropdownDataBank(bankPageSize, skip, '')
  }, [])

  const handleSelect = (e: TabStripSelectEventArguments, type: string) => {
    if (type === 'bank') setSelected({...selected, bank: e.selected})
    else setSelected({...selected, virtual: e.selected})
  }

  const fetchDropdownDataBank = async (take: any, skip: any, search: any) => {
    const dataFromApi = await getBankList(take, skip, search) // Replace 'getBankList()' with your API call
    const transFormBankData = transformedBankDataList(dataFromApi)
    setBankListData(transFormBankData)
  }

  const newVirtualAccountHandler = useCallback(() => {
    createVirtualAccountAsync()
  }, [])

  const createVirtualAccountAsync = async () => {
    if (clientData && clientData.length > 0) {
      setIsloading(true)
      let clientId = +(clientData[0].id || 0) 
      const {response}: any = await createVirtualAccount(clientId)
      if (response) {
        setIsloading(false)
        return;
      }
      document.location.reload()
    }
  }
  

  return (
    <div className='row'>
      <div className='col-12 col-md-6 col-lg-6'>
        <div className='form-group'>
          <label className='form-label' htmlFor='addressLine1'>
            {intl.formatMessage({id: 'ECOMMERCE.FIELD.ADDRESS'})}
          </label>
          <input
            type='text'
            className='form-control'
            value={(clientData && clientData[0].addressLine1) || ''}
            disabled
          />
        </div>
        <div className='form-group mt-2'>
          <label className='form-label' htmlFor='suburb'>
            {intl.formatMessage({id: 'ECOMMERCE.FIELD.SUBURB'})}
          </label>
          <input
            type='text'
            className='form-control'
            value={(clientData && clientData[0].suburb) || ''}
            disabled
          />
        </div>
        <div className='form-group mt-2'>
          <label className='form-label' htmlFor='state'>
            {intl.formatMessage({id: 'ECOMMERCE.FIELD.STATE'})}
          </label>
          <input
            type='text'
            className='form-control'
            value={(clientData && clientData[0].state) || ''}
            disabled
          />
        </div>
        <div className='form-group mt-2'>
          <label className='form-label' htmlFor='postCode'>
            {intl.formatMessage({id: 'ECOMMERCE.FIELD.POSTCODE'})}
          </label>
          <input
            type='text'
            className='form-control'
            value={(clientData && clientData[0].postCode) || ''}
            disabled
          />
        </div>
        <div className='form-group mt-2'>
          <label className='form-label' htmlFor='country'>
            {intl.formatMessage({id: 'ECOMMERCE.FIELD.COUNTRY'})}
          </label>
          <input
            type='text'
            className='form-control'
            value={(clientData && clientData[0].country) || ''}
            disabled
          />
        </div>
        <div className='form-group mt-2'>
          <label className='form-label' htmlFor='mobile'>
            {intl.formatMessage({id: 'ECOMMERCE.FIELD.MOBILENUMBER'})}
          </label>
          <input
            type='text'
            className='form-control'
            value={(clientData && clientData[0].mobile) || ''}
            disabled
          />
        </div>
        <div className='form-group mt-2'>
          <label className='form-label' htmlFor='dateOfBirth'>
            {intl.formatMessage({id: 'ECOMMERCE.FIELD.DATEOFBIRTH'})}
          </label>
          <input
            type='text'
            className='form-control'
            value={moment(clientData && clientData[0].dateOfBirth).format('DD/MM/YYYY') || ''}
            disabled
          />
        </div>
      </div>
      <div className='col-12 col-md-6 col-lg-6'>
        <div className='form-group'>
          <label className='form-label' htmlFor='paymentTerms'>
            {intl.formatMessage({id: 'ECOMMERCE.FIELD.VIRTUALACCOUNT'})}
          </label>
        </div>
        { clientVirtualAccounts && clientVirtualAccounts.length > 0 ? (
          <TabStrip selected={selected.virtual} onSelect={(e) => handleSelect(e, 'account')}>
            {clientVirtualAccounts.map((account, index) => (
              <TabStripTab key={index} contentClassName='col-12' title={`Account ${index + 1}`}>
                <div className='form-group'>
                  <label className='form-label' htmlFor='bsb'>
                    {intl.formatMessage({id: 'ECOMMERCE.FIELD.BSB'})}
                  </label>
                  <input
                    type='text'
                    className='form-control'
                    value={account.routingNumber}
                    disabled
                  />
                </div>
                <div className='form-group mt-2'>
                  <label className='form-label' htmlFor='bankAccountNumber'>
                    {intl.formatMessage({id: 'ECOMMERCE.FIELD.ACCOUNTNUMBER'})}
                  </label>
                  <input
                    type='text'
                    className='form-control'
                    value={account.accountNumber}
                    disabled
                  />
                </div>
                <div className='form-group mt-2'>
                  <label className='form-label' htmlFor='status'>
                    {intl.formatMessage({id: 'ECOMMERCE.FIELD.STATUS'})}
                  </label>
                  <input
                    type='text'
                    className='form-control'
                    value={account.status}
                    disabled
                  />
                </div>
              </TabStripTab>
            ))}
          </TabStrip>
        ) : (
          <div className='form-group mt-2'>
            {/* <label className='form-label' htmlFor='noRecords'>
              {intl.formatMessage({id: 'ECOMMERCE.VALIDATION.NORECORDS'})}
            </label> */}
            {
              isPayInsFeatureEnabled ? (
                <button type='button' className='btn btn-primary' onClick={newVirtualAccountHandler} disabled={isLoading}>
                  {intl.formatMessage({id: 'ECOMMERCE.FIELD.CREATEVIRTUALACCOUNT'})}
              </button>
              ) : (
                <div className='form-group mt-2'>
                <label className='form-label' htmlFor='bsb'>
                    {intl.formatMessage({id: 'ECOMMERCE.VALIDATION.NORECORDS'})}
                  </label>
                </div>
              )
            } 
           
          </div>
        )}

        <div className='form-group mt-8'>
          <label className='form-label' htmlFor='nppDetails'>
            {intl.formatMessage({id: 'ECOMMERCE.FIELD.NPPDETAILS'})}
          </label>
        </div>
        <div className='form-group mt-2'>
          <label className='form-label' htmlFor='pay_id'>
            {intl.formatMessage({id: 'ECOMMERCE.FIELD.PAYID'})}
          </label>
          <input type='text' className='form-control' value={clientWalletNpp?.pay_id} disabled />
        </div>
        <div className='form-group mt-2'>
          <label className='form-label' htmlFor='reference'>
            {intl.formatMessage({id: 'ECOMMERCE.FIELD.REFERENCE'})}
          </label>
          <input type='text' className='form-control' value={clientWalletNpp?.reference} disabled />
        </div>
      </div>
      <div className='col-12 mt-8'>
        <div className='form-group'>
          <label className='form-label' htmlFor='bankDetails'>
            {intl.formatMessage({id: 'ECOMMERCE.FIELD.BANKDETAILS'})}
          </label>
        </div>

        {clientData && clientData[0].banks.length > 0 ? (
          <TabStrip selected={selected.bank} onSelect={(e) => handleSelect(e, 'bank')}>
            {clientData[0].banks.map((bank, index) => (
              <TabStripTab
                key={index}
                contentClassName='personal-bank-details col-12'
                title={`Bank ${index + 1}`}
              >
                <div className='form-group'>
                  <label className='form-label' htmlFor='zaiBankId'>
                    {intl.formatMessage({id: 'ECOMMERCE.FIELD.ID'})}
                  </label>
                  <input type='text' className='form-control' value={bank.zaiBankId} disabled />
                </div>
                <div className='form-group'>
                  <label className='form-label' htmlFor='bankName'>
                    {intl.formatMessage({id: 'ECOMMERCE.FIELD.BANKNAME'})}
                  </label>
                  <input
                    type='text'
                    className='form-control'
                    value={bankListData.find((b) => b.name === bank.bankName) ? bank.bankName : ''}
                    disabled
                  />
                </div>
                <div className='form-group mt-2'>
                  <label className='form-label' htmlFor='bsb'>
                    {intl.formatMessage({id: 'ECOMMERCE.FIELD.BSB'})}
                  </label>
                  <input
                    type='text'
                    className='form-control'
                    value={bank.bsb1 + '-' + bank.bsb2}
                    disabled
                  />
                </div>
                <div className='form-group mt-2'>
                  <label className='form-label' htmlFor='bankAccountNumber'>
                    {intl.formatMessage({id: 'ECOMMERCE.FIELD.ACCOUNTNUMBER'})}
                  </label>
                  <input
                    type='text'
                    className='form-control'
                    value={bank.bankAccountNumber}
                    disabled
                  />
                </div>
                <div className='form-group mt-2'>
                  <label className='form-label' htmlFor='bankAccountName'>
                    {intl.formatMessage({id: 'ECOMMERCE.FIELD.BANKACCOUNTNAME'})}
                  </label>
                  <input
                    type='text'
                    className='form-control'
                    value={bank.bankAccountName}
                    disabled
                  />
                </div>
                <div className='form-group mt-2'>
                  <label className='form-label' htmlFor='bankBranch'>
                    {intl.formatMessage({id: 'ECOMMERCE.FIELD.BANKBRANCH'})}
                  </label>
                  <input type='text' className='form-control' value={bank.bankBranch} disabled />
                </div>
                <div className='form-group mt-2'>
                  <label className='form-label' htmlFor='bankDescription'>
                    {intl.formatMessage({id: 'ECOMMERCE.FIELD.DESCRIPTION'})}
                  </label>
                  <input
                    type='text'
                    className='form-control'
                    value={bank.bankDescription}
                    disabled
                  />
                </div>
              </TabStripTab>
            ))}
          </TabStrip>
        ) : (
          <div className='form-group mt-2'>
            <label className='form-label' htmlFor='noRecords'>
              {intl.formatMessage({id: 'ECOMMERCE.VALIDATION.NORECORDS'})}
            </label>
            <input
              type='text'
              className='form-control'
              value={moment(clientData && clientData[0].dateOfBirth).format('DD/MM/YYYY')}
              disabled
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default ClientProfile
