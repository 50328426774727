import {ChangeEvent, FC, useEffect, useState} from 'react'
import {Controller} from 'react-hook-form'
import {DatePicker} from '@progress/kendo-react-dateinputs'
import {ErrorMessage} from '@hookform/error-message'
import {NumericTextBox} from '@progress/kendo-react-inputs'

import CustomCalendar from './CustomCalendar'
import {PaymentCycleType} from '../../../constant/config-default'
import {IClientsData} from '../../../models/client'

interface IProps {
  clientData: IClientsData | undefined
  register: any
  control: any
  setValue: any
  intl: any
  watch: any
  errors: any
}

const DirectDebit: FC<IProps> = ({
  clientData,
  register,
  control,
  setValue,
  intl,
  watch,
  errors,
}) => {
  // Based on the alternative code structure, hard-coded with several conditional renderings.
  return (
    <div>
      <input type='hidden' {...register('debitInformation')} value='1' />
      <div className='row align-items-center mt-6 pe-2'>
        <div className='col-lg-6 col-md-6 col-12'>
          <label className='form-label' htmlFor='paymentTerms'>
            {intl.formatMessage({id: 'ECOMMERCE.FIELD.DIRECTDEBITINFORMATION'})}
          </label>
        </div>
      </div>
      <div className='row align-items-center pe-2'>
        <div className='col-lg-6 col-md-6 col-12 mt-4 mb-4'>
          <label className='form-label' htmlFor='paymentCycle'>
            {intl.formatMessage({id: 'ECOMMERCE.FIELD.DEBITAMOUNT'})}
            <span className='text-danger'>*</span>
          </label>
          <Controller
            control={control}
            name='debitAmount'
            render={({field: {value, name, onChange}}) => {
              return (
                <NumericTextBox
                  name={name}
                  width='100%'
                  value={value}
                  defaultValue={0}
                  onChange={onChange}
                  format={'n2'}
                  min={0} // Set the minimum value to 0
                  className={`${errors.debitAmount ? ' border-danger' : ''}`}
                />
              )
            }}
          />
          <ErrorMessage
            errors={errors}
            name='debitAmount'
            as={<span className='spanError text-danger' />}
          />
        </div>
      </div>
    </div>
  )
}

export default DirectDebit
