import {FC, Fragment, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'

import ClientProfile from './ClientProfile'
import {IClientProfile} from '../../../models/client-profile'
import {ClientProfileTab} from '../../../constant/config-default'
import ClientWallet from './ClientWallet'
import ClientOrders from './ClientOrders'
import ClientTransactions from './ClientTransactions'
import ClientFile from './ClientFile'
import ClientPoc from './ClientPoc'
import {useFeatureFlags} from '../../../../../context/FeatureFlags'
import { ClientPayIns } from './ClientPayIns'
import { FeatureFlagProvider } from '../../../../feature-flag/FeatureFlagProvider'

interface IProps {
  clientData?: IClientProfile
}

const ClientProfileDashboard: React.FC<IProps> = ({clientData}) => {
  const intl = useIntl()
  const [selectedTab, setSelectedTab] = useState(ClientProfileTab.Profile)
  const handleSelect = (e: any) => {
    setSelectedTab(e.selected)
  }
  const {features} = useFeatureFlags()
  const [isPlentyPayHideFeatureEnabled, setIsPlentyPayHideFeatureEnabled] = useState(false)
  const [isIdentityDataFeatureEnabled, setIsIdentityDataFeatureEnabled] = useState(false)
  const [isMultiplePOCFeatureEnabled, setIsMultiplePOCFeatureEnabled] = useState(false)

  useEffect(() => {
    if (features.some((feature) => feature.name === 'PlentyPayHideFeature')) {
      setIsPlentyPayHideFeatureEnabled(true)
    }
    if (features.some((feature) => feature.name === 'IdentityDataFeature')) {
      setIsIdentityDataFeatureEnabled(true)
    }
    if (features.some((feature) => feature.name === 'MultiplePOCFeature')) {
      setIsMultiplePOCFeatureEnabled(true)
    }
  }, [features])

  return (
    <Fragment>
      <div className='d-flex flex-column'>
        <h3>
          {clientData?.client[0].clientType === 2
            ? clientData?.client[0].companyName
            : clientData?.client[0].firstName + ' ' + clientData?.client[0].lastName}
        </h3>
        <div>{clientData?.client[0].email}</div>
        <div>{clientData?.client[0].country}</div>
        <div>{clientData?.client[0].mobile}</div>
      </div>
      <div className='row mt-5'>
        <div className='col'>
          <div className='d-flex flex-row bd-highlight mb-5'>
            <button
              className={`btn btn-tab ${
                selectedTab === ClientProfileTab.Profile ? 'selected' : ''
              } me-3`}
              onClick={() => setSelectedTab(ClientProfileTab.Profile)}
            >
              {intl.formatMessage({id: 'MENU.CLIENTPROFILE.PROFILE'})}
            </button>
            {!isMultiplePOCFeatureEnabled && clientData?.client[0].clientType === 2 && (
              <button
                className={`btn btn-tab ${
                  selectedTab === ClientProfileTab.Poc ? 'selected' : ''
                } me-3`}
                onClick={() => setSelectedTab(ClientProfileTab.Poc)}
              >
                {intl.formatMessage({id: 'MENU.CLIENTPROFILE.POC'})}
              </button>
            )}
            <button
              className={`btn btn-tab ${
                selectedTab === ClientProfileTab.Wallet ? 'selected' : ''
              } me-3`}
              onClick={() => setSelectedTab(ClientProfileTab.Wallet)}
            >
              {intl.formatMessage({id: 'MENU.CLIENTPROFILE.WALLET'})}
            </button>
            <button
              className={`btn btn-tab ${
                selectedTab === ClientProfileTab.Orders ? 'selected' : ''
              } me-3`}
              onClick={() => setSelectedTab(ClientProfileTab.Orders)}
            >
              {intl.formatMessage({id: 'MENU.CLIENTPROFILE.ORDERS'})}
            </button>
            <button
              className={`btn btn-tab ${
                selectedTab === ClientProfileTab.Transactions ? 'selected' : ''
              } me-3`}
              onClick={() => setSelectedTab(ClientProfileTab.Transactions)}
            >
              {intl.formatMessage({id: 'MENU.CLIENTPROFILE.TRANSACTIONS'})}
            </button>
            {!isIdentityDataFeatureEnabled && !isPlentyPayHideFeatureEnabled && (
              <button
                className={`btn btn-tab ${selectedTab === ClientProfileTab.file ? 'selected' : ''}  me-3`}
                onClick={() => setSelectedTab(ClientProfileTab.file)}
              >
                {intl.formatMessage({id: 'MENU.CLIENTPROFILE.FILES'})}
              </button>
            )}
            <FeatureFlagProvider featureName='PayInsFeature'>
              {
                clientData && clientData?.virtualAccounts.length > 0 && (
                  <button
                    className={`btn btn-tab ${
                      selectedTab === ClientProfileTab.payIns ? 'selected' : ''
                    } me-3`}
                    onClick={() => setSelectedTab(ClientProfileTab.payIns)}
                  >
                    {intl.formatMessage({id: 'MENU.CLIENTPROFILE.PAYINS'})}
                </button>
                )
              }
            
            </FeatureFlagProvider>
          </div>

          {selectedTab === ClientProfileTab.Profile && (
            <ClientProfile
              clientData={clientData?.client}
              clientWalletNpp={clientData?.walletNpp}
              clientVirtualAccounts={clientData?.virtualAccounts}
            />
          )}

          {selectedTab === ClientProfileTab.Poc && clientData?.client[0].clientType === 2 && (
            <ClientPoc clientData={clientData?.client} />
          )}

          {selectedTab === ClientProfileTab.Wallet && (
            <ClientWallet
              clientWallet={clientData?.wallet}
              balance={clientData?.balance || 0}
              clientWalletBpay={clientData?.walletBpayResponse}
            />
          )}
          {selectedTab === ClientProfileTab.Orders && (
            <ClientOrders clientOrders={clientData?.orders}></ClientOrders>
          )}
          {selectedTab === ClientProfileTab.Transactions && (
            <ClientTransactions
              clientTransactions={clientData?.transactions}
              clientId={clientData?.client[0].unqId || ''}
            />
          )}
          {selectedTab === ClientProfileTab.file && (
            <ClientFile clientData={clientData?.client} clientFiles={clientData?.files} />
          )}
          <FeatureFlagProvider featureName='PayInsFeature'>
            {selectedTab === ClientProfileTab.payIns && (clientData && clientData?.virtualAccounts.length > 0) && (
              <ClientPayIns clientId={+((clientData?.client ? clientData?.client[0].id : 0) || 0)} />
            )}
         </FeatureFlagProvider>

      
        </div>
      </div>
    </Fragment>
  )
}

export default ClientProfileDashboard
