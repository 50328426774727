import {FC} from 'react'
import {useLang} from './Metronici18n'
import { LocalizationProvider } from '@progress/kendo-react-intl';


import {
  loadMessages,
  load
} from "@progress/kendo-react-intl";
import { IntlProvider } from 'react-intl';

import kdEnMessages from './messages/kendo-message/en/datepicker.json'
import kdZhMessages from './messages/kendo-message/zh/datepicker.json'
import likelySubtags from "cldr-core/supplemental/likelySubtags.json";
import currencyData from "cldr-core/supplemental/currencyData.json";
import weekData from "cldr-core/supplemental/weekData.json";
import numbers from "cldr-numbers-full/main/zh/numbers.json";
import caGregorian from "cldr-dates-full/main/zh/ca-gregorian.json";
import dateFields from "cldr-dates-full/main/zh/dateFields.json";
import timeZoneNames from "cldr-dates-full/main/zh/timeZoneNames.json";

load(
  likelySubtags,
  currencyData,
  weekData,
  numbers,
  caGregorian,
  dateFields,
  timeZoneNames
);

const KendoToolI18Provider: FC = ({children}) => {
  const locale = useLang()

  loadMessages(kdEnMessages, 'en');
  loadMessages(kdZhMessages, 'zh');

  // const getKendoLang = (locale: string) => {
  //   if (locale === 'en') {
  //     return 'en-US'
  //   } 
  //   else if (locale === 'zh') {
  //     return 'zh-CN'
  //   } else return 'en-US'
  // } 

  return (
    <IntlProvider  locale={locale}>
      {children}
    </IntlProvider >
  )
}

export { KendoToolI18Provider }
