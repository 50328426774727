import {Grid, GridColumn as Column, GridPageChangeEvent} from '@progress/kendo-react-grid'
import { KendoI18Provider } from "../../../../../../_metronic/i18n/kendoI18Provider"
import { useIntl } from 'react-intl'
import { IClientPayIn } from '../../../models/client-payin'
import { useCallback, useEffect, useState } from 'react'
import { getPayIdsByClientPagination } from '../../../api/ClientApi'
import { Initial_GridSetup } from '../../../constant/config-default'
import { IGridSetup } from '../../../models/client'
import { DateFormatCell } from '../../../../../shared/components/kendo/format/DateFormatCell'

interface IProps {
    clientId: number
  }
  
  const ClientPayIns: React.FC<IProps> = ({clientId}) => {
    const intl = useIntl()
    const [tableData, setTableData] = useState<IClientPayIn[]>([])
    const [gridSetup, setGridSetup] = useState<IGridSetup>(Initial_GridSetup)
    const [totalRows, setTotalRows] = useState<number>(0)
    
    useEffect(() => {
        requestData()
    }, [gridSetup])

    const requestData = useCallback(() => {
        fetchPayIds()
    }, [])
    
    const fetchPayIds = async () => {
        const { skip, take } = gridSetup
        const dataFromApi = await getPayIdsByClientPagination(clientId, skip, take)
        if (dataFromApi.items) {
            setTableData(dataFromApi.items)
            setTotalRows(dataFromApi.totalCount)
        }
    }

    const handlePageChange = (pageProps: GridPageChangeEvent) => {
        setGridSetup({
            ...gridSetup,
            skip: pageProps.page.skip,
            take: pageProps.page.take,
        })
    }

    return (
        <KendoI18Provider>
          <Grid 
            resizable={true} 
            data={tableData}
            pageable={true}
            onPageChange={handlePageChange}
            total={totalRows}
            {...gridSetup}
            >
            <Column
                title={intl.formatMessage({id: 'ECOMMERCE.FIELD.PAYID'})}
                field='payInId'
            />
            <Column
                title={intl.formatMessage({id: 'ECOMMERCE.FIELD.BSB'})}
                field='bsb'
            />
            <Column
                title={intl.formatMessage({id: 'ECOMMERCE.FIELD.ACCOUNTNUMBER'})}
                field='accountNumber'
            />
            <Column
                title={intl.formatMessage({id: 'ECOMMERCE.FIELD.PAYMENTAMOUNT'})}
                field='amountFormatted'
            />
            <Column
                title={intl.formatMessage({id: 'ECOMMERCE.FIELD.DEBTORNAME'})}
                field='debtorName'
            />
            <Column
                title={intl.formatMessage({id: 'ECOMMERCE.FIELD.TYPEMETHOD'})}
                field='typeMethod'
            />
              <Column
                title={intl.formatMessage({id: 'ECOMMERCE.FIELD.REMITTANCEINFORMATION'})}
                field='remittanceInformation'
            />
            <Column
                    field='created'
                    title={intl.formatMessage({ id: 'ECOMMERCE.FIELD.CREATEDDATE' })}
                    format={'{0:dd/MM/yyy}'}
                    cell={DateFormatCell}
                    width={150}
                    />
          </Grid>
        </KendoI18Provider>
    )
  }

  export {ClientPayIns}