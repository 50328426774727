import { DatePicker } from '@progress/kendo-react-dateinputs'
import { ComboBoxFilterChangeEvent, ComboBoxPageChangeEvent, DropDownList } from '@progress/kendo-react-dropdowns'
import { NumericTextBox } from '@progress/kendo-react-inputs'
import { yupResolver } from '@hookform/resolvers/yup'

import { useEffect, useState, FC, useRef, useCallback, Fragment, ChangeEvent } from 'react'
import { Controller, Resolver, useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { ErrorMessage } from '@hookform/error-message'

import { CustomAlert, IAlert } from '../../../../shared/components/CustomAlert'
import { CLIENT_DATA_FORM_DEFAULT } from '../../constant/client-default'
import { IClientsData } from '../../models/client'
import DirectdebitFormValidationSchema from '../../validators/direct-debit-form'
import { getBankList, directDebitCreateClient } from '../../api/ClientApi'
import {
  blockNonNumericChars,
  getErrorMessage,
  getUTCDate,
} from '../../../../shared/service/utils'
import { countriesData } from '../../constant/countries'
import { transformedBankDataList } from '../../transformer/client-transform'
import { toAbsoluteUrl } from '../../../../../_metronic/helpers/AssetHelpers'
import { DirectDebitModal } from '../../../../../_metronic/partials/modals/DirectDebitModal'
import { paymentCycleOptions } from '../../constant/date'

interface IProps {
  clientData?: IClientsData
  isDirectDebitAgreementFeatureEnabled?: boolean
}

interface Item {
  id: number
  name: string
}

interface IPdf {
  blobPdf: Blob
  fileName: string
}

const textField = 'name'
const keyField = 'id'

const emptyItem: Item = { [textField]: 'loading ...', id: 0 }

const pageSize = 50

const loadingPanel = (
  <div className='position-fixed k-loading-mask'>
    <span className='k-loading-text'>Loading</span>
    <div className='k-loading-image'></div>
    <div className='k-loading-color'></div>
  </div>
)

const DirectDebitForm: FC<IProps> = ({ clientData, isDirectDebitAgreementFeatureEnabled }) => {
  const intl = useIntl()
  const skipRef = useRef(0)

  const [formNotification, setformNotification] = useState<IAlert | undefined>()
  const [isDisabled] = useState(false)
  const [isSaving, setIsSaving] = useState(false)

  const [showModal, setShowModal] = useState(false)
  const [isChecked, setIsChecked] = useState(false)

  const directdebitFormValidationSchema = DirectdebitFormValidationSchema()
  const {
    register,
    control,
    handleSubmit,
    formState: { errors, isValid },
    getValues,
    reset,
    trigger,
    setValue,
  } = useForm({
    defaultValues: clientData,
    reValidateMode: 'onSubmit',
    resolver: yupResolver(directdebitFormValidationSchema) as unknown as Resolver<IClientsData, any>,
  })

  const loadingData: Item[] = []
  while (loadingData.length < pageSize) {
    loadingData.push({ ...emptyItem })
  }

  const dataCaching = useRef<any>([])
  const pendingRequest = useRef<any>()
  const requestStarted = useRef(false)
  const [data, setData] = useState<Item[]>([])
  const [total, setTotal] = useState(0)
  const [filter, setFilter] = useState('')
  const [pdfBlobData, setPdfBlobData] = useState<IPdf>()

  const resetCach = () => {
    dataCaching.current.length = 0
  }

  const requestData = useCallback((skip: number, filter: string) => {
    if (requestStarted.current) {
      clearTimeout(pendingRequest.current)
      pendingRequest.current = setTimeout(() => {
        requestData(skip, filter)
      }, 50)
      return
    }

    fetchDropdownDataBank(pageSize, skip, filter)
  }, [])

  const onSubmit = async (values: IClientsData) => {
    const payload: IClientsData = values
    setIsSaving(true)

    const formData = new FormData()

    formData.append('abnacn', payload.abnacn === undefined ? '' : payload.abnacn.toString())
    formData.append('addressLine1', payload.addressLine1)
    formData.append('addressLine2', payload.addressLine2 === undefined ? '' : payload.addressLine2)
    formData.append('bankAccountName', payload.bankAccountName)
    formData.append('bankAccountNumber', payload.bankAccountNumber)
    formData.append('bankName', payload.bankName)
    formData.append(
      'bankBranch',
      payload.bankBranch === undefined ? '' : payload.bankBranch.toString()
    )
    formData.append(
      'bankDescription',
      payload.bankDescription === undefined ? '' : payload.bankDescription.toString()
    )
    formData.append('bsb1', payload.bsb1)
    formData.append('bsb2', payload.bsb2)
    formData.append('businessDevelopmentManager', payload.businessDevelopmentManager)
    formData.append(
      'clientType',
      payload.clientType === undefined ? '' : payload.clientType.toString()
    )
    formData.append('companyName', payload.companyName === undefined ? '' : payload.companyName)
    formData.append('country', payload.country)
    formData.append(
      'dateOfBirth',
      payload.dateOfBirth === undefined || payload.dateOfBirth === null
        ? new Date().toISOString()
        : payload.dateOfBirth.toDateString()
    )
    formData.append('email', payload.email)
    formData.append('englishName', payload.englishName)
    formData.append(
      'expiryDate',
      payload.expiryDate === undefined || payload.expiryDate === null
        ? ''
        : payload.expiryDate.toDateString()
    )
    formData.append('firstName', payload.firstName)
    formData.append('holderName', payload.holderName === undefined ? '' : payload.holderName)
    formData.append('id', payload.id === undefined ? '' : payload.id)
    formData.append('identity', payload.identity === undefined ? '' : payload.identity.toString())
    formData.append(
      'identityNumber',
      payload.identityNumber === undefined ? '' : payload.identityNumber
    )
    formData.append('issuedBy', payload.issuedBy === undefined ? '' : payload.issuedBy)
    formData.append(
      'issuedDate',
      payload.issuedDate === undefined || payload.issuedDate === null
        ? ''
        : payload.issuedDate.toDateString()
    )
    formData.append('lastName', payload.lastName)
    formData.append('mobile', payload.mobile === undefined ? '' : payload.mobile.toString())
    formData.append('occupation', payload.occupation || 'N/A')
    formData.append('postCode', payload.postCode)
    formData.append('remarks', payload.remarks === undefined ? '' : payload.remarks)
    formData.append('sex', payload.sex || 'Not To Say')
    formData.append('state', payload.state)
    formData.append('status', payload.status === undefined ? '' : payload.status.toString())
    formData.append('suburb', payload.suburb)
    formData.append('unqId', payload.unqId === undefined ? '' : payload.unqId)
    formData.append(
      'debitAmount',
      payload.debitAmount === undefined ? '' : payload.debitAmount.toString()
    )
    formData.append(
      'paymentCycle',
      payload.paymentCycle === undefined ? '' : payload.paymentCycle.toString()
    )
    formData.append(
      'paymentCycleStartDate',
      payload.paymentCycleStartDate === undefined || payload.paymentCycleStartDate === null
        ? new Date().toISOString()
        : payload.paymentCycleStartDate.toDateString()
    )
    formData.append(
      'PaymentCycleStartDateUtc',
      payload.paymentCycleStartDate === undefined || payload.paymentCycleStartDate === null
        ? new Date().toISOString()
        : getUTCDate(payload.paymentCycleStartDate).toDateString()
    )
    formData.append('blobPdf', pdfBlobData?.blobPdf === undefined ? '' : pdfBlobData.blobPdf)
    formData.append('fileName', pdfBlobData?.fileName === undefined ? '' : pdfBlobData.fileName)

    const [data, error] = await directDebitCreateClient(formData)
    if (data) {
      setformNotification({
        message: `Client saved successfully.`,
        header: `Direct debit`,
        type: 'primary',
      })
      setIsSaving(false)
      // reset(CLIENT_DATA_FORM_DEFAULT)
    } else {
      if (error) {
        const errorMessage = getErrorMessage(error)
        setformNotification({
          message: errorMessage,
          header: `Error Saving Client`,
          type: 'danger',
        })
      }
      setIsSaving(false)
    }
  }

  const onReset = () => {
    reset(CLIENT_DATA_FORM_DEFAULT)
  }

  const fetchDropdownDataBank = async (take: any, skip: any, search: any) => {
    const dataFromApi = await getBankList(take, skip, search)
    const transFormBankData = transformedBankDataList(dataFromApi)
    transFormBankData.forEach((element: any, index: any) => {
      dataCaching.current[index + skip] = element
    })
    if (skip === skipRef.current) {
      setData(transFormBankData)
      setTotal(total)
    }
    requestStarted.current = false
    setData(transFormBankData)
  }
  useEffect(() => {
    requestData(0, filter)
    return () => {
      resetCach()
    }
  }, [filter, requestData])

  const onFilterChange = useCallback((event: ComboBoxFilterChangeEvent) => {
    const filter = event.filter.value

    resetCach()
    requestData(0, filter)

    setData(loadingData)
    skipRef.current = 0
    setFilter(filter)
  }, [])

  const shouldRequestData = useCallback((skip) => {
    for (let i = 0; i < pageSize; i++) {
      if (!dataCaching.current[skip + i]) {
        return true
      }
    }
    return false
  }, [])

  const getCachedData = useCallback((skip) => {
    const data: Array<any> = []
    for (let i = 0; i < pageSize; i++) {
      data.push(dataCaching.current[i + skip] || { ...emptyItem })
    }
    return data
  }, [])

  const pageChange = useCallback(
    (event: ComboBoxPageChangeEvent) => {
      const newSkip = event.page.skip

      if (shouldRequestData(newSkip)) {
        requestData(newSkip, filter)
      }

      const data = getCachedData(newSkip)

      setData(data)
      skipRef.current = newSkip
    },
    [getCachedData, requestData, shouldRequestData, filter]
  )

  const getResourceBankData = (value: any) => {
    return data.find((resource) => resource.name === value)
  }

  const handleShowModal = () => {
    trigger() // Manual form validation
    if (isValid) setShowModal(true)
  }

  const handleCloseModal = () => {
    setShowModal(false)
  }

  const handleAccept = (blobPdf: Blob, fileName: string) => {
    setIsChecked(true)
    setPdfBlobData({ blobPdf, fileName })
    handleCloseModal()
  }

  const handleDecline = () => {
    setIsChecked(false)
    handleCloseModal()
  }

  const handleChangePaymentCycle = (event: ChangeEvent<HTMLSelectElement>) => {
    const value = parseInt(event.target.value)
    setValue('paymentCycle', value)
  }

  return (
    <Fragment>
      {isSaving && loadingPanel}
      {formNotification && (
        <CustomAlert {...formNotification} closeToaster={() => setformNotification(undefined)} />
      )}
      <form onSubmit={handleSubmit(onSubmit)} name='directdebit' encType='multipart/form-data'>
        <div className='modal-body' style={{ marginLeft: '100px', marginRight: '100px' }}>
          <div className='text-center px-4'>
            <img
              className='mw-30 mh-60px'
              alt='metronic'
              src={toAbsoluteUrl('/media/logos/relayosample.png')}
            />
          </div>
          <div className='row align-items-center text-center'>
            <h2 className='pb-2' style={{ fontSize: '24px' }}>
              {intl.formatMessage({ id: 'DIRECTDEBIT.DIRECTDEBITFORM' })}
            </h2>
          </div>
          <div className='row align-items-center mb-4'>
            <div className='col-lg-6 col-md-6 col-12'>
              <label className='form-label' htmlFor='firstName'>
                {intl.formatMessage({ id: 'ECOMMERCE.FIELD.FIRSTNAME' })}
                <span className='text-danger'>*</span>
              </label>
              <input
                type='text'
                className={`form-control border border-dark ${errors.firstName ? 'border-danger' : ''
                  }`}
                placeholder={
                  intl.formatMessage({ id: 'ECOMMERCE.FIELD.ENTER' }) +
                  ' ' +
                  intl.formatMessage({ id: 'ECOMMERCE.FIELD.FIRSTNAME' })
                }
                {...register('firstName')}
                disabled={isDisabled}
              />
              <ErrorMessage
                errors={errors}
                name='firstName'
                as={<span className='spanError text-danger' />}
              />
            </div>
            <div className='col-lg-6 col-md-6 col-12'>
              <label className='form-label' htmlFor='lastName'>
                {intl.formatMessage({ id: 'ECOMMERCE.FIELD.LASTNAME' })}
                <span className='text-danger'>*</span>
              </label>
              <input
                type='text'
                className={`form-control border border-dark ${errors.lastName ? 'border-danger' : ''
                  }`}
                placeholder={
                  intl.formatMessage({ id: 'ECOMMERCE.FIELD.ENTER' }) +
                  ' ' +
                  intl.formatMessage({ id: 'ECOMMERCE.FIELD.LASTNAME' })
                }
                {...register('lastName')}
                disabled={isDisabled}
              />
              <ErrorMessage
                errors={errors}
                name='lastName'
                as={<span className='spanError text-danger' />}
              />
            </div>
          </div>
          <div className='row align-items-center mb-4'>
            <div className='col-lg-6 col-md-6 col-12'>
              <label className='form-label' htmlFor='email'>
                {intl.formatMessage({ id: 'ECOMMERCE.FIELD.EMAIL' })}
                <span className='text-danger'>*</span>
              </label>
              <input
                type='text'
                className={`form-control border border-dark ${errors.email ? 'border-danger' : ''}`}
                placeholder={
                  intl.formatMessage({ id: 'ECOMMERCE.FIELD.ENTER' }) +
                  ' ' +
                  intl.formatMessage({ id: 'ECOMMERCE.FIELD.EMAIL' })
                }
                {...register('email')}
                disabled={isDisabled}
              />
              <ErrorMessage
                errors={errors}
                name='email'
                as={<span className='spanError text-danger' />}
              />
            </div>
            <div className='col-lg-6 col-md-6 col-12'>
              <label className='form-label' htmlFor='mobile'>
                {intl.formatMessage({ id: 'ECOMMERCE.FIELD.MOBILENUMBER' })}
                <span className='text-danger'>*</span>
              </label>
              <input
                type='text'
                className={`form-control border border-dark ${errors.mobile ? 'border-danger' : ''
                  }`}
                placeholder={
                  intl.formatMessage({ id: 'ECOMMERCE.FIELD.ENTER' }) +
                  ' ' +
                  intl.formatMessage({ id: 'ECOMMERCE.FIELD.MOBILENUMBER' })
                }
                {...register('mobile')}
                disabled={isDisabled}
                onKeyPress={blockNonNumericChars}
                maxLength={14}
              />
              <ErrorMessage
                errors={errors}
                name='mobile'
                as={<span className='spanError text-danger' />}
              />
            </div>
          </div>
          <div className='row align-items-center mb-4'>
            <div className='col-lg-6 col-md-6 col-12'>
              <label className='form-label' htmlFor='addressLine1'>
                {intl.formatMessage({ id: 'ECOMMERCE.FIELD.ADDRESS' })}
                <span className='text-danger'>*</span>
              </label>
              <input
                type='text'
                className={`form-control border border-dark ${errors.addressLine1 ? 'border-danger' : ''
                  }`}
                placeholder={intl.formatMessage({ id: 'DIRECTDEBIT.FIELD.ADDRESS' })}
                {...register('addressLine1')}
                disabled={isDisabled}
              />
              <ErrorMessage
                errors={errors}
                name='addressLine1'
                as={<span className='spanError text-danger' />}
              />
            </div>
            <div className='col-lg-6 col-md-6 col-12'>
              <label className='form-label' htmlFor='addressLine2'>
                {intl.formatMessage({ id: 'ECOMMERCE.FIELD.ADDRESS2' })}
              </label>
              <label className='form-label' htmlFor='addressLine2'></label>
              <input
                type='text'
                className={`form-control border border-dark ${errors.addressLine2 ? 'border-danger' : ''
                  }`}
                placeholder={intl.formatMessage({ id: 'DIRECTDEBIT.FIELD.ADDRESS2' })}
                {...register('addressLine2')}
                disabled={isDisabled}
              />
            </div>
          </div>
          <div className='row align-items-center mb-4'>
            <div className='col-lg-4 col-md-4 col-12'>
              <label className='form-label' htmlFor='suburb'>
                {intl.formatMessage({ id: 'ECOMMERCE.FIELD.SUBURB' })}
                <span className='text-danger'>*</span>
              </label>
              <input
                type='text'
                className={`form-control border border-dark ${errors.suburb ? 'border-danger' : ''
                  }`}
                placeholder={intl.formatMessage({ id: 'DIRECTDEBIT.FIELD.SUBURB' })}
                {...register('suburb')}
                disabled={isDisabled}
              />
              <ErrorMessage
                errors={errors}
                name='suburb'
                as={<span className='spanError text-danger' />}
              />
            </div>
            <div className='col-lg-4 col-md-4 col-12'>
              <label className='form-label' htmlFor='state'>
                {intl.formatMessage({ id: 'ECOMMERCE.FIELD.STATE' })}
                <span className='text-danger'>*</span>
              </label>
              <input
                type='text'
                className={`form-control border border-dark ${errors.state ? 'border-danger' : ''}`}
                placeholder={intl.formatMessage({ id: 'DIRECTDEBIT.FIELD.STATE' })}
                {...register('state')}
                disabled={isDisabled}
              />
              <ErrorMessage
                errors={errors}
                name='state'
                as={<span className='spanError text-danger' />}
              />
            </div>
            <div className='col-lg-4 col-md-4 col-12'>
              <label className='form-label' htmlFor='postCode'>
                {intl.formatMessage({ id: 'ECOMMERCE.FIELD.POSTCODE' })}
                <span className='text-danger'>*</span>
              </label>
              <input
                type='text'
                className={`form-control border border-dark ${errors.postCode ? 'border-danger' : ''
                  }`}
                placeholder={intl.formatMessage({ id: 'DIRECTDEBIT.FIELD.POSTCODE' })}
                {...register('postCode')}
                disabled={isDisabled}
              />
              <ErrorMessage
                errors={errors}
                name='postCode'
                as={<span className='spanError text-danger' />}
              />
            </div>
          </div>
          <div className='row align-items-center mb-4'>
            <div className='col-lg-4 col-md-4 col-12'>
              <label className='form-label' htmlFor='country'>
                {intl.formatMessage({ id: 'ECOMMERCE.FIELD.COUNTRY' })}
                <span className='text-danger'>*</span>
              </label>
              <Controller
                control={control}
                name='country'
                render={({ field: { onChange, value } }) => {
                  const countriesWithPlaceholder = [
                    ...countriesData,
                  ]
                  return (
                    <DropDownList
                      className={`${errors.country ? 'border-danger' : ''}`}
                      data={countriesWithPlaceholder}
                      textField='text'
                      filterable={false}
                      onChange={(e) => {
                        onChange(e.value.id)
                      }}
                      defaultValue={countriesWithPlaceholder.find((x) => x.id === 'AUS')}
                      style={{ width: '100%', border: '1px solid #000' }}
                    />
                  )
                }}
              />
              <ErrorMessage
                errors={errors}
                name='country'
                as={<span className='spanError text-danger' />}
              />
            </div>
          </div>
          <br />
          <div className='row align-items-center mb-4'>
            <div className='col-lg-6 col-md-6 col-12'>
              <label className='form-label' htmlFor='paymentTerms'>
                {intl.formatMessage({ id: 'ECOMMERCE.FIELD.DIRECTDEBITINFORMATION' })}
              </label>
            </div>
          </div>
          <div className='row align-items-center mb-4'>
            <div className='col-lg-4 col-md-4 col-12'>
              <label className='form-label' htmlFor='paymentCycle'>
                {intl.formatMessage({ id: 'ECOMMERCE.FIELD.DEBITAMOUNT' })}
                <span className='text-danger'>*</span>
              </label>
              <Controller
                control={control}
                name='debitAmount'
                render={({ field: { value, name, onChange } }) => {
                  return (
                    <NumericTextBox
                      name={name}
                      width='100%'
                      value={value}
                      defaultValue={0}
                      onChange={onChange}
                      format={'n2'}
                      min={0} // Set the minimum value to 0
                      className={`border border-dark ${errors.debitAmount ? 'border-danger' : ''}`}
                    />
                  )
                }}
              />
              <ErrorMessage
                errors={errors}
                name='debitAmount'
                as={<span className='spanError text-danger' />}
              />
            </div>
            <div className='col-lg-4 col-md-4 col-12'>
              <label className='form-label' htmlFor='paymentCycle'>
                {intl.formatMessage({ id: 'ECOMMERCE.FIELD.PAYMENTCYCLE' })}
                <span className='text-danger'>*</span>
              </label>
              <select
                className={`form-control form-select border border-dark ${errors.paymentCycle ? 'border-danger' : ''
                  }`}
                data-kt-select2='true'
                data-placeholder='Select option'
                data-allow-clear='true'
                {...register('paymentCycle')}
                onChange={handleChangePaymentCycle}
              >
                {paymentCycleOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
              <ErrorMessage
                errors={errors}
                name='paymentCycle'
                as={<span className='spanError text-danger' />}
              />
            </div>
            <div className='col-lg-4 col-md-4 col-12'>
              <label className='form-label' htmlFor='paymentCycleStartDate'>
                {intl.formatMessage({ id: 'ECOMMERCE.FIELD.PAYMENTCYCLE.STARTDATE' })}
                <span className='text-danger'>*</span>
              </label>
              <Controller
                control={control}
                name='paymentCycleStartDate'
                render={({ field: { value, name, onChange } }) => {
                  return (
                    <DatePicker
                      format='dd/MM/yyyy'
                      name={name}
                      min={new Date()}
                      className={`border border-dark ${errors.paymentCycleStartDate ? 'border-danger' : ''}`}
                      value={value}
                      onChange={onChange}
                    />
                  )
                }}
              />
              <ErrorMessage
                errors={errors}
                name='paymentCycleStartDate'
                as={<span className='spanError text-danger' />}
              />
            </div>
          </div>
          <br />
          <div className='row align-items-center mb-4'>
            <div className='col-lg-6 col-md-6 col-12'>
              <label className='form-label' htmlFor='bankDetails'>
                {intl.formatMessage({ id: 'ECOMMERCE.FIELD.BANKDETAILS' })}
              </label>
            </div>
          </div>
          <div className='row align-items-center mb-4'>
            <div className='col-lg-6 col-md-6 col-12'>
              <label className='form-label' htmlFor='bankName'>
                {intl.formatMessage({ id: 'ECOMMERCE.FIELD.BANKNAME' })}
                <span className='text-danger'>*</span>
              </label>
              <Controller
                control={control}
                name='bankName'
                render={({ field: { value, onChange } }) => (
                  <DropDownList
                    data={data}
                    value={getResourceBankData(value)}
                    dataItemKey={keyField}
                    textField={textField}
                    filterable={false}
                    virtual={{
                      pageSize: pageSize,
                      skip: skipRef.current,
                      total: total,
                    }}
                    className={`border border-dark ${errors.bankName ? 'border-danger' : ''}`}
                    onChange={(event) => {
                      const selectedItem = event.target.value

                      if (selectedItem) {
                        const value = selectedItem
                          ? event.target.value.name + '||' + event.target.value.id
                          : 'undefined'
                        if (typeof event.target.value === 'object') {
                          // Code block to handle the case when event.target.value is an object
                          onChange(event.target.value.text)
                        } else if (typeof event.target.value === 'string') {
                          // Code block to handle the case when event.target.value is a string
                          onChange(selectedItem)
                        } else {
                          // Code block for other data types (e.g., numbers, arrays, etc.)
                          onChange(value)
                        }
                      } else {
                        onChange('')
                      }
                    }}
                  />
                )}
                rules={{ required: 'Resource is required' }}
              />
              <ErrorMessage
                errors={errors}
                name='bankName'
                as={<span className='spanError text-danger' />}
              />
            </div>
            <div className='col-lg-6 col-md-6 col-12'>
              <div className='row align-items-center'>
                <label className='form-label' htmlFor='bsb1'>
                  {intl.formatMessage({ id: 'ECOMMERCE.FIELD.BSB' })}
                  <span className='text-danger'>*</span>
                </label>
                <div className='col-lg-3 col-md-3 col-6'>
                  <input
                    type='text'
                    className={`form-control border border-dark ${errors.bsb1 ? 'border-danger' : ''
                      }`}
                    {...register('bsb1')}
                    maxLength={3}
                    disabled={isDisabled}
                  />
                </div>
                <div className='col-lg-1 col-md-1 col-12 text-center'>
                  <label className='form-label' htmlFor='dash'>
                    -
                  </label>
                </div>
                <div className='col-lg-3 col-md-3 col-6'>
                  <input
                    type='text'
                    className={`form-control border border-dark ${errors.bsb2 ? 'border-danger' : ''
                      }`}
                    {...register('bsb2')}
                    maxLength={3}
                    disabled={isDisabled}
                  />
                </div>
                <ErrorMessage
                  errors={errors}
                  name='bsb1'
                  as={<span className='spanError text-danger' />}
                />
                <ErrorMessage
                  errors={errors}
                  name='bsb2'
                  as={<span className='spanError text-danger' />}
                />
              </div>
            </div>
          </div>
          <div className='row align-items-center mb-4'>
            <div className='col-lg-6 col-md-6 col-12'>
              <label className='form-label' htmlFor='bankAccountNumber'>
                {intl.formatMessage({ id: 'ECOMMERCE.FIELD.BANKACCOUNTNUMBER' })}
                <span className='text-danger'>*</span>
              </label>
              <input
                type='text'
                className={`form-control border border-dark ${errors.bankAccountNumber ? 'border-danger' : ''
                  }`}
                {...register('bankAccountNumber')}
                disabled={isDisabled}
                onKeyPress={blockNonNumericChars}
              />
              <ErrorMessage
                errors={errors}
                name='bankAccountNumber'
                as={<span className='spanError text-danger' />}
              />
            </div>
            <div className='col-lg-6 col-md-6 col-12'>
              <label className='form-label' htmlFor='bankAccountName'>
                {intl.formatMessage({ id: 'ECOMMERCE.FIELD.BANKACCOUNTNAME' })}
                <span className='text-danger'>*</span>
              </label>
              <input
                type='text'
                className={`form-control border border-dark ${errors.bankAccountName ? 'border-danger' : ''
                  }`}
                {...register('bankAccountName')}
                disabled={isDisabled}
              />
              <ErrorMessage
                errors={errors}
                name='bankAccountName'
                as={<span className='spanError text-danger' />}
              />
            </div>
          </div>
          <div className='row align-items-center mb-4'>
            <div className='col-12'>
              <label className='form-label' htmlFor='bankBranch'>
                {intl.formatMessage({ id: 'ECOMMERCE.FIELD.BANKBRANCH' })}
              </label>
              <input
                type='text'
                className={`form-control border border-dark ${errors.bankBranch ? 'border-danger' : ''
                  }`}
                {...register('bankBranch')}
                disabled={isDisabled}
              />
            </div>
          </div>
          <div className='row align-items-center mb-4'>
            <div className='col-12'>
              <label className='form-label' htmlFor='bankDescription'>
                {intl.formatMessage({ id: 'ECOMMERCE.FIELD.DESCRIPTION' })}
              </label>
              <input
                type='text'
                className={`form-control border border-dark ${errors.bankDescription ? 'border-danger' : ''
                  }`}
                {...register('bankDescription')}
                disabled={isDisabled}
              />
            </div>
          </div>
          <div className='row align-items-center mb-4'>
            {isDirectDebitAgreementFeatureEnabled && (
              <div className='col-lg-6 col-md-6 col-12'>
                <div className='form-check'>
                  <input
                    type='checkbox'
                    className='form-check-input'
                    id='directDebitAgreement'
                    checked={isChecked}
                    onChange={handleShowModal}
                  />
                  <label htmlFor='directDebitAgreement' className='form-check-label'>
                    Sign the&nbsp;
                    <span
                      style={{ color: 'blue', cursor: 'pointer', textDecoration: 'none' }}
                      onClick={() => {
                        if (isValid) {
                          setShowModal(true)
                        }
                      }}
                    >
                      Direct Debit Agreement
                    </span>
                  </label>
                </div>
                <DirectDebitModal
                  show={showModal}
                  formData={getValues()}
                  paymentCycleOptions={paymentCycleOptions}
                  onAccept={handleAccept}
                  onDecline={handleDecline}
                />
              </div>
            )}
          </div>
          <br />
          <div className='d-flex justify-content-center actions'>
            <button
              type='submit'
              className='btn btn-primary col-auto me-4'
              style={{ backgroundColor: '#E0E0E0', border: '1px solid #000', color: '#000' }}
              disabled={isDirectDebitAgreementFeatureEnabled ? !isChecked || isSaving : isSaving}
            >
              {intl.formatMessage({ id: 'DIRECTDEBIT.FIELD.SUBMIT' })}
            </button>
          </div>
        </div>
      </form>
    </Fragment>
  )
}

export { DirectDebitForm }
function fetchDropdownDataBank(pageSize: number, skip: number, filter: string) {
  throw new Error('Function not implemented.')
}
