/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import {Login2Fa} from './Login2Fa'
import {Login} from './Login'

export function LoginPage() {
  const [show2Fa, setShow2Fa] = useState(false)
  const [email, setEmail] = useState('')

  const show2faHandler = (email: string) => {
    setEmail(email)
    setShow2Fa(true)
  }

  const cancelHandler = () => {
    setShow2Fa(false)
  }

  return !show2Fa ? (
    <Login handleShow2fa={show2faHandler} />
  ) : (
    <Login2Fa email={email} cancelHandler={cancelHandler} />
  )
}
