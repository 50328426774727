import { Redirect } from 'react-router-dom';
import { usePageData } from '../../_metronic/layout/core';
import { PAGE_PERMISSION } from '../modules/roles/constant/role-default';

const ActivePageBasedRedirect = () => {

  const { currentUser: { activePages } } = usePageData();

  if (!activePages || activePages.length === 0) {
    return <Redirect to='error/404' />;
  }

  const permissionsOrder = [
    { permission: PAGE_PERMISSION.Clients_ViewPage, route: '/client' },
    { permission: PAGE_PERMISSION.Orders_ViewPage, route: '/order' },
    { permission: PAGE_PERMISSION.Transactions_ViewPage, route: '/transaction' },
    { permission: PAGE_PERMISSION.MasterWallet, route: '/master-wallet' },
    { permission: PAGE_PERMISSION.User_ViewPage, route: '/user' },
    { permission: PAGE_PERMISSION.PayIns_ViewPage, route: '/payins' },
    { permission: PAGE_PERMISSION.Administrator_Roles_ViewPage, route: '/role' },
  ];
  for (let { permission, route } of permissionsOrder) {
    if (activePages.some(page => page.name === permission)) {
      return <Redirect to={route} />;
    }
  }

  return null;

};

export default ActivePageBasedRedirect;
