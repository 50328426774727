/* eslint-disable react/jsx-no-target-blank */
import { useIntl } from 'react-intl'
import { shallowEqual, useSelector } from 'react-redux'

import { AsideMenuItem } from './AsideMenuItem'
import { UserModel } from '../../../../app/modules/auth/models/UserModel'
import { RootState } from '../../../../setup'
import { FeatureFlagProvider } from '../../../../app/modules/feature-flag/FeatureFlagProvider'
import { AsideMenuItemWithSub } from './AsideMenuItemWithSub'
import { PAGE_PERMISSION } from '../../../../app/modules/roles/constant/role-default'

export function AsideMenuMain() {
  const intl = useIntl()
  const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel

  return (
    <>
      {user.isCreatedByDebit && (
        <AsideMenuItem
          to='/dashboard'
          icon='/media/icons/duotune/art/art002.svg'
          title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
          fontIcon='bi-app-indicator'
        />
      )}
      {!user.isCreatedByDebit && (
        <AsideMenuItem
          to='/client'
          icon='/media/icons/duotune/art/art003.svg'
          title={intl.formatMessage({ id: 'MENU.CLIENTS' })}
          fontIcon='bi-app-indicator'
          pagePermission={PAGE_PERMISSION.Clients_ViewPage}
        />
      )}
      <AsideMenuItem
        to='/order'
        icon='/media/icons/duotune/art/art004.svg'
        title={intl.formatMessage({ id: 'MENU.ORDER' })}
        fontIcon='bi-app-indicator'
        pagePermission={PAGE_PERMISSION.Orders_ViewPage}
      />
      {user.isCreatedByDebit && (
        <AsideMenuItem
          to='/my-profile'
          icon='/media/icons/duotune/art/art003.svg'
          title={intl.formatMessage({ id: 'MENU.MYPROFILE' })}
          fontIcon='bi-app-indicator'
        />
      )}
      {/* <AsideMenuItem
        to='/item'
        icon='/media/icons/duotune/art/art005.svg'
        title={intl.formatMessage({id: 'MENU.ITEM'})}
        fontIcon='bi-app-indicator'
      /> */}
      {!user.isCreatedByDebit && (
        <AsideMenuItem
          to='/transaction'
          icon='/media/icons/duotune/art/art006.svg'
          title={intl.formatMessage({ id: 'MENU.TRANSACTION' })}
          fontIcon='bi-app-indicator'
          pagePermission={PAGE_PERMISSION.Transactions_ViewPage}
        />
      )}
      {!user.isCreatedByDebit && (
        <AsideMenuItem
          to='/master-wallet'
          icon='/media/icons/duotune/art/art006.svg'
          title={intl.formatMessage({ id: 'MENU.MASTERWALLET' })}
          fontIcon='bi-app-indicator'
          pagePermission={PAGE_PERMISSION.MasterWallet}
        />
      )}
      {!user.isCreatedByDebit && (
        <AsideMenuItem
          to='/user'
          title={intl.formatMessage({ id: 'MENU.USERS' })}
          fontIcon='bi-chat-left'
          icon='/media/icons/duotune/communication/com012.svg'
          pagePermission={PAGE_PERMISSION.User_ViewPage}
        />
      )}
      <FeatureFlagProvider featureName='PayInsFeature'>
        <AsideMenuItem
          to='/payins'
          title={intl.formatMessage({ id: 'MENU.PAYINS' })}
          fontIcon='bi-chat-left'
          icon='/media/icons/duotune/communication/com012.svg'
          pagePermission={PAGE_PERMISSION.PayIns_ViewPage}
          />
      </FeatureFlagProvider>

      <AsideMenuItemWithSub to='/administration'
        title="Administration"
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/communication/com012.svg'
        pagePermissions={[PAGE_PERMISSION.Administrator_Roles_ViewPage]}>

        <AsideMenuItem
          to='/role'
          title={intl.formatMessage({ id: 'MENU.ROLES' })}
          fontIcon='bi-chat-left'
          icon='/media/icons/duotune/communication/com012.svg'
          pagePermission={PAGE_PERMISSION.Administrator_Roles_ViewPage}
        />

      </AsideMenuItemWithSub>

      {/*
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
            {intl.formatMessage({id: 'MENU.ADMINISTRATION'})}
          </span>
        </div>
      </div>
      <AsideMenuItem
        to='/role'
        title={intl.formatMessage({id: 'MENU.ROLES'})}
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/communication/com012.svg'
      />
      {/* <AsideMenuItem
        to='/settings'
        title={intl.formatMessage({id: 'MENU.SETTINGS'})}
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/communication/com012.svg'
      /> 
      <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator mx-1 my-4'></div>
        </div>
      </div>*/}
    </>
  )
}
