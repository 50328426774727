import { FC, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useEffectOnce } from 'react-use'
import { getClientProfileById } from '../api/ClientApi'
import { IClientProfile } from '../models/client-profile'
import ClientProfileDashboard from './partials/client-profile/ClientProfileDashboard'
import { getErrorMessage } from '../../../shared/service/utils'
import { CustomAlert, IAlert } from '../../../shared/components/CustomAlert'

const loadingPanel = (
  <div className='position-fixed k-loading-mask'>
    <span className='k-loading-text'>Loading</span>
    <div className='k-loading-image'></div>
    <div className='k-loading-color'></div>
  </div>
)
const ClientProfileWrapper: FC = () => {
  const search = useLocation().search
  const idParam: string | null = new URLSearchParams(search).get('companyId')
  const [selectedClient, setSelectedClient] = useState<IClientProfile | undefined>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [formNotification, setformNotification] = useState<IAlert | undefined>()

  useEffectOnce(() => {
    getClientProfile()
  })

  const getClientProfile = async () => {
    if (idParam) {
      setIsLoading(true)
      const [data, error] = await getClientProfileById(idParam)

      if (data) {
        setSelectedClient(data as any)
      } else {
        if (error) {
          const errorMessage = getErrorMessage(error)
          setformNotification({
            message: errorMessage,
            header: `Error Profile Information`,
            type: 'danger',
          })
        }
      }
      setIsLoading(false)
    }
  }

  return (
    <>
      {isLoading && loadingPanel}
      {formNotification && (
        <CustomAlert {...formNotification} closeToaster={() => setformNotification(undefined)} />
      )}
      {selectedClient && <ClientProfileDashboard clientData={selectedClient} />}
    </>
  )
}

export default ClientProfileWrapper
