import React, {useEffect, useMemo} from 'react'
import {getFeatures} from './api/features'
import {FEATURE_DEFAULT, IFeature} from './model/feature'

interface IProps {
  features: IFeature[]
}

export const FeatureFlagsContext = React.createContext<IProps>({
  features: [],
})

export const FeatureFlagsProvider: React.FC = ({children}) => {
  const [isLoading, setIsLoading] = React.useState(true)
  const [features, setFeatures] = React.useState<IFeature[]>([])

  useEffect(() => {
    (async () => {
      try {
        const featureNames = await getFeatures();
        if (featureNames) {
          const featureObjects = featureNames.map((featureName: string) => ({
            name: featureName
          }));
          setFeatures(featureObjects);
        }
      } catch (err) {
        console.log(err);
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  const value: IProps = useMemo(() => {
    return {features}
  }, [features])

  return (
    <FeatureFlagsContext.Provider value={value}>
      {isLoading ? 'Loading...' : children}
    </FeatureFlagsContext.Provider>
  )
}

export const useFeatureFlags = () => React.useContext(FeatureFlagsContext)