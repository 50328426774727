import {IAlert} from '../../../shared/components/CustomAlert'
import {GridSetup, IBankInfo, IClientBankOption, ITransaction} from '../models/transaction-model'

export const TRANSACTION_FORM_DEFAULT: ITransaction = {
  id: 0,
  transactionType: 'withdrawal', //pending
  status: '', //withdraw
  orderId: '',
  currency: '',
  amount: 0,
  clientId: '',
  clientName: '',
  referenceId: '',
  bankAccount: '',
  amountFormatted: 'AUD 0.00',
}

export const BANK_INFO_DEFAULT: IBankInfo = {
  zaiBankId: '',
  bankName: '',
  bsb: '',
  accountNumber: '',
  accountName: '',
}

export const BANK_OPTION_DEFAULT: IClientBankOption = {
  value: '',
  label: '',
  bank: {
    zaiBankId: '',
    bankName: '',
    bsb: '',
    accountName: '',
    accountNumber: '',
  },
}
export const Initial_GridSetup: GridSetup = {
  sort: [{field: 'id', dir: 'desc'}],
  skip: 0,
  take: 10,
  filter: {
    logic: 'and',
    filters: [],
  },
}

export const TOASTER_DEFAULT: IAlert = {
  message: ``,
  header: ``,
  type: 'success',
}

export const FIELD_COLUMN_MAP = [
  {field: 'id', objectMap: 'id', sortMap: 'id', type: typeof 0},
  {field: 'transactionType', objectMap: 'transactionType', sortMap: 'transactionType', type: ''},
  {field: 'status', objectMap: 'status', sortMap: 'status', type: ''},
  {field: 'clientName', objectMap: 'clientName', sortMap: 'clientName', type: typeof ''},
  {field: 'bankAccount', objectMap: 'bankAccount', sortMap: 'bankAccount', type: typeof ''},
  {field: 'amount', objectMap: 'amount', sortMap: 'amount', type: typeof 0},
  {field: 'currency', objectMap: 'currency', sortMap: 'currency', type: typeof ''},
  {field: 'zaiDisbursementId', objectMap: 'zaiDisbursementId', sortMap: '', type: typeof ''},
  {field: 'zaiReferenceId', objectMap: 'zaiReferenceId', sortMap: '', type: typeof ''},
]
export const FIELD_DEFAULT = {field: 'id', dir: 'asc'}
export const FILTER_DEFAULT = 'id > 0 '
