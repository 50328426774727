import { useDispatch } from "react-redux"
import * as auth from '../../app//modules/auth/redux/AuthRedux'
import { Logout } from "../../app/modules/auth"


export default function setupAxios(axios: any, store: any) {
  axios.defaults.headers.Accept = 'application/json'
  axios.interceptors.request.use(
    (config: any) => {
      const {
        auth: {accessToken},
      } = store.getState()

      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`
      }

      return config
    },
    (err: any) => {
      console.log(err)
      Promise.reject(err)
    }
  )
  axios.interceptors.response.use(
    (response: any) => {
      return response
    },
    function (error: any) {
      const originalRequest = error.config
      if (
        error.response.status === 401
      ) {
        // If the auth received unauthorize must be redirect
        
        localStorage.setItem('persist:isg-next-auth', '')
        document.location.reload()
        return Promise.reject(error)
      }

      return Promise.reject(error)
    }
  )
}
