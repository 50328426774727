import {IAlert} from '../../../shared/components/CustomAlert'
import {IAvailableClient} from '../models/available-client'
import {IItems, IItemsData} from '../models/items'

export const ITEM_FORM_DEFAULT: IItems = {
  uuid: '',
  name: '',
  amount: 0,
  currency: 'AUD',
  state: '',
  buyerId: '',
  sellerId: '',
  rootAmount: 0,
  conversionRate: 0,
  description: '',
  internalCurrencyId: '',
  paymentType: 2,
  orderType: 2,
  isOrderDataFeatureEnabled: false,
  fromJobSched: false,
}

export const ITEMDATA_FORM_DEFAULT: IItemsData = {
  uuid: '',
  name: '',
  amount: 0,
  currency: 'AUD',
  state: '',
  buyerId: '',
  sellerId: '',
  rootAmount: 0,
  conversionRate: 0,
  description: '',
  internalCurrencyId: '',
  buyerName: '',
  sellerName: '',
  orderTypeName: '',
  paymentType: 2,
  orderType: 2,
  isOrderDataFeatureEnabled: false,
  createdDate: new Date(),
  updatedDate: new Date(),
  fromJobSched: false,
}

export const FIELD_COLUMN_MAP = [
  {field: 'uuid', objectMap: 'uuid', sortMap: 'Uuid', type: typeof 0},
  {field: 'state', objectMap: 'state', sortMap: 'state', type: ''},

  {
    field: 'internalCurrency',
    objectMap: 'internalCurrency',
    sortMap: 'item.internalCurrencyId',
    type: typeof 0,
  },
  {field: 'buyerName', objectMap: 'buyerName', sortMap: 'buyerName', type: ''},
  {field: 'sellerName', objectMap: 'sellerName', sortMap: 'sellerName', type: typeof ''},
  {field: 'name', objectMap: 'name', sortMap: 'name', type: typeof ''},
  {field: 'rootAmount', objectMap: 'rootAmount', sortMap: 'rootAmount', type: typeof ''},
  {
    field: 'conversionRate',
    objectMap: 'conversionRate',
    sortMap: 'item.conversionRate',
    type: typeof '',
  },
  {field: 'amount', objectMap: 'amount', sortMap: 'amount', type: typeof ''},
  {field: 'debitAmount', objectMap: 'debitAmount', sortMap: 'debitAmount', type: typeof ''},
  {
    field: 'paymentCycleName',
    objectMap: 'paymentCycleName',
    sortMap: 'paymentCycleName',
    type: typeof '',
  },
]
export const FIELD_DEFAULT = {field: 'id', dir: 'asc'}

export const STATUS = [
  {status: 'pending', title: 'Pending', success: true},
  {status: 'pending_check', title: 'Pending Check', success: true},
  {status: 'approved_kyc_check', title: 'Approved KYC Check', success: true},
  {status: 'approved', title: 'Approved', success: true},
  {status: 'payment_required', title: 'Payment Required', success: false},
  {status: 'wire_pending', title: 'Wire Pending', success: true},
  {status: 'payment_pending', title: 'Payment Pending', success: true},
  {status: 'payment_held', title: 'Payment Held', success: false},
  {status: 'payment_authorized', title: 'Payment Authorized', success: true},
  {status: 'void_pending', title: 'Void Pending', success: true},
  {status: 'voided', title: 'Voided', success: true},
  {status: 'payment_deposited', title: 'Payment Deposited', success: false},
  {status: 'work_completed', title: 'Work Completed', success: false},
  {status: 'problem_flagged', title: 'Problem Flagged', success: true},
  {status: 'problem_resolve_requested', title: 'Problem Resolve Requested', success: true},
  {status: 'problem_escalated', title: 'Problem Escalated', success: true},
  {status: 'completed', title: 'Completed', success: true},
  {status: 'cancelled', title: 'Cancelled', success: false},
  {status: 'refunded', title: 'Refunded', success: true},
  {status: 'refund_pending', title: 'Refund Pending', success: true},
  {status: 'refund_flagged', title: 'Refund Flagged', success: true},
  {status: 'off_platform_refunded', title: 'Off Platform Refunded', success: true},
  {status: 'partial_completed', title: 'Partial Completed', success: false},
  {status: 'partial_paid', title: 'Partial Paid', success: false},
  {status: 'off_platform_chargedback', title: 'Off Platform Chargedback', success: true},
  {status: 'requested', title: 'Requested', success: true},
  {status: 'processing', title: 'Processing', success: true},
  {status: 'pending_return', title: 'Pending Return', success: true},
  {status: 'pending_direct_credit', title: 'Pending Direct Credit', success: true},
  {status: 'failed', title: 'Failed', success: false},
  {status: 'success', title: 'Success', success: true},
  {status: 'successful', title: 'Successful', success: true},
  {status: 'pending_successful', title: 'Pending Successful', success: true},
  {status: 'bpay_pending', title: 'BPay Pending', success: true},
  {status: 'batched', title: 'Batched', success: true},
  {status: 'invalid_account_details', title: 'Invalid Account Details', success: false},
  {status: 'failed_direct_debit', title: 'Failed Direct Debit', success: false},
  {status: 'bank_processing', title: 'Bank Processing', success: true},
  {status: 'errored', title: 'Errored', success: false},
  {status: 'pending_activation', title: 'Pending Activation', success: true},
  {status: 'active', title: 'Active', success: true},
  {status: 'activation_failed', title: 'Activation Failed', success: false},
  {status: 'pending_validation', title: 'Pending Validation', success: true},
  {status: 'validated', title: 'Validated', success: true},
  {status: 'validation_failed', title: 'Validation Failed', success: false},
  {status: 'pending_creation', title: 'Pending Creation', success: true},
  {status: 'created', title: 'Created', success: true},
  {status: 'creation_failed', title: 'Creation Failed', success: false},
  {status: 'suspended', title: 'Suspended', success: false},
  {status: 'pending_payment_initiation', title: 'Pending Payment Initiation', success: false},
  {status: 'payment_initiated', title: 'Payment Initiated', success: false},
  {status: 'payment_initiation_completed', title: 'Payment  Initiation Completed', success: true},
  {status: 'payment_initiation_rejected', title: 'Payment Initiation Rejected', success: false},
]

export const TOASTER_DEFAULT: IAlert = {
  message: ``,
  header: ``,
  type: 'success',
}

export const AVALIABLECLIENT_DEFAULT: IAvailableClient = {
  unqId: '',
  firstName: '',
  lastName: '',
  email: '',
  isMasterWallet: false,
}
