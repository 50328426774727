import {Modal} from 'react-bootstrap-v5'
import {ITransaction} from '../../models/transaction-model'
import {AuthorizeForm} from './AuthorizeForm'
import { useIntl } from 'react-intl'

interface IProps {
  show: boolean
  handleClose: (refresh: boolean) => void
  transactionData?: ITransaction
  saveCallback?: () => void
}

const AuthorizeTransactionModal: React.FC<IProps> = ({
  show,
  handleClose,
  transactionData,
  saveCallback,
}) => {
  const intl = useIntl()
  //const {setContextToaster} = useContext(RoleContext)

  const saveTransactionHandler = () => {
    /*if (transactionData)
      deleteRole(transactionData).then(() => {
        setContextToaster({
          message: `Successfully deleted the role`,
          header: `Role Delete`,
          type: 'primary',
        })
        saveCallback()
      })*/
  }

  return (
    <Modal show={show} onHide={handleClose} size='lg'>
      <Modal.Header closeButton>
        <Modal.Title>{intl.formatMessage({id: 'ECOMMERCE.FIELD.AUTHORIZETRANSACTION'})}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <AuthorizeForm transactionData={transactionData} onClose={handleClose} />
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  )
}

export {AuthorizeTransactionModal}
