import {SortDescriptor} from '@progress/kendo-data-query'
import axios from 'axios'
import {ITransaction, ITransactionSearch} from '../models/transaction-model'

export const TRANSACTION_URL = `${process.env.REACT_APP_API_URL}/transaction`
export const CLIENT_URL = `${process.env.REACT_APP_API_URL}/Client`
export const ZAIUSER_URL = `${process.env.REACT_APP_API_URL}/ZaiUser`

// export const getTransactions = async (
//   skip: number,
//   take: number,
//   pageSort: SortDescriptor,
//   roleSearch?: ITransactionSearch
// ) => {
//   return await axios.post(`${TRANSACTION_URL}/get`, {
//     ...roleSearch,
//     sort: `${pageSort.field} ${pageSort.dir}`,
//     skip,
//     take,
//   })
// }
export const getTransactions = async (
  skip: number,
  take: number,
  pageSort: SortDescriptor,
  objectFilter?: any
) => {
  const {
    id,
    transactionType,
    status,
    clientName,
    bankAccount,
    amount,
    currency,
    zaiDisbursementId,
    zaiReferenceId,
  } = objectFilter
  try {
    return await axios.get(`${TRANSACTION_URL}/get`, {
      // skip,
      // take,
      // objectFilter,
      // sort: `${pageSort.field} ${pageSort.dir}`,
      params: {
        skip,
        take,
        sort: `${pageSort.field} ${pageSort.dir}`,
        id,
        transactionType,
        status,
        clientName,
        bankAccount,
        amount,
        currency,
        zaiDisbursementId,
        zaiReferenceId,
      },
    })
    // console.log(response?.data)
    // return response?.data ?? []
  } catch (err) {
    return []
  }
}

export const createTransaction = async (form: ITransaction) => {
  const payload: ITransaction = form
  try {
    const {data} = await axios.post(`${TRANSACTION_URL}/create`, payload)
    return [data, null]
  } catch (error) {
    console.log('error', error)
    return [null, error]
  }
}

export const authorizeTransaction = async (form: ITransaction) => {
  const payload: ITransaction = form

  try {
    const {data} = await axios.post(`${TRANSACTION_URL}/authorize`, payload)
    return [data, null]
  } catch (error) {
    return [null, error]
  }
}

export const updateTransaction = async (form: ITransaction) => {
  const payload: ITransaction = form
  return await axios.put(`${TRANSACTION_URL}`, payload)
}

export const deleteRole = async (form: ITransaction) => {
  return await axios.delete(`${TRANSACTION_URL}?id=${form.id}`)
}

export const getAllClientsWithType = async (clientType: number,  isMasterWalletDefault: boolean) => {
  try {
    const response = await axios.post(`${CLIENT_URL}/get`, {
      skip: 0,
      take: 9999,
      search: 'id > 0',
      sort: 'id asc',
      clientType,
      isMasterWalletDefault
    })
    return response?.data ?? []
  } catch (err) {
    return []
  }
}

export const getClientBankDetails = async (userId: string) => {
  try {
    const response = await axios.get(`${ZAIUSER_URL}/getBankAccount`, {
      params: {
        userId,
      },
    })

    if (response.status == 200) {
      return response.data
    } else return null
  } catch (err) {
    return null
  }
}

export const getClientBankList = async (clientId: string) => {
  try {
    const response = await axios.get(`${CLIENT_URL}/getBankList`, {
      params: {
        clientId,
      },
    })

    if (response.status == 200) {
      return response.data
    } else return null
  } catch (err) {
    return null
  }
}

export const getMasterClientBank = async () => {
  try {
    const response = await axios.get(`${CLIENT_URL}/getMasterBank`)

    if (response.status == 200) {
      return response.data
    } else return null
  } catch (err) {
    return null
  }
}

export const generateTransactionPdf = async () => {
  try {
    const {data} = await axios.post(
      `${TRANSACTION_URL}/generatetransactionpdf`,
      {},
      {
        headers: {
          'Content-Type': 'application/json', // Set the appropriate content type
        },
      }
    )
    return [data, null]
  } catch (error) {
    return [null, error]
  }
}

export const deleteTransaction = async (id: number) => {
  return await axios.delete(`${TRANSACTION_URL}?id=${id}`)
}

export const rejectTransaction = async (id: number) => {
  return await axios.patch(`${TRANSACTION_URL}/reject?id=${id}`, {
    id,
  })
}
