import { GridCellProps } from '@progress/kendo-react-grid';

export const UppercaseFirstLetter = (props: GridCellProps) => {
  const { dataItem } = props;
  const field = props.field || '';
  let dataValue = dataItem ? (dataItem[field] ? dataItem[field] : '') : '';

  if (dataValue) {
    dataValue = dataValue.charAt(0).toUpperCase() + dataValue.slice(1);
  } else {
    dataValue = 'N/A';
  }

  return (
    <td>
      {dataValue.trim() ? dataValue : 'N/A'}
    </td>
  );
};