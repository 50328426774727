import {GridSetup} from '../../../shared/model/grid-config'

export enum ClientProfileTab {
  Profile,
  Poc,
  Wallet,
  Orders,
  Transactions,
  file,
  payIns,
}

export const Initial_GridSetup: GridSetup = {
  sort: [{field: 'Id', dir: 'desc'}],
  skip: 0,
  take: 10,
  filter: {
    logic: 'and',
    filters: [],
  },
  filterOperators: {
    text: [
      {
        text: 'grid.filterContainsOperator',
        operator: 'contains',
      },
    ],
  },
}

export const FIELD_COLUMN_MAP = [
  {field: 'firstName', map: 'FirstName', type: typeof ''},
  {field: 'lastName', map: 'LastName', type: typeof ''},
  {field: 'email', map: 'Email', type: ''},
  {field: 'zaiCreatedDate', map: 'ZaiCreatedDate', type: typeof ''},
  {field: 'zaiLastModified', map: 'ZaiLastModified', type: typeof ''},
  {field: 'companyName', map: 'CompanyName', type: typeof ''},
  {field: 'walletBalance', map: 'WalletBalance', type: typeof 0},
  {field: 'paymentCycleName', map: 'PaymentCycleName', type: typeof ''},
  {field: 'debitAmount', map: 'DebitAmount', type: typeof 0},
]

export const FIELD_DEFAULT = {field: 'zaiCreatedDate', dir: 'asc'}
export const PAYINS_FIELD_DEFAULT = {field: 'id', dir: 'desc'}

export const FILTER_DEFAULT = 'Id > 0 '

export enum ClientType {
  Individual = 1,
  Company = 2,
}

export enum PaymentCycleType {
  OneTime = 1,
  Weekly = 2,
  Fortnite = 3,
  Monthly = 4,
}
