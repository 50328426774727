import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'

import {IDebitWallet} from '../../models/debit-wallet'

interface IProps {
  debitData: IDebitWallet | undefined
}

const NppDetail: React.FC<IProps> = ({debitData}) => {
  const intl = useIntl()
  const [bpayDetails, setBpayDetails] = useState({biller_code: '', reference: ''})

  useEffect(() => {
    if (debitData?.walletBpayResponse) {
      setBpayDetails({
        biller_code: debitData?.walletBpayResponse.biller_code,
        reference: debitData?.walletBpayResponse.reference,
      })
    }
  }, [debitData?.walletBpayResponse])

  return (
    <React.Fragment>
      <h2>{intl.formatMessage({id: 'ECOMMERCE.FIELD.BPAYINFORMATION'})}</h2>
      {!debitData?.walletBpayResponse ? (
        <div className='form-group mt-2'>
          <label className='form-label' htmlFor='noRecords'>
            {intl.formatMessage({id: 'ECOMMERCE.VALIDATION.NORECORDS'})}
          </label>
        </div>
      ) : (
        <div className='row mx-1'>
          <div className='col-12 mt-2'>
            <h4>{intl.formatMessage({id: 'ECOMMERCE.FIELD.BILLERCODE'})}</h4>
          </div>
          <div className='col-12'>{bpayDetails.biller_code}</div>
          <div className='col-12 mt-2'>
            <h4>{intl.formatMessage({id: 'ECOMMERCE.FIELD.REFERENCE'})}</h4>
          </div>
          <div className='col-12'>{bpayDetails.reference}</div>
        </div>
      )}
    </React.Fragment>
  )
}
export {NppDetail}
