import React, {useMemo, useState} from 'react'
import {IAlert} from '../../../shared/components/CustomAlert'
import {TOASTER_DEFAULT} from '../constant/default'

interface IProps {
  contextToaster: IAlert
  setContextToaster: (alert: IAlert) => void
}

export const Context = React.createContext<IProps>({
  contextToaster: TOASTER_DEFAULT,
  setContextToaster: (data: IAlert) => {},
})

export const ContextProvider: React.FC = ({children}) => {
  const [contextToaster, setContextToaster] = useState<IAlert>(TOASTER_DEFAULT)

  const value: IProps = useMemo(() => {
    return {
      contextToaster,
      setContextToaster,
    }
  }, [contextToaster, setContextToaster])

  return <Context.Provider value={value}>{children}</Context.Provider>
}
