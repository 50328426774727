import {Grid, GridColumn as Column, GridCellProps} from '@progress/kendo-react-grid'

import {FC, Fragment, useCallback, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'

import {DateFormatCell} from '../../../../../shared/components/kendo/format/DateFormatCell'
import {ITransaction, ITransactionData} from '../../../../transactions/models/transaction-model'
import {transactionStatusMasking} from '../../../../../shared/service/utils'
import {UppercaseFirstLetter} from '../../../../../shared/components/kendo/format/UppercaseFirstLetter'
import {AuthorizeTransactionModal} from '../../../../transactions/components/partials/AuthorizeTransactionModal'
import {TRANSACTION_FORM_DEFAULT} from '../../../../transactions/constants/default'
import {getClientTransactions} from '../../../api/ClientApi'
import {KendoI18Provider} from '../../../../../../_metronic/i18n/kendoI18Provider'

interface IProps {
  clientTransactions: ITransactionData[] | undefined
  clientId: string
}

const ClientTransactions: FC<IProps> = ({clientTransactions, clientId}) => {
  const intl = useIntl()
  const [tableData, setTableData] = useState<ITransactionData[]>([])
  const [modalAuthorizeTransactionShow, setModalAuthorizeTransactionShow] = useState(false)
  const [selectedTransaction, setSelectedTransaction] =
    useState<ITransaction>(TRANSACTION_FORM_DEFAULT)

  const fetchData = useCallback(() => {
    getClientTransactions(clientId)
      .then((response: any) => {
        setTableData(response.data.items)
      })
      .catch(() => {})
  }, [])

  useEffect(() => {
    if (clientTransactions) {
      setTableData(clientTransactions)
    }
  }, [clientTransactions])

  const statusCell = (props: GridCellProps) => {
    return (
      <td className='k-command-cell'>
        <span className='custom-header-cell'>
          {transactionStatusMasking(props.dataItem.status)}
        </span>
      </td>
    )
  }

  const handleAuthorizeButton = (data: ITransaction) => {
    setSelectedTransaction(data)
    setModalAuthorizeTransactionShow(true)
  }

  const actionCellButton = (props: GridCellProps) => {
    return props.dataItem.statusName === 'awaitingapproval' ? (
      <td className='k-command-cell'>
        <button
          type='button'
          className='btn btn-primary btn-sm col-md-12 p-4'
          onClick={() => handleAuthorizeButton(props.dataItem)}
        >
          {intl.formatMessage({id: 'ECOMMERCE.FIELD.AUTHORIZETRANSACTION'})}
        </button>
      </td>
    ) : (
      <td className='k-command-cell' />
    )
  }

  const closeAuthorizeTransactionModal = (refresh: boolean = false) => {
    setModalAuthorizeTransactionShow(false)
    if (refresh && selectedTransaction.id) {
      fetchData()
    }
  }

  return (
    <Fragment>
      <AuthorizeTransactionModal
        show={modalAuthorizeTransactionShow}
        transactionData={selectedTransaction}
        handleClose={closeAuthorizeTransactionModal}
      />
      <KendoI18Provider>
        <Grid resizable={true} data={tableData}>
          <Column cell={actionCellButton} />
          <Column title={intl.formatMessage({id: 'ECOMMERCE.FIELD.TRANSACTIONID'})} field='id' />
          <Column
            title={intl.formatMessage({id: 'ECOMMERCE.FIELD.TRANSACTIONTYPE'})}
            field='transactionTypeName'
            cell={UppercaseFirstLetter}
          />
          <Column
            title={intl.formatMessage({id: 'ECOMMERCE.FIELD.CREATEDDATE'})}
            field='created'
            cell={DateFormatCell}
          />
          <Column
            title={intl.formatMessage({id: 'ECOMMERCE.FIELD.STATUS'})}
            field='status'
            cell={statusCell}
          />
          <Column title={intl.formatMessage({id: 'ECOMMERCE.FIELD.CURRENCY'})} field='currency' />
          <Column
            title={intl.formatMessage({id: 'ECOMMERCE.FIELD.AMOUNT'})}
            field='amountFormatted'
          />
          <Column
            title={intl.formatMessage({id: 'ECOMMERCE.FIELD.BANKACCOUNT'})}
            field='bankAccount'
          />
        </Grid>
      </KendoI18Provider>
    </Fragment>
  )
}

export default ClientTransactions
