import {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'

import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {DirectDebitForm} from './components/partials/DirectDebitForm'
import {useFeatureFlags} from '../../context/FeatureFlags'

const CreateClientPage: FC = () => {
  const intl = useIntl()
  const {features} = useFeatureFlags()

  const [isDirectDebitFormFeatureEnabled, setIsDirectDebitFormFeatureEnabled] = useState(false)
  const [isDirectDebitAgreementFeatureEnabled, setIsDirectDebitAgreementFeatureEnabled] =
    useState(false)
  const widgetsBreadCrumbs: Array<PageLink> = []

  useEffect(() => {
    if (features.some((feature) => feature.name === 'DirectDebitFormFeature')) {
      setIsDirectDebitFormFeatureEnabled(true)
    }
    if (features.some((feature) => feature.name === 'DirectDebitAgreementFeature')) {
      setIsDirectDebitAgreementFeatureEnabled(true)
    }
  }, [features])

  return (
    <>
      {isDirectDebitFormFeatureEnabled ? (
        <div className='card card-custom'>
          <div className='card-body'>
            <div className='container-fluid px-0 mb-10'>
              <div className='row justify-content-between align-items-center'>
                <PageTitle breadcrumbs={widgetsBreadCrumbs}>
                  {intl.formatMessage({id: 'ACTION.NEWDIRECTDEBIT'})}
                </PageTitle>
                <DirectDebitForm
                  isDirectDebitAgreementFeatureEnabled={isDirectDebitAgreementFeatureEnabled}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className='alert alert-info'>Direct Debit Form feature is not enabled.</div>
      )}
    </>
  )
}

export default CreateClientPage
