import { FC, useContext } from 'react'
import { Modal } from 'react-bootstrap-v5'
import { useIntl } from 'react-intl'

import { IClientsData } from '../../models/client'
import { ClientContext } from '../../context/ClientContext'
import { deleteClient } from '../../api/ClientApi'
import { ButtonModal } from '../../../../../_metronic/partials/content/button/modal/ButtonModal'
import { getErrorMessage } from '../../../../shared/service/utils'

interface IProps {
  show: boolean
  handleClose: () => void
  clientData?: IClientsData
  deleteCallback: (errorMessage?: string) => void
}

const DeleteClientModal: FC<IProps> = ({ show, handleClose, clientData, deleteCallback }) => {
  const intl = useIntl()
  const { setContextToaster } = useContext(ClientContext)

  const deleteUserHandler = async () => {
    if (clientData) {
      try {
        const [, error] = await deleteClient(clientData?.unqId || '')

        setContextToaster({
          message: intl.formatMessage({ id: 'CLIENT.DELETEMESSAGE' }),
          header: intl.formatMessage({ id: 'CLIENT.DELETEHEADER' }),
          type: 'primary',
        })

        if (error) {
          throw error
        } else {
          deleteCallback()
        }
      } catch (error) {
        const errorMessage = getErrorMessage(error)
        deleteCallback(errorMessage)
        setContextToaster({
          message: errorMessage,
          header: `Error Removing Client`,
          type: 'danger',
        })
      }
    }
  }

  return (
    <Modal show={show} onHide={handleClose} size='sm'>
      <Modal.Header closeButton>
        <Modal.Title>{intl.formatMessage({ id: 'CLIENT.DELETEUSER' })}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{intl.formatMessage({ id: 'CLIENT.DELETEWARNNING' })}</p>
      </Modal.Body>
      <Modal.Footer>
        <ButtonModal proceedHandler={deleteUserHandler} cancelHandler={handleClose}></ButtonModal>
      </Modal.Footer>
    </Modal>
  )
}

export { DeleteClientModal }
