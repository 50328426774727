import React from 'react'
import { View } from '@react-pdf/renderer'

const Row = ({ children, style }: any) => (
  <View
    style={[{ display: 'flex', flexDirection: 'row' }, style]}>
    {children}
  </View>
)

export default Row
