import {DatePicker} from '@progress/kendo-react-dateinputs'

import {FC, useEffect, useState} from 'react'
import {Controller} from 'react-hook-form'
import {ErrorMessage} from '@hookform/error-message'

import {IClientsData} from '../../../models/client'
import {useFeatureFlags} from '../../../../../context/FeatureFlags'
import {blockNonNumericChars} from '../../../../../shared/service/utils'

interface IProps {
  clientData: IClientsData | undefined
  register: any
  control: any
  setValue: any
  intl: any
  errors: any
  companyDetails: boolean
  isClientDataFeatureEnabled: boolean
}

const PersonalData: FC<IProps> = ({
  clientData,
  register,
  control,
  setValue,
  intl,
  errors,
  companyDetails,
  isClientDataFeatureEnabled,
}) => {
  return (
    <div>
      <div className='row align-items-center mt-6 pe-2'>
        <div className='col-lg-6 col-md-6 col-12 mb-4'>
          <label className='form-label' htmlFor='firstName'>
            {intl.formatMessage({id: 'ECOMMERCE.FIELD.FIRSTNAME'})}
            <span className='text-danger'>*</span>
          </label>
          <input
            type='text'
            className={`form-control${errors.firstName ? ' border-danger' : ''}`}
            placeholder={
              intl.formatMessage({id: 'ECOMMERCE.FIELD.ENTER'}) +
              ' ' +
              intl.formatMessage({id: 'ECOMMERCE.FIELD.FIRSTNAME'})
            }
            {...register('firstName')}
          />
          <ErrorMessage errors={errors} name='firstName' as={<span className='spanError text-danger' />} />
        </div>
        <div className='col-lg-6 col-md-6 col-12 mb-4'>
          <label className='form-label' htmlFor='lastName'>
            {intl.formatMessage({id: 'ECOMMERCE.FIELD.LASTNAME'})}
            <span className='text-danger'>*</span>
          </label>
          <input
            type='text'
            className={`form-control${errors.lastName ? ' border-danger' : ''}`}
            placeholder={
              intl.formatMessage({id: 'ECOMMERCE.FIELD.ENTER'}) +
              ' ' +
              intl.formatMessage({id: 'ECOMMERCE.FIELD.LASTNAME'})
            }
            {...register('lastName')}
          />
          <ErrorMessage errors={errors} name='lastName' as={<span className='spanError text-danger' />} />
        </div>
      </div>
      {!isClientDataFeatureEnabled && (
        <>
          <div className='row align-items-center mt-6 pe-2'>
            <div className='col-lg-6 col-md-6 col-12 mb-4'>
              <label className='form-label' htmlFor='englishName'>
                {intl.formatMessage({id: 'ECOMMERCE.FIELD.ENGLISTNAME'})}
              </label>
              <input
                type='text'
                className={`form-control${errors.englishName ? ' border-danger' : ''}`}
                placeholder={
                  intl.formatMessage({id: 'ECOMMERCE.FIELD.ENTER'}) +
                  ' ' +
                  intl.formatMessage({id: 'ECOMMERCE.FIELD.ENGLISTNAME'})
                }
                {...register('englishName')}
              />
            </div>
            <div className='col-lg-6 col-md-6 col-12 mb-4'>
              <div className='row align-items-center mt-6 pe-2'>
                <label className='form-label' htmlFor='id'>
                  {intl.formatMessage({id: 'ECOMMERCE.FIELD.SEX'})}
                  <span className='text-danger'>*</span>
                </label>
                <div
                  className={`checkbox-list d-flex align-items-buttom p-2 ${
                    errors.sex ? 'form-control border-danger' : ''
                  }`}
                >
                  <label className='checkbox me-5'>
                    <input type='radio' value='Male' {...register('sex')} />
                    <span> {intl.formatMessage({id: 'ECOMMERCE.FIELD.MALE'})}</span>
                  </label>
                  <label className='checkbox  me-5'>
                    <input type='radio' value='Female' {...register('sex')} />
                    <span> {intl.formatMessage({id: 'ECOMMERCE.FIELD.FEMALE'})}</span>
                  </label>
                  <label className='checkbox  me-5'>
                    <input type='radio' value='Not To Say' {...register('sex')} />
                    <span> {intl.formatMessage({id: 'ECOMMERCE.FIELD.PREFEREDNOTTOSAY'})}</span>
                  </label>
                </div>
                <ErrorMessage errors={errors} name='sex' as={<span className='spanError text-danger' />} />
              </div>
            </div>
          </div>
          <div className='row align-items-center mt-6 pe-2'>
            <div className='col-lg-6 col-md-6 col-12 mb-4'>
              <label className='form-label' htmlFor='occupation'>
                {intl.formatMessage({id: 'ECOMMERCE.FIELD.OCCUPATION'})}
              </label>
              <input
                type='text'
                className={`form-control${errors.occupation ? ' border-danger' : ''}`}
                placeholder={
                  intl.formatMessage({id: 'ECOMMERCE.FIELD.ENTER'}) +
                  ' ' +
                  intl.formatMessage({id: 'ECOMMERCE.FIELD.OCCUPATION'})
                }
                {...register('occupation')}
              />
            </div>
            <div className='col-lg-6 col-md-6 col-12 mb-4'>
              <label className='form-label' htmlFor='dateOfBirth'>
                {intl.formatMessage({id: 'ECOMMERCE.FIELD.DATEOFBIRTH'})}
              </label>
              <span className='text-danger'>*</span>
              <Controller
                control={control}
                name='dateOfBirth'
                render={({field: {value, name, onChange}}) => {
                  return (
                    <DatePicker
                      className={`${errors.dateOfBirth ? ' border-danger' : ''}`}
                      format='dd/MM/yyyy'
                      name={name}
                      onChange={onChange}
                      value={value ? new Date(value) : null}
                      validationMessage={intl.formatMessage({
                        id: 'ECOMMERCE.VALIDATION.PLEASEENTERVALIDDATE',
                      })}
                    />
                  )
                }}
              />
              <ErrorMessage
                errors={errors}
                name='dateOfBirth'
                as={<span className='spanError text-danger' />}
              />
            </div>
          </div>
        </>
      )}
      {!companyDetails && (
        <div className='row align-items-center mt-6 pe-2'>
          <div className='col-lg-6 col-md-6 col-12 mb-4'>
            <label className='form-label' htmlFor='mobile'>
              {intl.formatMessage({id: 'ECOMMERCE.FIELD.MOBILENUMBER'})}
            </label>
            <span className='text-danger'>*</span>
            <input
              type='text'
              className={`form-control${errors.mobile ? ' border-danger' : ''}`}
              maxLength={14}
              placeholder={
                intl.formatMessage({id: 'ECOMMERCE.FIELD.ENTER'}) +
                ' ' +
                intl.formatMessage({id: 'ECOMMERCE.FIELD.MOBILENUMBER'})
              }
              {...register('mobile')}
              onKeyPress={blockNonNumericChars}
            />
            <ErrorMessage errors={errors} name='mobile' as={<span className='spanError text-danger' />} />
          </div>
          <div className='col-lg-6 col-md-6 col-12 mb-4'>
            <label className='form-label' htmlFor='email'>
              {intl.formatMessage({id: 'ECOMMERCE.FIELD.EMAIL'})}
            </label>
            <span className='text-danger'>*</span>
            <input
              type='text'
              className={`form-control${errors.email ? ' border-danger' : ''}`}
              placeholder={
                intl.formatMessage({id: 'ECOMMERCE.FIELD.ENTER'}) +
                ' ' +
                intl.formatMessage({id: 'ECOMMERCE.FIELD.EMAIL'})
              }
              {...register('email')}
            />
            <ErrorMessage errors={errors} name='email' as={<span className='spanError text-danger' />} />
          </div>
        </div>
      )}
    </div>
  )
}

export default PersonalData
