import React from 'react'
import { Text, StyleSheet } from '@react-pdf/renderer'
import { config } from './config'

const styles = StyleSheet.create({
  title: {
    fontFamily: config.fontFamily,
    fontSize: 24,
    fontWeight: 600,
    width: '100%',
    textAlign: 'center',
    marginBottom: 20,
  }
})

const Title = ({ children }: any) => <Text style={styles.title}>{children}</Text>

export default Title
