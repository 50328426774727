import { FC } from 'react'
import { ErrorMessage } from '@hookform/error-message'
import { blockNonNumericChars } from '../../../../shared/service/utils'

interface IProps {
  register: any
  control: any
  setValue: any
  intl: any
  errors: any
}

const PersonalData: FC<IProps> = ({
  register,
  control,
  setValue,
  intl,
  errors,
}) => {
  return (
    <div>
      <div className='row align-items-center mt-6 pe-2'>
        <div className='col-lg-6 col-md-6 col-12 mb-4'>
          <label className='form-label' htmlFor='firstName'>
            {intl.formatMessage({ id: 'ECOMMERCE.FIELD.FIRSTNAME' })}
            <span className='text-danger'>*</span>
          </label>
          <input
            type='text'
            className={`form-control${errors.firstName ? ' border-danger' : ''}`}
            placeholder={
              intl.formatMessage({ id: 'ECOMMERCE.FIELD.ENTER' }) +
              ' ' +
              intl.formatMessage({ id: 'ECOMMERCE.FIELD.FIRSTNAME' })
            }
            {...register('firstName')}
          />
          <ErrorMessage errors={errors} name='firstName' as={<span className='spanError text-danger' />} />
        </div>
        <div className='col-lg-6 col-md-6 col-12 mb-4'>
          <label className='form-label' htmlFor='lastName'>
            {intl.formatMessage({ id: 'ECOMMERCE.FIELD.LASTNAME' })}
            <span className='text-danger'>*</span>
          </label>
          <input
            type='text'
            className={`form-control${errors.lastName ? ' border-danger' : ''}`}
            placeholder={
              intl.formatMessage({ id: 'ECOMMERCE.FIELD.ENTER' }) +
              ' ' +
              intl.formatMessage({ id: 'ECOMMERCE.FIELD.LASTNAME' })
            }
            {...register('lastName')}
          />
          <ErrorMessage errors={errors} name='lastName' as={<span className='spanError text-danger' />} />
        </div>
      </div>

      <div className='row align-items-center mt-6 pe-2'>
        <div className='col-lg-6 col-md-6 col-12 mb-4'>
          <label className='form-label' htmlFor='mobile'>
            {intl.formatMessage({ id: 'ECOMMERCE.FIELD.MOBILENUMBER' })}
          </label>
          <span className='text-danger'>*</span>
          <input
            type='text'
            className={`form-control${errors.mobile ? ' border-danger' : ''}`}
            maxLength={14}
            placeholder={
              intl.formatMessage({ id: 'ECOMMERCE.FIELD.ENTER' }) +
              ' ' +
              intl.formatMessage({ id: 'ECOMMERCE.FIELD.MOBILENUMBER' })
            }
            {...register('mobile')}
            onKeyPress={blockNonNumericChars}
          />
          <ErrorMessage errors={errors} name='mobile' as={<span className='spanError text-danger' />} />
        </div>
        <div className='col-lg-6 col-md-6 col-12 mb-4'>
          <label className='form-label' htmlFor='email'>
            {intl.formatMessage({ id: 'ECOMMERCE.FIELD.EMAIL' })}
          </label>
          <span className='text-danger'>*</span>
          <input
            type='text'
            className={`form-control${errors.email ? ' border-danger' : ''}`}
            placeholder={
              intl.formatMessage({ id: 'ECOMMERCE.FIELD.ENTER' }) +
              ' ' +
              intl.formatMessage({ id: 'ECOMMERCE.FIELD.EMAIL' })
            }
            {...register('email')}
          />
          <ErrorMessage errors={errors} name='email' as={<span className='spanError text-danger' />} />
        </div>
      </div>
    </div>
  )
}

export default PersonalData
