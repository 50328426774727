import {SortDescriptor} from '@progress/kendo-data-query'
import axios from 'axios'
import {IItemsData} from '../models/items'
export const ORDER_URL = `${process.env.REACT_APP_API_URL}/Order`
export const CLIENT_URL = `${process.env.REACT_APP_API_URL}/Client`
export const MASTER_WALLET_URL = `${process.env.REACT_APP_API_URL}/MasterWallet`
export const CURRENCY_URL = `${process.env.REACT_APP_API_URL}/Currency`

export const getOrders = async (
  offset: number,
  limit: number,
  pageSort: any,
  isCreatedByDebit: boolean,
  userId: string,
  objectFilter: any
) => {
  const {buyerName, name, sellerName, state, uuid, debitAmount, paymentCycleName} = objectFilter
  try {
    return await axios.get(`${ORDER_URL}/get`, {
      params: {
        offset,
        limit,
        sort: `${pageSort.field} ${pageSort.dir}`,
        isCreatedByDebit,
        userId,
        buyerName,
        name,
        sellerName,
        state,
        uuid,
        debitAmount,
        paymentCycleName,
      },
    })
  } catch (err) {
    return []
  }
}

export const getAvailableClients = async (keyword?: string) => {
  try {
    const {data} = await axios.get(`${CLIENT_URL}/getavailableclients`, {
      params: {
        keyword,
      },
    })
    return [data, null]
  } catch (error) {
    return [null, error]
  }
}

export const getCurrencies = async (keyword?: string) => {
  try {
    if (keyword == null || keyword === '') return [null, null]

    const {data} = await axios.get(`${CURRENCY_URL}`, {
      params: {
        keyword,
      },
    })
    return [data, null]
  } catch (error) {
    return [null, error]
  }
}

export const getCurrentCurrencies = async () => {
  try {
    const {data} = await axios.get(`${CURRENCY_URL}/current`, {
      params: {},
    })
    return [data, null]
  } catch (error) {
    return [null, error]
  }
}

export const createItem = async (form: any) => {
  try {
    const {data} = await axios.post(`${ORDER_URL}/create`, form)
    return [data, null]
  } catch (error) {
    return [null, error]
  }
}

export const updateItem = async (form: any) => {
  try {
    const {data} = await axios.put(`${ORDER_URL}/update`, form)
    return [data, null]
  } catch (error) {
    return [null, error]
  }
}

export const reRunItem = async (uuid: string, buyerId: string) => {
  try {
    const data = await axios.get(`${ORDER_URL}/re_run?Id=${uuid}&Buyer_id=${buyerId}`)
    return [data, null]
  } catch (error) {
    return [null, error]
  }
}

export const getMasterUser = async () => {
  try {
    const {data} = await axios.get(`${MASTER_WALLET_URL}/getmasteruser`)
    return [data, null]
  } catch (err) {
    return [null, err]
  }
}

export const authorizeOrder = async (id: number) => {
  const payload: any = {id: id}

  try {
    const {data} = await axios.post(`${ORDER_URL}/authorize`, payload)
    return [data, null]
  } catch (error) {
    return [null, error]
  }
}

export const generateCsv = async () => {
  try {
    const {data} = await axios.post(
      `${ORDER_URL}/generate-csv`,
      {},
      {
        headers: {
          'Content-Type': 'application/json', // Set the appropriate content type
        },
      }
    )
    return [data, null]
  } catch (error) {
    return [null, error]
  }
}
