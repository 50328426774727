import {
  TabStrip,
  TabStripSelectEventArguments,
  TabStripTab,
} from "@progress/kendo-react-layout";

import { FC, Fragment, useContext, useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { yupResolver } from '@hookform/resolvers/yup'
import { Resolver, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { PDFDownloadLink } from '@react-pdf/renderer';

import { CustomAlert, IAlert } from '../../../../shared/components/CustomAlert'
import { IClientsData } from "../../../client/models/client";
import { CLIENT_DATA_FORM_DEFAULT } from "../../../client/constant/client-default";
import PersonalData from "./PersonalData";
import PersonalCommonData from "./PersonalCommonData";
import BankDetailsSection from "./BankDetailSection";
import DirectDebit from "./DirectDebit";
import PaymentTerm from "./PaymentTerm";
import Agreement from "./Agreement";
import { Context } from "../../context";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "../../../../../setup";
import { UserModel } from "../../../auth/models/UserModel";
import { getClientById, updateClient } from "../../../client/api/ClientApi";
import { getErrorMessage, getUTCDate } from "../../../../shared/service/utils";
import ClientFormValidationSchema from "../../validators/client-form";
import DirectDebitAgreementPDF from "../../../client/components/partials/debit-pdf-form/DirectDebitAgreementPDF";

const loadingPanel = (
  <div className='position-fixed k-loading-mask'>
    <span className='k-loading-text'>Loading</span>
    <div className='k-loading-image'></div>
    <div className='k-loading-color'></div>
  </div>
)

const Form: FC = () => {
  const intl = useIntl()
  const history = useHistory()
  const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel
  const { setContextToaster } = useContext(Context)
  const pdfDownloadLinkRef = useRef<HTMLButtonElement | null>(null);

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [formNotification, setformNotification] = useState<IAlert | undefined>()
  const [selected, setSelected] = useState<number>(0);
  const [selectedClient, setSelectedClient] = useState<IClientsData[] | undefined>(undefined)

  const clientFormValidationSchema = ClientFormValidationSchema()
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setValue,
    getValues,
    trigger,
  } = useForm({
    defaultValues: CLIENT_DATA_FORM_DEFAULT,
    reValidateMode: 'onSubmit',
    resolver: yupResolver(clientFormValidationSchema) as unknown as Resolver<IClientsData, any>,
  })

  useEffect(() => {
    if (user.unqId) {
      setIsLoading(true)
      getClientById(user.unqId)
        .then((result: any) => {
          setSelectedClient(result)
          setIsLoading(false)
        })
        .catch(() => {
          history.push('/dashboard')
          setIsLoading(false)
        })
    }
  }, [user.unqId])

  useEffect(() => {
    // Initialize the clients / hook form inputs
    if (selectedClient && selectedClient.length > 0) {
      const files = selectedClient[0]?.files || []
      const updatedClient = {
        ...selectedClient[0],
        clientType: selectedClient[0].clientType || 1,
        companyName: selectedClient[0].companyName || '',
        abnacn: selectedClient[0].abnacn || '',
        mobile: selectedClient[0].mobile || 0,
        email: selectedClient[0].email || '',
        dateOfBirth: new Date(selectedClient[0].dateOfBirth || ''),
        issuedDate: new Date(selectedClient[0].issuedDate || ''),
        expiryDate: new Date(selectedClient[0].expiryDate || ''),
        paymentCycleStartDate: new Date(selectedClient[0].paymentCycleStartDate || ''),
        paymentCycleEndDate: new Date(selectedClient[0].paymentCycleEndDate || ''),
        frontImageFileName: files.find(
          (f) => f.cType === selectedClient[0].identity && f.cIndex === 1
        )?.name,
        backImageFileName: files.find((f) => f.cType === selectedClient[0].identity && f.cIndex === 2)
          ?.name,
      }

      reset(updatedClient)
    } else {
      reset(CLIENT_DATA_FORM_DEFAULT)
    }
  }, [selectedClient, reset])

  const handleSelect = (e: TabStripSelectEventArguments) => {
    setSelected(e.selected);
  };

  const onReset = () => {
    reset(CLIENT_DATA_FORM_DEFAULT)
  }

  const onSubmit = async (values: IClientsData) => {
    setIsLoading(true)
    try {
      values.paymentCycleStartDateUtc = values.paymentCycleStartDate ? getUTCDate(values.paymentCycleStartDate) : null
      await createOrUpdateClient(updateClient, values)

      setformNotification({
        message: 'Client Update successfully.',
        header: 'Client Update',
        type: 'primary',
      })
    } catch (error) {
      const errorMessage = getErrorMessage(error)
      setformNotification({
        message: errorMessage,
        header: `Error Updating Client`,
        type: 'danger',
      })
    }

    setIsLoading(false)
  }

  const createOrUpdateClient = async (apiFunction: any, payload: any) => {
    try {
      const formData = new FormData()

      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          if (key === 'banks') {
            for (let j = 0; j < payload.banks.length; j++) {
              const bank = payload.banks[j]
              for (const bankKey in bank) {
                formData.append(`clients[0].banks[${j}].${bankKey}`, bank[bankKey])
              }
            }
          } else {
            if (key.indexOf('date') > -1 || key.indexOf('Date') > -1) {
              const dateObject = new Date(payload[key])
              var dateString = dateObject.toDateString()
              var timeString =
                ('0' + dateObject.getHours()).slice(-2) +
                ':' +
                ('0' + dateObject.getMinutes()).slice(-2) +
                ':' +
                ('0' + dateObject.getSeconds()).slice(-2)

              var formattedDate = dateString + ' ' + timeString

              formData.append(`clients[0].${key}`, formattedDate)
            } else {
              formData.append(`clients[0].${key}`, payload[key] == null ? '' : payload[key])
            }
          }
        }
      }

      const [data, error] = await apiFunction(formData)

      if (data) {
        return data
      }

      if (error) {
        throw error
      }
    } catch (error) {
      throw error
    }
  }

  // Function to generate the file name
  const generateFileName = (formData: IClientsData) => {
    const currentDate = new Date();
    const formattedDate = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1)
      .toString()
      .padStart(2, '0')}-${currentDate.getDate().toString().padStart(2, '0')}`;

    // Combine all parts of the file name
    const fileName = `Direct Debit Agreement_${formattedDate}_${formData.banks[0].bankName}_${formData.lastName}_${formData.firstName}.pdf`;

    return fileName;
  };

  const handleSavePDF = () => {
    if (pdfDownloadLinkRef.current) {
      pdfDownloadLinkRef.current.click();
    }
  }

  return (
    <Fragment>
      {isLoading && loadingPanel}
      {formNotification && (
        <CustomAlert {...formNotification} closeToaster={() => setformNotification(undefined)} />
      )}
      <TabStrip selected={selected} onSelect={handleSelect}>
        <TabStripTab title={intl.formatMessage({ id: 'MENU.MYPROFILE' })} >
          <form
            onSubmit={handleSubmit(onSubmit)}
            onReset={onReset}
            name='client'
            encType='multipart/form-data'
            noValidate
            className="px-4"
          >
            <div className='d-flex justify-content-end'>
              <button
                type='button'
                className='btn btn-outline-primary col-auto'
                onClick={() => {
                  history.push('/dashboard')
                  setContextToaster({
                    message: ``,
                    header: ``,
                    type: 'primary',
                  })
                }}
              >
                {intl.formatMessage({ id: 'ECOMMERCE.ACTION.CANCEL' })}
              </button>
              <button type='submit' className='btn btn-primary col-auto'>
                {intl.formatMessage({ id: 'ECOMMERCE.ACTION.SAVE' })}
              </button>
            </div>
            <PersonalData
              register={register}
              control={control}
              intl={intl}
              errors={errors}
              setValue={setValue}
            />
            <PersonalCommonData
              register={register}
              control={control}
              intl={intl}
              errors={errors}
              setValue={setValue}
            />

            <br />
            <hr />

            <BankDetailsSection
              register={register}
              control={control}
              intl={intl}
              errors={errors}
              setValue={setValue}
            />

            <br />
            <hr />
            <DirectDebit
              register={register}
              control={control}
              setValue={setValue}
              intl={intl}
              watch={watch}
              errors={errors}
            />

            <br />
            <hr />
            <PaymentTerm
              register={register}
              control={control}
              setValue={setValue}
              intl={intl}
              watch={watch}
              errors={errors}
              clientData={selectedClient?.[0]}
            />
          </form>
        </TabStripTab>
        <TabStripTab title={intl.formatMessage({ id: 'MENU.DIRECTDEBITAGREEMENT' })}>
          <div className='d-flex justify-content-end mb-8'>
            <button className='btn btn-primary col-auto' onClick={handleSavePDF}>
              {intl.formatMessage({ id: 'ECOMMERCE.ACTION.SAVEASPDF' })}
            </button>
          </div>
          <Agreement
            clientData={selectedClient?.[0]}
          />
          {selectedClient?.[0] &&
            // <PDFDownloadLink document={<DirectDebitAgreementPDF formData={selectedClient?.[0]} />} fileName={generateFileName(selectedClient?.[0])}>
            <PDFDownloadLink document={<DirectDebitAgreementPDF formData={selectedClient?.[0]} />} fileName={generateFileName(selectedClient?.[0])}>
              {({ blob, url, loading, error }) =>
                loading ? "Loading..." :
                  <button
                    ref={pdfDownloadLinkRef}
                    className='d-none'
                  >
                    Print
                  </button>
              }
            </PDFDownloadLink>
          }
        </TabStripTab>
      </TabStrip>
    </Fragment>
  )
}
export { Form }
