import { GridCellProps } from '@progress/kendo-react-grid'
import { dateFormat } from '../../../service/utils'

export const DateFormatCell = (props: GridCellProps) => {
    const { dataItem } = props
    const field = props.field || ''
    const dataValue = dataItem ? (dataItem[field] ? dataItem[field] : '') : ''

    return (
        <td>
            {dateFormat(dataValue)}
        </td>
    )
}