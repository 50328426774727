import {FC} from 'react'
import {useLang} from './Metronici18n'
import {IntlProvider as GridIntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import kdEnMessages from './messages/kendo-message/en/en.json'
import kdZhMessages from './messages/kendo-message/zh/zh.json'

import {
  loadMessages,
} from "@progress/kendo-react-intl";
import { IntlProvider } from 'react-intl';
import { KendoToolI18Provider } from './kendoToolI18Provider';


const KendoI18Provider: FC = ({children}) => {
  const locale = useLang()

  loadMessages(kdEnMessages, 'en');
  loadMessages(kdZhMessages, 'zh');


  return (
    <LocalizationProvider language={locale}>
        <GridIntlProvider locale={locale}>
          <KendoToolI18Provider>
            {children}
          </KendoToolI18Provider >
        </GridIntlProvider>
    </LocalizationProvider>
  )

}

export { KendoI18Provider }
