import {IClients, IClientsData} from '../../client/models/client'
import {IRole} from '../../users/models/user-model'
import {FIELD_COLUMN_MAP, FIELD_DEFAULT} from '../constants/default'
import {IClientBankOption, IClientOption} from '../models/transaction-model'
import {SortDescriptor} from '@progress/kendo-data-query'
export const transformDataClient = (client: IClientsData[]) => {
  return (client || []).map((client) => ({
    value: client.unqId,
    label:
      client.firstName + ' ' + client.lastName + ' - ' + (client.email == null ? '' : client.email),
    client: client,
  }))
}

export const transformDataToBankInfo = (bank: IClientBankOption) => {
  return {
    zaiBankId: bank.bank.zaiBankId,
    bankName: bank.bank.bankName,
    bsb: bank.bank.bsb,
    accountNumber: bank.bank.accountNumber,
    accountName: bank.bank.accountName,
  }
}

export const transformDataToBankInfoFromZai = (bankInfo: any) => {
  return {
    bankName: bankInfo.bank.bank_name,
    bsb: bankInfo.bank.routing_number,
    accountNumber: bankInfo.bank.account_number,
    accountName: bankInfo.bank.account_name,
  }
}

export const transformDataToBankInfoFromMasterWallet = (bankInfo: any) => {
  return {
    clientId: bankInfo.client_id,
    bankName: bankInfo.bank_name,
    bsb1: bankInfo.bsb1,
    bsb2: bankInfo.bsb2,
    bankAccountNumber: bankInfo.account_number,
    bankAccountName: bankInfo.account_name,
    bankBranch: bankInfo.bank_branch,
    bankDescription: bankInfo.bank_description,
  }
}

export const transformSaveClient = (option: IClientOption) => {
  return option.value //(option || []).map((add) => add.value)
}
export const transformTransactionFilter = (filter: any) => {
  if (!filter) return filter

  let newFilter = (filter.filters || []).map((ft: any) => {
    const fieldMap = FIELD_COLUMN_MAP.find((cln) => cln.field === ft.field)

    ft = {
      ...ft,
      field: fieldMap?.objectMap,
      type: fieldMap?.type,
    }
    return ft
  })

  return {...filter, filters: newFilter}
}
export const transformTransactinSort = (sort: SortDescriptor[]) => {
  const configSort: any = {
    ...sort[0],
    field: sort[0] ? sort[0].field : FIELD_DEFAULT.field,
    dir: sort[0] ? sort[0].dir : FIELD_DEFAULT.dir,
  }
  const fieldMap = FIELD_COLUMN_MAP.find((cln) => cln.field === configSort.field)
  const sortField = fieldMap ? fieldMap.objectMap : configSort.field
  return {...configSort, field: sortField}
}

export const transformClientBankListToBankOptions = (banks: any[]) => {
  var bankOptions: IClientBankOption[] = []

  banks.forEach((bank) => {
    bankOptions.push({
      value: bank.bankName,
      label: bank.bankName + ' - ' + bank.bankAccountNumber.slice(-3),
      bank: {
        zaiBankId: bank.zaiBankId,
        bankName: bank.bankName,
        bsb: bank.bsb1 + '-' + bank.bsb2,
        accountNumber: bank.bankAccountNumber,
        accountName: bank.bankAccountName,
      },
    })
  })

  return bankOptions
}
