import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {useHistory, useParams} from 'react-router-dom'
import {useFormik} from 'formik'
import {requestResetPassword} from '../redux/AuthCRUD'

const initialValues = {
  newPassword: '',
  confirmPassword: '',
}

// Define form validation schema using Yup
const resetPasswordSchema = Yup.object().shape({
  newPassword: Yup.string().min(6, 'Password must be at least 6 characters').required('Required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
    .required('Required'),
})

export function ResetPassword() {
  const history = useHistory()
  // Access the 'uuid' parameter from the URL
  const {uuid}: {uuid: string} = useParams()

  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState(false)
  const formik = useFormik({
    initialValues,
    validationSchema: resetPasswordSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)

      // Here, you would typically send a request to reset the password
      // and handle the redirection logic
      // After a successful password reset, you can redirect to the login page
      try {
        // Send a request to reset the password
        const resp = await requestResetPassword(uuid, values.newPassword)
        // Password reset was successful
        history.push('/login')
      } catch (error: any) {
        // Handle network errors or other exceptions
        setHasErrors(true)
        setLoading(false)
        setSubmitting(false)
        setStatus(error.message)
      }
    },
  })
  const isSubmitButtonVisible =
    formik.values.newPassword !== '' &&
    formik.values.confirmPassword !== '' &&
    ((!formik.errors.newPassword && !formik.errors.confirmPassword) || loading)

  return (
    <>
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_password_reset_form'
        onSubmit={formik.handleSubmit}
      >
        <div className='text-center mb-10'>
          {/* begin::Title */}
          <h1 className='text-dark mb-3'>Reset Password ?</h1>
          {/* end::Title */}

          {/* begin::Link */}
          <div className='text-gray-400 fw-bold fs-4'>Enter your new password.</div>
          {/* end::Link */}
        </div>

        {/* begin::Title */}
        {hasErrors && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>
              Sorry, looks like there are some errors detected, Please try again later or contact
              the server manager for assistance.
            </div>
          </div>
        )}
        {/* end::Title */}

        {/* begin::Form group */}
        <div className='fv-row mb-10'>
          <label className='form-label fw-bolder text-gray-900 fs-6'>New Password</label>
          <input
            type='password'
            placeholder=''
            autoComplete='off'
            {...formik.getFieldProps('newPassword')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {'is-invalid': formik.touched.newPassword && formik.errors.newPassword},
              {
                'is-valid': formik.touched.newPassword && !formik.errors.newPassword,
              }
            )}
          />
          {formik.touched.newPassword && formik.errors.newPassword && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.newPassword}</span>
              </div>
            </div>
          )}
        </div>
        <div className='fv-row mb-10'>
          <label className='form-label fw-bolder text-gray-900 fs-6'>Confirm Password</label>
          <input
            type='password'
            placeholder=''
            autoComplete='off'
            {...formik.getFieldProps('confirmPassword')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {'is-invalid': formik.touched.confirmPassword && formik.errors.confirmPassword},
              {
                'is-valid': formik.touched.confirmPassword && !formik.errors.confirmPassword,
              }
            )}
          />
          {formik.touched.confirmPassword && formik.errors.confirmPassword && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.confirmPassword}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
          {isSubmitButtonVisible && (
            <button
              type='submit'
              id='kt_password_reset_submit'
              className='btn btn-lg btn-primary fw-bolder me-4'
            >
              <span className='indicator-label'>Submit</span>
              {loading && (
                <span className='indicator-progress'>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          )}
        </div>
        {/* end::Form group */}
      </form>
    </>
  )
}
