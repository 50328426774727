import {useIntl} from 'react-intl'
import * as yup from 'yup'
import { BankFormValidationSchema as bankFormValidationSchema } from './bank-form'

const ClientFormValidationSchema = () => {
  const intl = useIntl()
  const bankSchema = bankFormValidationSchema()
  const schema = yup.object().shape({
    firstName: yup
      .string()
      .required(intl.formatMessage({id: 'ECOMMERCE.VALIDATION.FIRSTNAMEREQUIRED'})),
    lastName: yup
      .string()
      .required(intl.formatMessage({id: 'ECOMMERCE.VALIDATION.LASTNAMEREQUIRED'})),
    sex: yup
      .string()
      .required(intl.formatMessage({id: 'ECOMMERCE.VALIDATION.SEXREQUIRED'}))
      .oneOf(
        ['Male', 'Female', 'Not To Say'],
        intl.formatMessage({id: 'ECOMMERCE.VALIDATION.SEXREQUIRED'})
      ),
    email: yup
      .string()
      .email(intl.formatMessage({id: 'ECOMMERCE.VALIDATION.WRONGEMAILFORMAT'}))
      .max(50, intl.formatMessage({id: 'ECOMMERCE.VALIDATION.MAXIMUMSIMBOL'}))
      .required(intl.formatMessage({id: 'ECOMMERCE.VALIDATION.EMAILREQUIRED'})),
    addressLine1: yup
      .string()
      .required(intl.formatMessage({id: 'ECOMMERCE.VALIDATION.ADDRESSREQUIRED'})),
    suburb: yup.string().required(intl.formatMessage({id: 'ECOMMERCE.VALIDATION.SUBURBREQUIRED'})),
    state: yup.string().required(intl.formatMessage({id: 'ECOMMERCE.VALIDATION.STATEREQUIRED'})),
    postCode: yup
      .string()
      .required(intl.formatMessage({id: 'ECOMMERCE.VALIDATION.POSTCODEREQUIRED'})),
    isUploadFileFeatureEnabled: yup.boolean(),
    frontImageFile: yup.mixed().when('isUploadFileFeatureEnabled', {
      is: function (isUploadFileFeatureEnabled: any) {
        return isUploadFileFeatureEnabled !== undefined && isUploadFileFeatureEnabled
      },
      then: yup.mixed().when(['frontImageFileName'], {
        is: (value: any) => {
          return !value
        },
        then: yup.mixed().test({
          test: function (value) {
            if (value && value !== '') return true // Skip the validation if value not empty
            return this.createError({
              message: intl.formatMessage({id: 'ECOMMERCE.VALIDATION.FRONTIMAGEREQUIRED'}),
            })
          },
        }),
      }),
    }),
    // backImageFile: yup.mixed().when(['identity', 'isUploadFileFeatureEnabled'], {
    //   is: function (isUploadFileFeatureEnabled: any) {
    //     return isUploadFileFeatureEnabled !== undefined && isUploadFileFeatureEnabled
    //   },
    //   then: yup.mixed().when(['backImageFileName'], {
    //     is: (value: any) => {
    //       return !value
    //     },
    //     then: yup.mixed().test({
    //       test: function (value) {
    //         const {identity} = this.parent
    //         if (value && value !== '' && parseInt(identity) === 1) return true // Skip the validation if value not empty
    //         return this.createError({
    //           message: intl.formatMessage({id: 'ECOMMERCE.VALIDATION.FRONTIMAGEREQUIRED'}),
    //         })
    //       },
    //     }),
    //   }),
    // }),
    companyName: yup.mixed().when('clientType', {
      is: 2,
      then: yup
        .string()
        .required(intl.formatMessage({id: 'ECOMMERCE.VALIDATION.COMPANYNAMEREQUIRED'})),
      otherwise: yup.string().nullable(),
    }),
    abnacn: yup.mixed().when('clientType', {
      is: 2,
      then: yup.string().required(intl.formatMessage({id: 'ECOMMERCE.VALIDATION.ABNACNREQUIRED'})),
    }),
    dateOfBirth: yup.mixed().when('isClientDataFeatureEnabled', {
      is: false,
      then: yup.mixed().test({
        test: function (value) {
          if (!value)
            return this.createError({
              message: intl.formatMessage({id: 'ECOMMERCE.VALIDATION.DOBREQUIRED'}),
            })
          if (new Date(value) < new Date(1900, 0, 1))
            return this.createError({
              message: intl.formatMessage({id: 'ECOMMERCE.VALIDATION.DOBINVALID'}),
            })
          if (new Date(value) > new Date(2900, 0, 1))
            return this.createError({
              message: intl.formatMessage({id: 'ECOMMERCE.VALIDATION.DOBINVALID'}),
            })
          return true
        },
      }),
    }),
    clientType: yup.mixed().test({
      test: function (value) {
        if (value || value === 0) return true
        return this.createError({
          message: intl.formatMessage({id: 'ECOMMERCE.VALIDATION.CLIENTYPEREQUIRED'}),
        })
      },
    }),
    identityNumber: yup.string().when('isIdentityDataFeatureEnabled', {
      is: function (isIdentityDataFeatureEnabled: any) {
        return isIdentityDataFeatureEnabled === undefined || !isIdentityDataFeatureEnabled
      },
      then: yup
        .string()
        .required(intl.formatMessage({id: 'ECOMMERCE.VALIDATION.IDENTITYREQUIRED'})),
      otherwise: yup.string(), // Return a string schema for the "else" case.
    }),
    passportCountry: yup.mixed().when(['identity', 'isIdentityDataFeatureEnabled'], {
      is: (identity: any, isIdentityDataFeatureEnabled: any) => {
        return (
          identity && (isIdentityDataFeatureEnabled === undefined || !isIdentityDataFeatureEnabled)
        )
      },
      then: yup.mixed().test({
        test: function (value) {
          const {identity} = this.parent
          if (parseInt(identity) === 1 && !value) {
            return this.createError({
              message: intl.formatMessage({id: 'ECOMMERCE.VALIDATION.PASSPORTCOUNTRYREQUIRED'}),
            })
          }
          return true
        },
      }),
    }),
    issuedBy: yup.string().when('isIdentityDataFeatureEnabled', {
      is: function (isIdentityDataFeatureEnabled: any) {
        return isIdentityDataFeatureEnabled === undefined || !isIdentityDataFeatureEnabled
      },
      then: yup
        .string()
        .required(intl.formatMessage({id: 'ECOMMERCE.VALIDATION.ISSUEDBYREQUIRED'})),
      otherwise: yup.string(), // Return a string schema for the "else" case.
    }),
    issuedDate: yup.mixed().when('isIdentityDataFeatureEnabled', {
      is: function (isIdentityDataFeatureEnabled: any) {
        return isIdentityDataFeatureEnabled === undefined || !isIdentityDataFeatureEnabled
      },
      then: yup.mixed().test({
        test: function (value) {
          if (!value)
            return this.createError({
              message: intl.formatMessage({id: 'ECOMMERCE.VALIDATION.ISSUEDREQUIRED'}),
            })
          if (new Date(value) < new Date(1900, 0, 1))
            return this.createError({
              message: intl.formatMessage({id: 'ECOMMERCE.VALIDATION.ISSUEDATEINVALID'}),
            })
          if (new Date(value) > new Date(2900, 0, 1))
            return this.createError({
              message: intl.formatMessage({id: 'ECOMMERCE.VALIDATION.ISSUEDATEINVALID'}),
            })
          return true
        },
      }),
    }),
    expiryDate: yup.mixed().when('isIdentityDataFeatureEnabled', {
      is: function (isIdentityDataFeatureEnabled: any) {
        return isIdentityDataFeatureEnabled === undefined || !isIdentityDataFeatureEnabled
      },
      then: yup.mixed().test({
        test: function (value) {
          if (!value)
            return this.createError({
              message: intl.formatMessage({id: 'ECOMMERCE.VALIDATION.EXPIRYDATEREQUIRED'}),
            })
          return true
        },
      }),
    }),
    mobile: yup.mixed().test({
      test: function (value) {
        const digitPattern = /^[0-9]+$/
        if (!value)
          return this.createError({
            message: intl.formatMessage({id: 'ECOMMERCE.VALIDATION.MOBILEREQUIRED'}),
          })
        else if (value.length > 20)
          return this.createError({
            message: intl.formatMessage({id: 'ECOMMERCE.VALIDATION.MOBILEMUSTBE'}),
          })
        else if (!digitPattern.test(value))
          return this.createError({
            message: intl.formatMessage({id: 'ECOMMERCE.VALIDATION.MOBILENUMBERIC'}),
          })
        return true
      },
    }),
    country: yup.mixed().test({
      test: function (value) {
        if (!value)
          return this.createError({
            message: intl.formatMessage({id: 'ECOMMERCE.VALIDATION.COUNTRYREQUIRED'}),
          })
        return true
      },
    }),

    status: yup
      .number()
      .test(
        'is-not-zero',
        intl.formatMessage({id: 'ECOMMERCE.VALIDATION.STATUSREQUIRED'}),
        (value) => value !== 0
      )
      .required(intl.formatMessage({id: 'ECOMMERCE.VALIDATION.STATUSREQUIRED'})),
    businessDevelopmentManager: yup.mixed().when('isHideBDMDropdownFeatureEnabled', {
      is: function (isHideBDMDropdownFeatureEnabled: any) {
        return isHideBDMDropdownFeatureEnabled === undefined || !isHideBDMDropdownFeatureEnabled
      },
      then: yup.mixed().test({
        test: function (value) {
          if (!value)
            return this.createError({
              message: intl.formatMessage({id: 'ECOMMERCE.VALIDATION.BDMREQUIRED'}),
            })
          return true
        },
      }),
    }),
    debitAmount: yup.mixed().when('isDirectDebitFormFeatureEnabled', {
      is: function (isDirectDebitFormFeatureEnabled: any) {
        return isDirectDebitFormFeatureEnabled !== undefined && isDirectDebitFormFeatureEnabled
      },
      then: yup
        .number()
        .typeError(intl.formatMessage({id: 'ECOMMERCE.VALIDATION.DEBITAMOUNTINVALID'}))
        .test({
          name: 'debitAmount',
          test: function (value) {
            if (!value || value === 0) {
              return this.createError({
                message: intl.formatMessage({id: 'ECOMMERCE.VALIDATION.DEBITAMOUNTREQUIRED'}),
              })
            }
            return true
          },
        })
        .nullable(),
    }),
    paymentCycle: yup.mixed().when('isDirectDebitFormFeatureEnabled', {
      is: function (isDirectDebitFormFeatureEnabled: any) {
        return isDirectDebitFormFeatureEnabled !== undefined && isDirectDebitFormFeatureEnabled
      },
      then: yup
        .number()
        .typeError(intl.formatMessage({id: 'ECOMMERCE.VALIDATION.DEBITAMOUNTINVALID'}))
        .test({
          name: 'paymentCycle',
          test: function (value) {
            if (!value || value === 0) {
              return this.createError({
                message: intl.formatMessage({id: 'ECOMMERCE.VALIDATION.PAYMENTCYCLEREQUIRED'}),
              })
            }

            return true
          },
        })
        .nullable(),
    }),
    paymentCycleStartDate: yup.mixed().when(['isDirectDebitFormFeatureEnabled', 'paymentCycle'], {
      is: (isDirectDebitFormFeatureEnabled: any, paymentCycle: number) => {
        return isDirectDebitFormFeatureEnabled && paymentCycle > 0
      },
      then: yup
        .date()
        .typeError(intl.formatMessage({id: 'ECOMMERCE.VALIDATION.STARTDATEINVALID'}))
        .min(
          new Date(1900, 0, 1),
          intl.formatMessage({id: 'ECOMMERCE.VALIDATION.STARTDATEINVALID'})
        )
        .max(
          new Date(2900, 0, 1),
          intl.formatMessage({id: 'ECOMMERCE.VALIDATION.STARTDATEINVALID'})
        )
        .required(intl.formatMessage({id: 'ECOMMERCE.VALIDATION.STARTDATEREQUIRED'})),
    }),
    paymentCycleEndDate: yup.mixed().when(['isDirectDebitFormFeatureEnabled', 'paymentCycle'], {
      is: (isDirectDebitFormFeatureEnabled: any, paymentCycle: number) => {
        return isDirectDebitFormFeatureEnabled && paymentCycle > 1
      },
      then: yup
        .date()
        .typeError(intl.formatMessage({id: 'ECOMMERCE.VALIDATION.ENDDATEINVALID'}))
        .min(new Date(1900, 0, 1), intl.formatMessage({id: 'ECOMMERCE.VALIDATION.ENDDATEINVALID'}))
        .max(new Date(2900, 0, 1), intl.formatMessage({id: 'ECOMMERCE.VALIDATION.ENDDATEINVALID'}))
        .required(intl.formatMessage({id: 'ECOMMERCE.VALIDATION.ENDDATEREQUIRED'})),
    }),
    banks: yup
      .array()
      .of(bankSchema)
      .min(1, 'at least 1'),
  })
  
  return schema;
}

export default ClientFormValidationSchema
