import {
  ComboBox,
  ComboBoxFilterChangeEvent,
  ComboBoxPageChangeEvent,
} from '@progress/kendo-react-dropdowns'

import {FC, useCallback, useEffect, useRef, useState} from 'react'
import {Controller} from 'react-hook-form'
import {ErrorMessage} from '@hookform/error-message'

import {getBDMList} from '../../../api/ClientApi'
import {IClientsData} from '../../../models/client'
import {Item} from '../../../models/item'
import {transformedBdmDataList} from '../../../transformer/client-transform'
import {useFeatureFlags} from '../../../../../context/FeatureFlags'

interface IProps {
  clientData: IClientsData | undefined
  register: any
  control: any
  setValue: any
  intl: any
  errors: any
}

const textField = 'name'
const keyField = 'id'

const emptyItem: Item = {[textField]: 'loading ...', id: 0}
const emptyItemBdm: Item = {[textField]: 'loading ...', id: 0}

const pageSize = 50
const pageSizeBdm = 50

const loadingData: Item[] = []
while (loadingData.length < pageSize) {
  loadingData.push({...emptyItem})
}
const loadingDataBdm: Item[] = []
while (loadingDataBdm.length < pageSizeBdm) {
  loadingDataBdm.push({...emptyItemBdm})
}

const StatusData: FC<IProps> = ({
  clientData,
  register,
  control,
  setValue,
  intl,
  errors,
}) => {
  const {features} = useFeatureFlags()
  const dataCachingBdm = useRef<any>([])
  const requestStartedBdm = useRef(false)
  const pendingRequestBdm = useRef<any>()
  const skipRef = useRef(0)
  const skipRefBdm = useRef(0)
  const pendingRequest = useRef<any>()
  const requestStarted = useRef(false)

  const [dataBDM, setDataBDM] = useState<Item[]>([])
  const [filterBdm, setFilterBdm] = useState('')
  const [totalBdm, setTotalBdm] = useState(0)
  const [isHideBDMDropdownFeatureEnabled, setIsHideBDMDropdownFeatureEnabled] = useState(false)

  useEffect(() => {
    requestDataBDM(0, '')
    return () => {
      resetCachBdm()
    }
  }, [filterBdm])

  useEffect(() => {
    if (features.some((feature) => feature.name === 'HideBDMDropdownFeature')) {
      setIsHideBDMDropdownFeatureEnabled(true)
    }
  }, [features])

  useEffect(() => {
    setValue('isHideBDMDropdownFeatureEnabled', isHideBDMDropdownFeatureEnabled)
  }, [isHideBDMDropdownFeatureEnabled])

  const requestData = useCallback((skip: number, filter: string) => {
    if (requestStarted.current) {
      clearTimeout(pendingRequest.current)
      pendingRequest.current = setTimeout(() => {
        requestData(skip, filter)
      }, 50)
      return
    }
  }, [])

  const resetCachBdm = () => {
    dataCachingBdm.current.length = 0
  }

  const requestDataBDM = useCallback((skip: number, filter: string) => {
    if (requestStartedBdm.current) {
      clearTimeout(pendingRequestBdm.current)
      pendingRequestBdm.current = setTimeout(() => {
        requestDataBDM(skip, filter)
      }, 50)
      return
    }

    fetchDropdownDataBdm(pageSizeBdm, skip, filter)
  }, [])

  const getResourceBDMData = (value: any) => {
    return dataBDM.find((resource) => resource.name === value)
  }

  const onFilterChangeBdm = useCallback((event: ComboBoxFilterChangeEvent) => {
    const filter = event.filter.value

    resetCachBdm()
    requestDataBDM(0, filter)

    setDataBDM(loadingDataBdm)
    skipRefBdm.current = 0
    setFilterBdm(filterBdm)
  }, [])

  const fetchDropdownDataBdm = async (take: any, skip: any, search: any) => {
    const dataFromApi = await getBDMList(take, skip, search) // Replace 'getBankList()' with your API call
    const transFormBdmData = transformedBdmDataList(dataFromApi)
    transFormBdmData.forEach((element: any, index: any) => {
      dataCachingBdm.current[index + skip] = element
    })
    if (skip === skipRef.current) {
      setDataBDM(transFormBdmData)
      setTotalBdm(totalBdm)
    }
    requestStartedBdm.current = false
    setDataBDM(transFormBdmData)
  }

  const shouldRequestDataBdm = useCallback((skip) => {
    for (let i = 0; i < pageSizeBdm; i++) {
      if (!dataCachingBdm.current[skip + i]) {
        return true
      }
    }
    return false
  }, [])

  const getCachedDataBdm = useCallback((skip) => {
    const data: Array<any> = []
    for (let i = 0; i < pageSizeBdm; i++) {
      data.push(dataCachingBdm.current[i + skip] || {...emptyItemBdm})
    }
    return data
  }, [])

  const pageChangeBdm = useCallback(
    (event: ComboBoxPageChangeEvent) => {
      const newSkip = event.page.skip

      if (shouldRequestDataBdm(newSkip)) {
        requestDataBDM(newSkip, filterBdm)
      }

      const dataBdm = getCachedDataBdm(newSkip)

      setDataBDM(dataBdm)
      skipRefBdm.current = newSkip
    },
    [getCachedDataBdm, requestDataBDM, shouldRequestDataBdm, filterBdm]
  )

  // Based on the alternative code structure, hard-coded with several conditional renderings.
  return (
    <div className='row align-items-center mt-6 pe-2'>
      <div className='col-lg-6 col-md-6 col-12 mb-4'>
        <div className='row align-items-center mt-6 pe-2'>
          <div className='col-lg-12 col-md-12 col-12 mb-4'>
            <label className='form-label' htmlFor='status'>
              {intl.formatMessage({id: 'ECOMMERCE.FIELD.STATUS'})}{' '}
              <span className='text-danger'>*</span>
            </label>
            <select
              className={`${errors.status ? 'border-danger' : ''} form-select`}
              data-kt-select2='true'
              data-placeholder='Select option'
              data-allow-clear='true'
              defaultValue={'1'}
              {...register('status')}
            >
              <option value='1'>{intl.formatMessage({id: 'ECOMMERCE.COMMON.ACTIVE'})}</option>
              <option value='2'>{intl.formatMessage({id: 'ECOMMERCE.COMMON.INACTIVE'})}</option>
            </select>
            <ErrorMessage
              errors={errors}
              name='status'
              as={<span className='spanError text-danger' />}
            />
          </div>
          {!isHideBDMDropdownFeatureEnabled ? (
            <div className='col-lg-12 col-md-12 col-12 mb-4'>
              <label className='form-label' htmlFor='businessDevelopmentManager'>
                {intl.formatMessage({id: 'ECOMMERCE.FIELD.BUSINESSDEVELOPMENTMANAGER'})}{' '}
                <span className='text-danger'>*</span>
              </label>
              <Controller
                control={control}
                name='businessDevelopmentManager'
                render={({field: {value, onChange}}) => (
                  <ComboBox
                    data={dataBDM}
                    value={getResourceBDMData(value)}
                    dataItemKey={keyField}
                    textField={textField}
                    filterable={true}
                    allowCustom={true}
                    onFilterChange={onFilterChangeBdm}
                    virtual={{
                      pageSize: pageSizeBdm,
                      skip: skipRefBdm.current,
                      total: totalBdm,
                    }}
                    className={`${errors.businessDevelopmentManager ? ' border-danger' : ''}`}
                    onChange={(event) => {
                      const selectedItem = event.target.value

                      if (selectedItem) {
                        const value = selectedItem
                          ? event.target.value.name + '||' + event.target.value.id
                          : 'undefined'
                        if (typeof event.target.value === 'object') {
                          // Code block to handle the case when event.target.value is an object
                          onChange(event.target.value.text)
                        } else if (typeof event.target.value === 'string') {
                          // Code block to handle the case when event.target.value is a string
                          onChange(selectedItem)
                        } else {
                          // Code block for other data types (e.g., numbers, arrays, etc.)
                          onChange(value)
                        }
                      } else {
                        onChange('')
                      }
                    }}
                    onPageChange={pageChangeBdm}
                  />
                )}
                rules={{required: 'Resource is required'}}
              />
              <ErrorMessage
                errors={errors}
                name='businessDevelopmentManager'
                as={<span className='spanError text-danger' />}
              />
            </div>
          ) : null}
        </div>
      </div>
      <div className='col-lg-6 col-md-6 col-12 mb-4'>
        <div className='row align-items-center mt-6 pe-2'>
          <div className='col-lg-12 col-md-12 col-12 mb-4'>
            <label className='form-label' htmlFor='remarks'>
              {intl.formatMessage({id: 'ECOMMERCE.FIELD.REMARKS'})}
            </label>
            <textarea
              className='form-control form-control-solid mb-8'
              rows={6}
              {...register('remarks')}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default StatusData
