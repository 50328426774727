import {Grid, GridColumn as Column, GridCellProps} from '@progress/kendo-react-grid'

import React, {useState} from 'react'
import {useIntl} from 'react-intl'

import {IClientFile} from '../../../models/client-file'
import {KendoI18Provider} from '../../../../../../_metronic/i18n/kendoI18Provider'
import {IClientsData} from '../../../models/client'

interface IProps {
  clientFiles?: IClientFile[][]
  clientData?: IClientsData[]
}

const ClientFile: React.FC<IProps> = ({clientData, clientFiles}) => {
  const intl = useIntl()
  const [activeTabIndex, setActiveTabIndex] = useState(0)

  const imageFormatCell = (dataProps: GridCellProps) => (
    <td className='k-command-cell'>
      {dataProps.dataItem.presignedUrl && (
        <img
          src={dataProps.dataItem.presignedUrl}
          alt='me'
          width={130}
          style={{display: 'block', marginLeft: 'auto', marginRight: 'auto'}}
        />
      )}
    </td>
  )

  return (
    <React.Fragment>
      <KendoI18Provider>
        {clientData &&
          clientData.length > 0 &&
          clientData[0].clientType === 2 &&
          clientData.map((_, index) => (
            <button
              key={index}
              type='button'
              className={`${
                index === activeTabIndex ? 'btn-primary' : 'btn-secondary'
              } btn btn-sm mx-1 my-1`}
              title={`Client ${index + 1}`}
            >
              <span onClick={() => setActiveTabIndex(index)}>
                {(clientData[index] && clientData[index]['lastName']) || '<Last Name>'}{' '}
                {(clientData[index] && clientData[index]['firstName']) || '<First Name>'}
              </span>
            </button>
          ))}
        <Grid resizable={true} data={clientFiles && clientFiles[activeTabIndex]}>
          <Column title={intl.formatMessage({id: 'ECOMMERCE.FIELD.IDENTITY'})} field='cTypeDesc' />
          <Column
            title={intl.formatMessage({id: 'ECOMMERCE.FIELD.IMAGETYPE'})}
            field='cIndexDesc'
          />
          <Column
            title={intl.formatMessage({id: 'ECOMMERCE.FIELD.IMAGE'})}
            field='presignedUrl'
            cell={imageFormatCell}
          />
        </Grid>
      </KendoI18Provider>
    </React.Fragment>
  )
}

export default ClientFile
