import { Notification, NotificationGroup } from '@progress/kendo-react-notification'
import { Slide } from '@progress/kendo-react-animation'

import { FC, Fragment, useEffect, useContext, useState } from 'react'
import { Resolver, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { ErrorMessage } from '@hookform/error-message'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import { CustomAlert, IAlert } from '../../../../shared/components/CustomAlert'
import { CLIENT_DATA_FORM_DEFAULT } from '../../constant/client-default'
import { ClientContext } from '../../context/ClientContext'
import { IClientsData } from '../../models/client'
import { createClient, updateClient } from '../../api/ClientApi'
import {
  getUTCDate,
  blockNonNumericChars,
  getErrorMessage,
} from '../../../../shared/service/utils'
import { ClientType } from '../../constant/config-default'
import ClientFormValidationSchema from '../../validators/client-form'
import ClientFormFields from './ClientFormFields'
import { DeleteClientModal } from './DeleteClientModal'
import { useFeatureFlags } from '../../../../context/FeatureFlags'
import { Item } from '../../models/item'

interface IProps {
  clientData?: IClientsData[]
}

const loadingPanel = (
  <div className='position-fixed k-loading-mask'>
    <span className='k-loading-text'>Loading</span>
    <div className='k-loading-image'></div>
    <div className='k-loading-color'></div>
  </div>
)

const PocFields: Array<keyof IClientsData> = [
  'firstName',
  'lastName',
  'englishName',
  'sex',
  'occupation',
  'dateOfBirth',
  'identity',
  'passportCountry',
  'issuedBy',
  'issuedDate',
  'identityNumber',
  'expiryDate',
  'holderName',
  'frontImageFileName',
  'backImageFileName',
  'files',
  'frontImageFile',
  'backImageFile',
]

const excludeFields: Array<keyof IClientsData> = [
  'id',
  'unqId',
  'created',
  'bankName',
  'bsb1',
  'bsb2',
  'bankAccountNumber',
  'bankAccountName',
  'bankBranch',
  'bankDescription',
]

const keysToInclude: Array<keyof IClientsData> = [
  'debitAmount',
  'paymentCycle',
  'paymentCycleDayOfMonth',
  'paymentCycleDayOfWeek',
  'paymentCycleOccurrence',
  'paymentCycleDescription',
  'paymentCycleStartDate',
  'paymentCycleEndDate',
]

const ClientForm: FC<IProps> = ({ clientData }) => {
  const intl = useIntl()
  const history = useHistory()
  const { features } = useFeatureFlags()
  const { setContextToaster } = useContext(ClientContext)

  const [formNotification, setformNotification] = useState<IAlert | undefined>()
  const [isSaving, setIsSaving] = useState(false)
  const [companyDetails, setcompanyDetails] = useState(false)
  const [isAllowNoBank, setIsAllowNoBank] = useState(false)

  const [selectedClientType, setSelectedClientType] = useState<string>('')

  const [isClientTypeSelectionFeatureEnabled, setIsClientTypeSelectionFeatureEnabled] =
    useState(false)
  const [isMultiplePOCFeatureEnabled, setIsMultiplePOCFeatureEnabled] = useState(false)

  const [errorMessage, setErrorMessage] = useState({
    error: false,
    message: '',
  })
  const [hasError, setHasError] = useState<{
    tabIndexs: number[]
    status: boolean
    bankIndexes: { clientIndex: number; conflictingIndexes: number[]; wrongNameIndexes: number[] }[]
  }>({
    tabIndexs: [],
    status: false,
    bankIndexes: [],
  })
  const [activeTabIndex, setActiveTabIndex] = useState(0)
  const [showAddButton, setShowAddButton] = useState(true)
  const [clients, setClients] = useState<IClientsData[]>([])
  const [showDeleteModal, setShowDeleteModal] = useState({ isDelete: false, index: -1 })
  const [isIdentityDataFeatureEnabled, setIsIdentityDataFeatureEnabled] = useState(false)
  const [isDirectDebitFormFeatureEnabled, setIsDirectDebitFormFeatureEnabled] = useState(false)
  const [isUploadFileFeatureEnabled, setIsUploadFileFeatureEnabled] = useState<boolean>(false)
  const [isClientDataFeatureEnabled, setIsClientDataFeatureEnabled] = useState(false)
  const [bankListData, setBankListData] = useState<Item[]>([])

  const defaultValues =
    Array.isArray(clientData) && clientData.length > 0 ? clientData[activeTabIndex] : undefined
  const [isAllowedNoBankFeatureEnable, setIsAllowedNoBankFeatureEnable] = useState(false)
  const clientFormValidationSchema = ClientFormValidationSchema()
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setValue,
    getValues,
    trigger,
  } = useForm({
    defaultValues,
    reValidateMode: 'onSubmit',
    resolver: yupResolver(clientFormValidationSchema) as unknown as Resolver<IClientsData, any>,
  })

  const clientType = watch('clientType')

  useEffect(() => {
    if (errors && Object.keys(errors || {}).length !== 0) {
      checkErrors([])
    }
  }, [errors])

  useEffect(() => {
    if (clientType === ClientType.Company) {
      setcompanyDetails(true)
    }
    setSelectedClientType(clientType === ClientType.Company ? 'Company' : 'Individual')
  }, [clientType])

  useEffect(() => {
    // Initialize the clients / hook form inputs
    if (clientData) {
      let allClientsData: IClientsData[] = [...clientData]
      for (let index = 0; index < clientData.length; index++) {
        const currentClient = clientData[index]
        const files = currentClient?.files || []
        const updatedClient = {
          ...currentClient,
          clientType: currentClient.clientType || 1,
          companyName: currentClient.companyName || '',
          abnacn: currentClient.abnacn || '',
          mobile: currentClient.mobile || 0,
          email: currentClient.email || '',
          dateOfBirth: new Date(currentClient.dateOfBirth || ''),
          issuedDate: new Date(currentClient.issuedDate || ''),
          expiryDate: new Date(currentClient.expiryDate || ''),
          paymentCycleStartDate: new Date(currentClient.paymentCycleStartDate || ''),
          paymentCycleEndDate: new Date(currentClient.paymentCycleEndDate || ''),
          country: currentClient.country || 'AUS',
          passportCountry: currentClient.passportCountry || 'AUS',
          frontImageFileName: files.find(
            (f) => f.cType === currentClient.identity && f.cIndex === 1
          )?.name,
          backImageFileName: files.find((f) => f.cType === currentClient.identity && f.cIndex === 2)
            ?.name,
          isUploadFileFeatureEnabled:
            features.some((feature) => feature.name === 'UploadFileFeature') || false,
          isPlentyPayHideFeatureEnabled:
            features.some((feature) => feature.name === 'PlentyPayHideFeature') || false,
          isClientDataFeatureEnabled:
            features.some((feature) => feature.name === 'ClientDataFeature') || false,
          isIdentityDataFeatureEnabled:
            features.some((feature) => feature.name === 'IdentityDataFeature') || false,
          isHideBDMDropdownFeatureEnabled:
            features.some((feature) => feature.name === 'HideBDMDropdownFeature') || false,
          isDirectDebitFormFeatureEnabled:
            features.some((feature) => feature.name === 'DirectDebitFormFeature') || false,
          isClientTypeSelectionFeatureEnabled:
            features.some((feature) => feature.name === 'ClientTypeSelectionFeature') || false,
          isMultiplePOCFeatureEnabled:
            features.some((feature) => feature.name === 'MultiplePOCFeature') || false,
          isAllowedNoBankFeatureEnable:
            features.some((feature) => feature.name === 'AllowedNonBankFeatureEnable') || false
        }

        // Update the specific client within the array
        allClientsData[index] = {
          ...allClientsData[index],
          ...updatedClient,
        }
      }

      // Update the specific client data in the copied array
      setClients(allClientsData)
      reset(allClientsData[activeTabIndex])
    } else {
      setClients([...clients, CLIENT_DATA_FORM_DEFAULT])
      reset(CLIENT_DATA_FORM_DEFAULT)
    }
  }, [clientData, features, reset])

  // useEffect(() => {
  //   // Whenever you switch tabs, populate the input fields with the selected client's data.
  //   reset(clients[activeTabIndex])
  // }, [activeTabIndex, reset])

  useEffect(() => {
    if (hasError.status && hasError.tabIndexs.includes(activeTabIndex)) trigger(PocFields)
  }, [activeTabIndex, hasError])

  useEffect(() => {
    if (clients && clients.length > 2) setShowAddButton(false)
  }, [clients])

  useEffect(() => {
    // Manually set the flags for the current client form.
    if (features.some((feature) => feature.name === 'UploadFileFeature')) {
      setIsUploadFileFeatureEnabled(true)
      setValue('isUploadFileFeatureEnabled', true)
    }
    if (features.some((feature) => feature.name === 'ClientTypeSelectionFeature')) {
      setIsClientTypeSelectionFeatureEnabled(true)
      setValue('isClientTypeSelectionFeatureEnabled', true)
    }
    if (features.some((feature) => feature.name === 'MultiplePOCFeature')) {
      setIsMultiplePOCFeatureEnabled(true)
      setValue('isMultiplePOCFeatureEnabled', true)
    }
    if (features.some((feature) => feature.name === 'DirectDebitFormFeature')) {
      setIsDirectDebitFormFeatureEnabled(true)
      setValue('isDirectDebitFormFeatureEnabled', true)
    }
    if (features.some((feature) => feature.name === 'IdentityDataFeature')) {
      setIsIdentityDataFeatureEnabled(true)
      setValue('isIdentityDataFeatureEnabled', true)
    }
    if (features.some((feature) => feature.name === 'HideBDMDropdownFeature')) {
      setValue('isHideBDMDropdownFeatureEnabled', true)
    }
    if (features.some((feature) => feature.name === 'ClientDataFeature')) {
      setIsClientDataFeatureEnabled(true)
      setValue('isClientDataFeatureEnabled', true)
    }
    if (features.some((feature) => feature.name === 'AllowedNoBankFeatureEnable')) {
      setIsAllowedNoBankFeatureEnable(true)
    }
  }, [features, activeTabIndex])

  useEffect(() => {
    handleClientImageNames()
  }, [clients, activeTabIndex])

  useEffect(() => {
    // Update clients by input changes
    const subscription = watch((value) => {
      const fields = Object.keys(value)

      // Create a deep copy of clients to avoid mutation
      const updatedClientsData = [...clients]

      if (
        updatedClientsData.length > 0 &&
        !areObjectsEqual(updatedClientsData[activeTabIndex], value)
      ) {
        fields.forEach((fieldName) => {
          const _value = value[fieldName as keyof IClientsData]

          // Create a deep copy of the current client data to update
          const updatedClientData = {
            ...updatedClientsData[activeTabIndex],
            [fieldName]: _value,
          }

          // Update the specific client data in the copied array
          updatedClientsData[activeTabIndex] = updatedClientData
        })
        // Update the clients state outside of the loop
        setClients(updatedClientsData)
      }
    })

    return () => subscription.unsubscribe()
  }, [watch, activeTabIndex, clients]) // Include clients as a dependency

  const handleClientImageNames = () => {
    // When updating the tab index, please check the files and set the imageFileNames to bypass the frontImageFile validation.
    const activeClient = clients[activeTabIndex]
    const files = activeClient?.files || []

    if (files.length > 0) {
      setValue(
        'frontImageFileName',
        files.find((f) => f?.cType === activeClient.identity && f?.cIndex === 1)?.name
      )
      setValue(
        'backImageFileName',
        files.find((f) => f?.cType === activeClient.identity && f?.cIndex === 2)?.name
      )
    }
  }

  const areObjectsEqual = (obj1: IClientsData, obj2: any) => {
    const stringifiedObj1 = JSON.stringify(obj1)
    const stringifiedObj2 = JSON.stringify(obj2)
    return stringifiedObj1 === stringifiedObj2
  }

  const checkErrors = (
    errorIndexes: number[],
    errorBankDetails: { clientIndex: number; conflictingIndexes: number[] }[] | null = null
  ) => {
    let errorMessage = 'Validation errors have been detected.'
    if (errorIndexes.length > 0 && clients.length > 0) {
      const errorFirstNames = errorIndexes.map((index) => clients[index].firstName)
      errorMessage = `Validation errors have been detected on ${errorFirstNames.join(', ')}.`
    }
    if (
      errorIndexes.length > 0 &&
      clients.length > 0 &&
      errorBankDetails &&
      errorBankDetails.some((item) => item.conflictingIndexes && item.conflictingIndexes.length > 0)
    ) {
      errorMessage = `Duplicate bank accounts have been identified.`
    }

    setformNotification({
      message: errorMessage,
      header: `Error ${clientData === undefined ? 'Saving' : 'Updating'} Client`,
      type: 'danger',
    })
  }

  const onSubmit = async (values: IClientsData) => {
    setIsSaving(true)
    const tempValues = getValues()
    let pocValidation = false
    let bankValidation = false
    try {
      if (!clientData) {
        const updatedClients = handleCloneNonPocValues(values)
        pocValidation = await handlePocValidation(updatedClients)
        bankValidation = await handleBankValidation(updatedClients)
        if (pocValidation && bankValidation)
          await createOrUpdateClient(createClient, updatedClients)
      } else {
        const nonPocValues = handleCloneNonPocValues(values)
        const updatedClients = nonPocValues.map((c: IClientsData) => {
          // Check if paymentCycle is 0; if so, omit the specified keys
          if (c.paymentCycle === 0) {
            keysToInclude.forEach((key) => delete (c as any)[key])
          }
          return c
        })
        pocValidation = await handlePocValidation(updatedClients)
        bankValidation = await handleBankValidation(updatedClients)
        if (pocValidation && bankValidation)
          await createOrUpdateClient(updateClient, updatedClients)
      }

      if (pocValidation && bankValidation) {
        history.push('/client/list')
        setContextToaster({
          message: `${clientData === undefined ? 'Client Add' : 'Client Update'} successfully.`,
          header: `${clientData === undefined ? 'Client Add' : 'Client Update'}`,
          type: 'primary',
        })
      }
    } catch (error) {
      const errorMessage = getErrorMessage(error)
      setformNotification({
        message: errorMessage,
        header: `Error ${clientData === undefined ? 'Saving' : 'Updating'} Client`,
        type: 'danger',
      })
    }

    // Need to reset the last form values if the submission fails.
    reset(tempValues)
    setIsSaving(false)
  }

  const handleCloneNonPocValues = (values: IClientsData) => {
    const updatedClients = clients.map((client: IClientsData) => {
      // Clone the client object
      const updatedClient = { ...client }

      const excludeKeys = [
        ...excludeFields,
        ...PocFields, // Include PocFields in exclusion
      ]

      // Iterate through excludeFields and exclude those fields
      excludeKeys.forEach((field) => {
        delete values[field]
      })

      // Merge in the values from the parameter
      return {
        ...updatedClient,
        ...values,
        paymentCycleStartDateUtc: client.paymentCycleStartDate
          ? getUTCDate(client.paymentCycleStartDate)
          : null,
      }
    })

    return updatedClients
  }

  const createOrUpdateClient = async (apiFunction: any, payload: any[]) => {
    try {
      const formData = new FormData()

      for (let i = 0; i < payload.length; i++) {
        const item = payload[i]
        for (const key in item) {
          if (item.hasOwnProperty(key)) {
            if (key === 'banks') {
              for (let j = 0; j < item.banks.length; j++) {
                const bank = item.banks[j]
                for (const bankKey in bank) {
                  formData.append(`clients[${i}].banks[${j}].${bankKey}`, bank[bankKey])
                }
              }
            } else {
              if (key.indexOf('date') > -1 || key.indexOf('Date') > -1) {
                const dateObject = new Date(item[key])
                var dateString = dateObject.toDateString()
                var timeString =
                  ('0' + dateObject.getHours()).slice(-2) +
                  ':' +
                  ('0' + dateObject.getMinutes()).slice(-2) +
                  ':' +
                  ('0' + dateObject.getSeconds()).slice(-2)

                var formattedDate = dateString + ' ' + timeString

                formData.append(`clients[${i}].${key}`, formattedDate)
              } else {
                formData.append(`clients[${i}].${key}`, item[key] == null ? '' : item[key])
              }
            }
          }
        }
      }
      const [data, error] = await apiFunction(formData)

      if (data) {
        return data
      }

      if (error) {
        throw error
      }
    } catch (error) {
      throw error
    }
  }

  const onReset = () => {
    reset(CLIENT_DATA_FORM_DEFAULT)
  }

  const handleClientTypeChange = (event: any) => {
    const clientType =
      event.target.value === 'Individual' ? ClientType.Individual : ClientType.Company
    setValue('clientType', clientType)

    if (clientType === ClientType.Individual) {
      setcompanyDetails(false)
    }
    if (clientType === ClientType.Company) {
      setcompanyDetails(true)
    }
  }

  const handleTabClick = async (index: number) => {
    await trigger(PocFields)
    let currentFormData = getValues()
    // Update clients if the active tab has non POC vlaues
    const updatedClients = handleCloneNonPocValues(currentFormData)

    setClients([...updatedClients])
    setErrorMessage({ error: false, message: '' })
    setActiveTabIndex(index)
    reset(updatedClients[index])
  }

  const handleAddClient = async () => {
    await trigger(PocFields)
    const newIndex = clients.length

    let currentFormData = getValues()

    const resetFields = {
      firstName: '',
      lastName: '',
      englishName: '',
      sex: 'Male',
      occupation: '',
      dateOfBirth: null,
      identity: 1,
      passportCountry: 'AUS',
      issuedBy: '',
      issuedDate: null,
      identityNumber: '',
      expiryDate: null,
      holderName: '',
      frontImageFileName: null,
      backImageFileName: null,
      files: undefined,
      frontImageFile: null,
      backImageFile: null,
      unqId: undefined,
      country: 'AUS'
    }
    currentFormData = {
      ...currentFormData,
      ...resetFields,
    }

    clients[newIndex] = currentFormData

    if (newIndex < 3) {
      setClients([...clients])
      setActiveTabIndex(newIndex)
    }
    if (newIndex === 2) {
      setShowAddButton(false)
    }

    setErrorMessage({ error: false, message: '' })

    reset(currentFormData)
  }

  const handlePocValidation = async (updateClients: IClientsData[]) => {
    const errorIndexs = []

    for (let index = 0; index < updateClients.length; index++) {
      const formValues = updateClients[index]

      try {
        await clientFormValidationSchema.validate(formValues, {
          abortEarly: false,
        })
      } catch (validationError) {
        if (validationError instanceof yup.ValidationError) {
          errorIndexs.push(index)
        } else {
          // Handle other errors or general validation errors
          console.error('Validation failed for client at tab', activeTabIndex, ':', validationError)
        }
      }
    }

    if (errorIndexs.length > 0) {
      checkErrors(errorIndexs)
      setHasError({ ...hasError, tabIndexs: errorIndexs, status: true })
      return false
    }

    return true
  }

  const handleBankValidation = async (updateClients: IClientsData[]) => {
    const errorIndexs = []
    const errorDetails: {
      clientIndex: number
      conflictingIndexes: number[]
      wrongNameIndexes: number[]
    }[] = []

    for (let i = 0; i < updateClients.length; i++) {
      const banks = updateClients[i].banks

      const conflictingIndexes: number[] = []
      const wrongNameIndexes: number[] = []

      for (let j = 0; j < banks.length; j++) {
        const currentBank = banks[j]

        for (let k = j + 1; k < banks.length; k++) {
          const comparedBank = banks[k]

          // Check if all specified properties have the same values
          const isDuplicate1 =
            currentBank.bankName === comparedBank.bankName &&
            currentBank.bsb1 === comparedBank.bsb1 &&
            currentBank.bsb2 === comparedBank.bsb2 &&
            currentBank.bankAccountNumber === comparedBank.bankAccountNumber

          const isDuplicate2 =
            currentBank.bankName !== comparedBank.bankName &&
            currentBank.bsb1 === comparedBank.bsb1 &&
            currentBank.bsb2 === comparedBank.bsb2

          const isDuplicate3 =
            currentBank.bankName === comparedBank.bankName &&
            currentBank.bsb1 !== comparedBank.bsb1 &&
            currentBank.bsb2 !== comparedBank.bsb2 &&
            currentBank.bankAccountNumber === comparedBank.bankAccountNumber

          const isDuplicate4 =
            currentBank.bankName === comparedBank.bankName &&
            currentBank.bsb1 === comparedBank.bsb1 &&
            currentBank.bsb2 !== comparedBank.bsb2 &&
            currentBank.bankAccountNumber === comparedBank.bankAccountNumber

          const isDuplicate5 =
            currentBank.bankName === comparedBank.bankName &&
            currentBank.bsb1 === comparedBank.bsb1 &&
            currentBank.bsb2 !== comparedBank.bsb2 &&
            currentBank.bankAccountNumber === comparedBank.bankAccountNumber

          if (isDuplicate1 || isDuplicate2 || isDuplicate3 || isDuplicate4 || isDuplicate5) {
            conflictingIndexes.push(j, k)
          }
        }
        
        let matchingBank = bankListData.find((b) => b.name === currentBank.bankName)
        let allowedEmptyBank = currentBank.bankName == '' && isAllowedNoBankFeatureEnable
        const isWrongBankname = !matchingBank
        if (isWrongBankname && !allowedEmptyBank) {
          wrongNameIndexes.push(j)
        }
      }

      if (conflictingIndexes.length > 0 || wrongNameIndexes.length > 0) {
        errorDetails.push({ clientIndex: i, conflictingIndexes, wrongNameIndexes })
        // Handle duplicate banks as needed
        errorIndexs.push(i)
      }
    }

    if (errorDetails.length > 0) {
      checkErrors(errorIndexs, errorDetails)
      setHasError({ tabIndexs: errorIndexs, status: true, bankIndexes: errorDetails })
      return false
    }

    return true
  }

  const handleDeleteCallback = (errorMessage?: string) => {
    if (!errorMessage) handleRemoveClient(showDeleteModal.index)
    else {
      setShowDeleteModal({ ...showDeleteModal, isDelete: !showDeleteModal.isDelete })
      setformNotification({
        message: errorMessage,
        header: `Error Removing Client`,
        type: 'danger',
      })
    }
  }

  const handleRemoveClient = (index: number) => {
    const updatedClients = clients.filter((_, i) => i !== index)
    // Set the state with the updated clients array
    setClients(updatedClients)

    setShowDeleteModal({ isDelete: !showDeleteModal.isDelete, index })
    setShowAddButton(true)
    if (activeTabIndex > index) {
      setActiveTabIndex(index)
      // reset(updatedClients[index])
    }
  }

  return (
    <Fragment>
      {isSaving && loadingPanel}
      {formNotification && (
        <CustomAlert {...formNotification} closeToaster={() => setformNotification(undefined)} />
      )}
      <DeleteClientModal
        show={showDeleteModal.isDelete}
        handleClose={() => {
          setShowDeleteModal({ ...showDeleteModal, isDelete: !showDeleteModal.isDelete })
        }}
        clientData={clients[showDeleteModal.index]}
        deleteCallback={(errorMessage) => {
          handleDeleteCallback(errorMessage)
        }}
      />
      <form
        onSubmit={handleSubmit(onSubmit)}
        onReset={onReset}
        name='client'
        encType='multipart/form-data'
        noValidate
      >
        <div className='modal-body'>
          <div className='d-flex actions'>
            <button hidden type='reset' className='btn btn-primary'>
              {intl.formatMessage({ id: 'ECOMMERCE.ACTION.CLEAR' })}
            </button>
            <span className='me-auto'></span>
            <button
              type='button'
              className='btn btn-outline-primary col-auto me-4'
              onClick={() => {
                history.push('/client/list')
                setContextToaster({
                  message: ``,
                  header: ``,
                  type: 'primary',
                })
              }}
            >
              {intl.formatMessage({ id: 'ECOMMERCE.ACTION.CANCEL' })}
            </button>
            <button type='submit' className='btn btn-primary col-auto me-4'>
              {intl.formatMessage({ id: 'ECOMMERCE.ACTION.SAVE' })}
            </button>
          </div>
          <div className='row align-items-center mt-6 pe-2'>
            <h2 className='pb-2'>
              {intl.formatMessage({ id: 'CLIENT.FIELDWITH' })} <span className='text-danger'>*</span>{' '}
              {intl.formatMessage({ id: 'CLIENT.AREREQUIREDFIELDS' })}
            </h2>
          </div>
          <div className='row align-items-center mt-6 pe-2' hidden>
            <div className='col-lg-6 col-md-6 col-12 mb-4'>
              <label className='form-label' htmlFor='id'>
                User ID
              </label>
              <input
                type='text'
                className={`form-control${errors.id ? ' border-danger' : ''}`}
                disabled
                {...register('id')}
              />
            </div>
          </div>

          {!isClientTypeSelectionFeatureEnabled ? (
            <div className='row align-items-center mt-6 pe-2'>
              <label className='form-label pb-2' htmlFor='id'>
                {intl.formatMessage({ id: 'CLIENT.CLIENTTYPE' })}
                <span className='text-danger'>*</span>
              </label>
              <div className='checkbox-list mb-3 d-flex align-items-buttom'>
                <label className='checkbox me-5'>
                  <input
                    type='radio'
                    value='Individual'
                    style={{ marginRight: '5px' }}
                    id='1'
                    checked={selectedClientType === 'Individual'}
                    onChange={handleClientTypeChange}
                  />
                  <span>{intl.formatMessage({ id: 'ECOMMERCE.FIELD.INDIVIDUAL' })}</span>
                </label>
                <label className='checkbox  me-5'>
                  <input
                    type='radio'
                    value='Company'
                    style={{ marginRight: '5px' }}
                    checked={selectedClientType === 'Company'}
                    id='2'
                    onChange={handleClientTypeChange}
                  />
                  <span>{intl.formatMessage({ id: 'ECOMMERCE.FIELD.COMPANY' })}</span>
                </label>
              </div>
              <ErrorMessage
                errors={errors}
                name='clientType'
                as={<span className='spanError text-danger' />}
              />
            </div>
          ) : null}
          <div>
            {companyDetails && (
              <>
                <div className='row align-items-center mt-6 pe-2'>
                  <div className='col-lg-6 col-md-6 col-12 mb-4'>
                    <label className='form-label' htmlFor='companyName'>
                      {intl.formatMessage({ id: 'ECOMMERCE.FIELD.COMPANYNAME' })}
                      <span className='text-danger'>*</span>
                    </label>
                    <input
                      type='text'
                      className={`form-control${errors.companyName ? ' border-danger' : ''}`}
                      placeholder={
                        intl.formatMessage({ id: 'ECOMMERCE.FIELD.ENTER' }) +
                        ' ' +
                        intl.formatMessage({ id: 'ECOMMERCE.FIELD.COMPANYNAME' })
                      }
                      {...register('companyName')}
                    />
                    <ErrorMessage
                      errors={errors}
                      name='companyName'
                      as={<span className='spanError text-danger' />}
                    />
                  </div>
                  <div className='col-lg-6 col-md-6 col-12 mb-4'>
                    <label className='form-label' htmlFor='abnacn'>
                      {intl.formatMessage({ id: 'ECOMMERCE.FIELD.ABNACN' })}
                      <span className='text-danger'>*</span>
                    </label>
                    <input
                      type='number'
                      className={`form-control${errors.abnacn ? ' border-danger' : ''}`}
                      placeholder={
                        intl.formatMessage({ id: 'ECOMMERCE.FIELD.ENTER' }) +
                        ' ' +
                        intl.formatMessage({ id: 'ECOMMERCE.FIELD.ABNACN' })
                      }
                      {...register('abnacn')}
                      
                    />
                    <ErrorMessage
                      errors={errors}
                      name='abnacn'
                      as={<span className='spanError text-danger' />}
                    />
                  </div>
                </div>
                <div className='row align-items-center mt-6 pe-2'>
                  <div className='col-lg-6 col-md-6 col-12 mb-4'>
                    <label className='form-label' htmlFor='mobile'>
                      {intl.formatMessage({ id: 'ECOMMERCE.FIELD.MOBILENUMBER' })}
                    </label>
                    <span className='text-danger'>*</span>
                    <input
                      type='text'
                      className={`form-control${errors.mobile ? ' border-danger' : ''}`}
                      maxLength={14}
                      placeholder={
                        intl.formatMessage({ id: 'ECOMMERCE.FIELD.ENTER' }) +
                        ' ' +
                        intl.formatMessage({ id: 'ECOMMERCE.FIELD.MOBILENUMBER' })
                      }
                      {...register('mobile')}
                      onKeyPress={blockNonNumericChars}
                    />
                    <ErrorMessage
                      errors={errors}
                      name='mobile'
                      as={<span className='spanError text-danger' />}
                    />
                  </div>
                  <div className='col-lg-6 col-md-6 col-12 mb-4'>
                    <label className='form-label' htmlFor='email'>
                      {intl.formatMessage({ id: 'ECOMMERCE.FIELD.EMAIL' })}
                    </label>
                    <span className='text-danger'>*</span>
                    <input
                      type='text'
                      className={`form-control${errors.email ? ' border-danger' : ''}`}
                      placeholder={
                        intl.formatMessage({ id: 'ECOMMERCE.FIELD.ENTER' }) +
                        ' ' +
                        intl.formatMessage({ id: 'ECOMMERCE.FIELD.EMAIL' })
                      }
                      {...register('email')}
                    />
                    <ErrorMessage
                      errors={errors}
                      name='email'
                      as={<span className='spanError text-danger' />}
                    />
                  </div>
                </div>
                <br />
                <hr />

                {clients.map((_, index) => (
                  <button
                    key={index}
                    type='button'
                    className={`${index === activeTabIndex ? 'btn-primary' : 'btn-secondary'
                      } btn btn-sm mx-1 my-1`}
                    title={`Client ${index + 1}`}
                  >
                    {index !== activeTabIndex && (
                      <i
                        className='bi bi-trash2'
                        onClick={() =>
                          setShowDeleteModal({ isDelete: !showDeleteModal.isDelete, index })
                        }
                      ></i>
                    )}
                    <span onClick={() => handleTabClick(index)}>
                      {(clients[index] && clients[index]['lastName']) || '<Last Name>'}{' '}
                      {(clients[index] && clients[index]['firstName']) || '<First Name>'}
                    </span>
                  </button>
                ))}
                {!isMultiplePOCFeatureEnabled && showAddButton && (
                  <button
                    className='btn btn-sm btn-icon btn-secondary mx-1 my-1'
                    type='button'
                    title='Add'
                    onClick={handleAddClient}
                  >
                    <i className='bi bi-plus-lg'></i>
                  </button>
                )}
              </>
            )}
          </div>
          <ClientFormFields
            register={register}
            control={control}
            intl={intl}
            errors={errors}
            setValue={setValue}
            clientData={clients && clients[activeTabIndex]}
            watch={watch}
            companyDetails={companyDetails}
            getValues={getValues}
            isIdentityDataFeatureEnabled={isIdentityDataFeatureEnabled}
            isDirectDebitFormFeatureEnabled={isDirectDebitFormFeatureEnabled}
            isUploadFileFeatureEnabled={isUploadFileFeatureEnabled}
            isClientDataFeatureEnabled={isClientDataFeatureEnabled}
            duplicatedBankIndexes={hasError.bankIndexes}
            onSetBankListData={setBankListData}
            isAllowedNoBankFeatureEnable={isAllowedNoBankFeatureEnable}
          />
        </div>
      </form>
      <NotificationGroup
        style={{
          right: 0,
          bottom: 0,
          alignItems: 'flex-start',
          flexWrap: 'wrap-reverse',
        }}
      >
        <Slide direction={errorMessage.error ? 'up' : 'down'}>
          {errorMessage.error && (
            <Notification
              type={{ style: 'error', icon: true }}
              closable={true}
              onClose={() => setErrorMessage({ ...errorMessage, error: false, message: '' })}
            >
              <span>{errorMessage.message}</span>
            </Notification>
          )}
        </Slide>
      </NotificationGroup>
    </Fragment>
  )
}

export { ClientForm }
