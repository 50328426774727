import { ChangeEvent, FC, useEffect, useState } from 'react'
import { Controller } from 'react-hook-form'
import { DatePicker } from '@progress/kendo-react-dateinputs'
import { ErrorMessage } from '@hookform/error-message'
import moment from 'moment'

import { paymentCycleOptions } from '../../../client/constant/date'
import CustomCalendar from './CustomCalendar'
import { IClientsData } from '../../../client/models/client'
import { PaymentCycleType } from '../../../client/constant/config-default'

interface IProps {
  clientData: IClientsData | undefined
  register: any
  control: any
  setValue: any
  intl: any
  watch: any
  errors: any
}

const PaymentTerm: FC<IProps> = ({
  clientData,
  register,
  control,
  setValue,
  intl,
  watch,
  errors,
}) => {
  const [selectedPaymentCycleType, setSelectedPaymentCycleType] = useState<number>(0)
  const paymentCycleStartDate = watch('paymentCycleStartDate')
  const [isInitialLoad, setisInitialLoad] = useState<boolean>(true)

  useEffect(() => {
    // It first monitors PaymentCycleStartDate 3 times, so you should configure the initial load to establish the initial PaymentCycleEndDate.
    setTimeout(() => {
      setisInitialLoad(false)
    }, 500)
  }, [])

  useEffect(() => {
    if (clientData) setSelectedPaymentCycleType(clientData?.paymentCycle || 0)
  }, [clientData])

  useEffect(() => {
    // Update the end date / day of week based on the start date
    // selectedPaymentCycleType = 0, 1 doesn't have end date, so ignored
    if (
      !isInitialLoad &&
      paymentCycleStartDate &&
      selectedPaymentCycleType > PaymentCycleType.OneTime
    ) {
      setValue('paymentCycleEndDate', calcEndDate())
    }
  }, [paymentCycleStartDate, selectedPaymentCycleType])

  const handleChangePaymentCycle = (event: ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value
    setSelectedPaymentCycleType(parseInt(value))
    setValue('paymentCycle', value)
  }

  const calcEndDate = () => {
    // Calculate the end date (x days from the selected start date and selectedPaymentCycleType)
    const endOfWeekDate = new Date(paymentCycleStartDate)
    if (parseInt(selectedPaymentCycleType.toString()) !== PaymentCycleType.Monthly) {
      const endList = [7, 14]
      const caledEndDate = endOfWeekDate.getDate() + endList[selectedPaymentCycleType - 2]
      endOfWeekDate.setDate(caledEndDate)
    } else {
      endOfWeekDate.setMonth(endOfWeekDate.getMonth() + 1)
    }

    return endOfWeekDate
  }
  // Based on the alternative code structure, hard-coded with several conditional renderings.
  return (
    <div>
      <div className='row align-items-center mt-6 pe-2'>
        <div className='col-lg-6 col-md-6 col-12'>
          <label className='form-label' htmlFor='paymentTerms'>
            {intl.formatMessage({ id: 'ECOMMERCE.FIELD.PAYMENTTERMS' })}
          </label>
        </div>
      </div>
      <div className='row align-items-center pe-2'>
        <div className='col-lg-6 col-md-6 col-12 mt-4 mb-4'>
          <label className='form-label' htmlFor='paymentCycle'>
            {intl.formatMessage({ id: 'ECOMMERCE.FIELD.PAYMENTCYCLE' })}
            <span className='text-danger'>*</span>
          </label>
          <select
            className={`${errors.paymentCycle ? 'border-danger' : ''} form-select`}
            data-kt-select2='true'
            data-placeholder='Select option'
            data-allow-clear='true'
            {...register('paymentCycle')}
            disabled
          >
            {paymentCycleOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
          <ErrorMessage
            errors={errors}
            name='paymentCycle'
            as={<span className='spanError text-danger' />}
          />
        </div>
      </div>
      {selectedPaymentCycleType > 0 && (
        <div className='row align-items-center mt-6 pe-2'>
          <div className='col-lg-6 col-md-6 col-12 mb-4'>
            <label className='form-label' htmlFor='paymentCycleStartDate'>
              {intl.formatMessage({ id: 'ECOMMERCE.FIELD.PAYMENTCYCLE.STARTDATE' })}
              <span className='text-danger'>*</span>
            </label>
            <Controller
              control={control}
              name='paymentCycleStartDate'
              render={({ field: { value, name, onChange } }) => {
                var date = moment(value)
                return (
                  <DatePicker
                    format='dd/MM/yyyy'
                    name={name}
                    min={new Date()}
                    className={`${errors.paymentCycleStartDate ? ' border-danger' : ''}`}
                    value={date.isValid() ? new Date(value || null) : null}
                    onChange={onChange}
                    disabled
                  />
                )
              }}
            />
            <ErrorMessage
              errors={errors}
              name='paymentCycleStartDate'
              as={<span className='spanError text-danger' />}
            />
          </div>
          {selectedPaymentCycleType > 1 && (
            <div className='col-lg-6 col-md-6 col-12 mb-4'>
              <label className='form-label' htmlFor='paymentCycleEndDate'>
                {intl.formatMessage({ id: 'ECOMMERCE.FIELD.PAYMENTCYCLE.ENDDATE' })}
                <span className='text-danger'>*</span>
              </label>
              <Controller
                control={control}
                name='paymentCycleEndDate'
                render={({ field: { value, name, onChange } }) => {
                  var date = moment(value)

                  return (
                    <DatePicker
                      format='dd/MM/yyyy'
                      name={name}
                      className={`${errors.paymentCycleEndDate ? ' border-danger' : ''}`}
                      onChange={onChange}
                      value={date.isValid() ? new Date(value || '') : null}
                      disabled
                      calendar={(props) => (
                        <CustomCalendar
                          {...props}
                          selectedPaymentCycleType={selectedPaymentCycleType} // Pass the selectedPaymentCycleType to CustomCalendar
                          paymentCycleStartDate={paymentCycleStartDate} // Pass the selectedPaymentCycleType to CustomCalendar
                        />
                      )}
                    />
                  )
                }}
              />
              <ErrorMessage
                errors={errors}
                name='paymentCycleEndDate'
                as={<span className='spanError text-danger' />}
              />
            </div>
          )}
        </div>
      )}
      <div className='col-lg-6 col-md-6 col-12 my-6'>
        <label className='form-label' htmlFor='paymentCycleDescription'>
          {intl.formatMessage({ id: 'ECOMMERCE.FIELD.DESCRIPTION' })}
        </label>
        <input
          type='text'
          className={`form-control${errors.id ? ' border-danger' : ''}`}
          {...register('paymentCycleDescription')}
          disabled
        />
        <ErrorMessage
          errors={errors}
          name='paymentCycleDescription'
          as={<span className='spanError text-danger' />}
        />
      </div>
    </div>
  )
}

export default PaymentTerm
