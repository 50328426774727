import React from 'react'
import { Text, StyleSheet, View } from '@react-pdf/renderer'
import { config } from '../common/config'

const styles = StyleSheet.create({
  field: {
    flexDirection: "row",
    fontFamily: config.fontFamily,
    fontSize: config.normalFontSize + 1,
    width: '100%',
  },
  listTypeText: {
    color: config.grayColor,
    marginHorizontal: 8,
    fontSize: config.normalFontSize,
  },
  title: {
    fontWeight: 'bold',
    marginRight: 5
  },
  value: {
    color: config.grayColor,
    fontSize: config.normalFontSize,
    margin: 0
  }
})

const ListText = ({ children, listTypeText, boldLabel, style }: any) => (
  <View style={[styles.field, style]}>
    <Text style={styles.listTypeText}>{listTypeText}</Text>
    <Text style={styles.title}>
      {boldLabel}
      <Text style={styles.value}>
        {children || 'Not provided'}
      </Text>
    </Text>
  </View>
)

export default ListText
