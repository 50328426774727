/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { Registration } from './components/Registration'
import { ForgotPassword } from './components/ForgotPassword'
import { ResetPassword } from './components/ResetPassword'
import { Login } from './components/Login'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { Login2Fa } from './components/Login2Fa'
import { LoginPage } from './components/LoginPage'

export function AuthPage() {
  useEffect(() => {
    document.body.classList.add('bg-white')
    return () => {
      document.body.classList.remove('bg-white')
    }
  }, [])

  return (
    <div
      className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
      // style={{
      //   backgroundImage: `url(${toAbsoluteUrl('/media/illustrations/sketchy-1/14.png')})`,
      // }}
    >
      <div className="login login-2 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white">
        <div className="login-aside order-2 order-lg-1 d-flex flex-column-fluid flex-lg-row-auto bgi-size-cover bgi-no-repeat p-7 p-lg-10 justify-content-center">
          <div className="d-flex flex-column-auto flex-column">
            <div className='text-center'>
              <div style={{
                backgroundImage: `url('${toAbsoluteUrl('/media/logos/logo.png')}')`,
                height: 25,
                width: 50,
                backgroundRepeat: 'no-repeat',
                margin: '0 auto',
                backgroundSize: 'contain'
              }} >

              </div>
            </div>
          </div>
          {/* begin::Content */}
          <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
            {/* begin::Logo */}
            <a href='#' className='mb-12'>
              <img alt='Logo' src={toAbsoluteUrl('/media/logos/relayosample.jpg')} className='h-45px' />
            </a>
            {/* end::Logo */}
            {/* begin::Wrapper */}
            <div className='w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto'>
              <Switch>
                <Route path='/auth/login' component={LoginPage} />
                <Route path='/auth/registration' component={Registration} />
                <Route path='/auth/forgot-password' component={ForgotPassword} />
                <Route path='/auth/reset-password/:uuid' component={ResetPassword} />
                <Redirect from='/auth' exact={true} to='/auth/login' />
                <Redirect to='/auth/login' />
              </Switch>
            </div>
            {/* end::Wrapper */}
          </div>
          {/* end::Content */}
        </div>
        <div className="order-1 order-lg-2 flex-column-auto flex-lg-row-fluid d-flex flex-column p-7" style={{
        backgroundImage: `url(${toAbsoluteUrl('/media/illustrations/bg-4.jpg')})`,
        }}>
        <div className="d-flex flex-column-fluid flex-lg-center">
            <div className="d-flex flex-column justify-content-center">
                <h3 className="display-3 font-weight-bold my-7 text-white">Welcome to Relayoo</h3>
                {/* <p className="font-weight-bold font-size-lg text-white opacity-80">
                    The ultimate Bootstrap, Angular 8, React &amp; VueJS admin theme
                </p> */}
            </div>
        </div>
    </div>
      </div>

      {/* begin::Footer */}
      {/* <div className='d-flex flex-center flex-column-auto p-10'>
        <div className='d-flex align-items-center fw-bold fs-6'>
          <a href='#' className='text-muted text-hover-primary px-2'>
            About
          </a>

          <a href='#' className='text-muted text-hover-primary px-2'>
            Contact
          </a>

          <a href='#' className='text-muted text-hover-primary px-2'>
            Contact Us
          </a>
        </div>
      </div> */}
      {/* end::Footer */}
    </div>
  )
}
