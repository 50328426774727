import { FC, Fragment } from 'react'
import { useIntl } from 'react-intl'

import Wrapper from './components/Wrapper'
import { PageTitle } from '../../../_metronic/layout/core'
import { ContextProvider } from './context'

const Page: FC = () => {
  const intl = useIntl()

  return (
    <Fragment>
      <PageTitle>{intl.formatMessage({ id: 'MENU.MYPROFILE' })}</PageTitle>
      <ContextProvider>
        <Wrapper />
      </ContextProvider>
    </Fragment>
  )
}

export default Page
