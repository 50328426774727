import {PaymentCycle, Week} from '../models/client'

export const weekData: Week[] = [
  {text: 'Sunday', id: 'SUN'},
  {text: 'Monday', id: 'MON'},
  {text: 'Tuesday', id: 'TUE'},
  {text: 'Wednesday', id: 'WED'},
  {text: 'Thursday', id: 'THU'},
  {text: 'Friday', id: 'FRI'},
  {text: 'Saturday', id: 'SAT'},
]

export const paymentCycleOptions: PaymentCycle[] = [
  {value: '0', label: 'Select'},
  {value: '1', label: 'One Time'},
  {value: '2', label: 'Weekly'},
  {value: '3', label: 'Fortnite'},
  {value: '4', label: 'Monthly'},
]
