import {
  ComboBox,
  ComboBoxFilterChangeEvent,
  ComboBoxPageChangeEvent,
} from '@progress/kendo-react-dropdowns'

import { useCallback, useEffect, useState, useRef, FC } from 'react'
import { Controller, useFieldArray } from 'react-hook-form'

import { useFeatureFlags } from '../../../../context/FeatureFlags'
import { Item } from '../../../client/models/item'
import { getBankList } from '../../../client/api/ClientApi'
import { transformedBankDataList } from '../../../client/transformer/client-transform'
import { IClientBankData } from '../../../client/models/client'
import { blockNonNumericChars } from '../../../../shared/service/utils'

interface IProps {
  register: any
  control: any
  setValue: any
  intl: any
  errors: any
}

const BankDetailsSection: FC<IProps> = ({
  register,
  control,
  setValue,
  intl,
  errors,
}) => {
  const { features } = useFeatureFlags()

  const [bankListData, setBankListData] = useState<Item[]>([])
  const [bankFilter, setBankFilter] = useState('')
  const [bankTotal, setBankTotal] = useState(0)
  const { fields, append, remove } = useFieldArray({ name: 'banks', control })

  const textField = 'name'
  const bankPageSize = 50
  const bankSkipRef = useRef(0)
  const emptyBankItem: Item = { [textField]: 'loading ...', id: 0 }
  const requestStarted = useRef(false)
  const pendingRequest = useRef<any>()
  const dataCaching = useRef<any>([])
  const loadingData: Item[] = []
  while (loadingData.length < bankPageSize) {
    loadingData.push({ ...emptyBankItem })
  }
  const [isMultipleBankAccountsFeatureEnabled, setIsMultipleBankAccountsFeatureEnabled] =
    useState<boolean>(false)
  const [isAllowedNoBankFeatureEnable, setisAllowedNoBankFeatureEnable] =
    useState<boolean>(false)

  useEffect(() => {
    if (features.some((feature) => feature.name === 'MultipleBankAccountsFeature')) {
      setIsMultipleBankAccountsFeatureEnabled(true)
    }
    if (features.some((feature) => feature.name === 'AllowedNonBankFeatureEnable')) {
      setisAllowedNoBankFeatureEnable(true)
    }
  }, [features])

  useEffect(() => {
    requestData(0, bankFilter)
    return () => {
      resetCach()
    }
  }, [bankFilter])

  useEffect(() => {
    if (fields.length === 0) {
      addBankDetail()
    }
  }, [fields])

  const requestData = useCallback((skip: number, filter: string) => {
    if (requestStarted.current) {
      clearTimeout(pendingRequest.current)
      pendingRequest.current = setTimeout(() => {
        requestData(skip, filter)
      }, 50)
      return
    }

    fetchDropdownDataBank(bankPageSize, skip, filter)
  }, [])

  const onFilterChange = useCallback((event: ComboBoxFilterChangeEvent) => {
    const filter = event.filter.value
    resetCach()
    requestData(0, filter)
    setBankListData(loadingData)
    bankSkipRef.current = 0
    setBankFilter(filter)
  }, [])

  const shouldRequestData = useCallback((skip) => {
    for (let i = 0; i < bankPageSize; i++) {
      if (!dataCaching.current[skip + i]) {
        return true
      }
    }
    return false
  }, [])

  const getCachedData = useCallback((skip) => {
    const data: Array<any> = []
    for (let i = 0; i < bankPageSize; i++) {
      data.push(dataCaching.current[i + skip] || { ...emptyBankItem })
    }
    return data
  }, [])

  const pageChange = useCallback(
    (event: ComboBoxPageChangeEvent) => {
      if (event.page.skip) {
        const newSkip = event.page.skip || 0

        if (shouldRequestData(newSkip)) {
          requestData(newSkip, bankFilter)
        }

        const data = getCachedData(newSkip)

        setBankListData(data)
        bankSkipRef.current = newSkip
      }
    },
    [getCachedData, requestData, shouldRequestData, bankFilter]
  )

  const fetchDropdownDataBank = async (take: any, skip: any, search: any) => {
    const dataFromApi = await getBankList(take, skip, search) // Replace 'getBankList()' with your API call
    const transFormBankData = transformedBankDataList(dataFromApi)
    transFormBankData.forEach((element: any, index: any) => {
      dataCaching.current[index + skip] = element
    })

    if (skip === bankSkipRef.current) {
      setBankListData(transFormBankData)
      setBankTotal(bankTotal)
    }
    requestStarted.current = false
    setBankListData(transFormBankData)
  }

  const resetCach = () => {
    dataCaching.current.length = 0
  }

  const getResourceBankData = (value: any) => {
    return bankListData.find((resource) => resource.name === value)
  }

  const addBankDetail = () => {
    const newField: IClientBankData = {
      id: 0,
      bankName: '',
      bsb1: '',
      bsb2: '',
      bankAccountNumber: '',
      bankAccountName: '',
      bankBranch: '',
      bankDescription: '',
      isAllowedNoBankFeatureEnable: isAllowedNoBankFeatureEnable
    }
    append(newField)
  }

  return (
    <div>
      <input type='hidden' {...register('debitInformation')} value='1' />
      {fields.map((field, index) => (
        <div key={'bank_' + index}>
          <div className='row align-items-center mt-6'>
            <div className='col-lg-6 col-md-6 col-12 mb-4'>
              <label className='form-label' htmlFor='bankName'>
                {intl.formatMessage({ id: 'ECOMMERCE.FIELD.BANKNAME' })}
                <span className='text-danger'>*</span>
              </label>
              <input type='hidden' {...register(`banks[${index}].id`)} />

              <Controller
                control={control}
                name={`banks[${index}].bankName`}
                render={({ field: { value, onChange } }) => (
                  <ComboBox
                    data={bankListData}
                    value={getResourceBankData(value)}
                    dataItemKey={'id'}
                    textField={'name'}
                    filterable={true}
                    allowCustom={true}
                    onFilterChange={onFilterChange}
                    virtual={{
                      pageSize: bankPageSize,
                      skip: bankSkipRef.current,
                      total: bankTotal,
                    }}
                    className={`${(errors.banks && errors.banks[index] && errors.banks[index].bankName)
                      ? ' border-danger'
                      : ''
                      }`}
                    disabled
                    onChange={(event) => {
                      const selectedItem = event.target.value

                      if (selectedItem) {
                        const value = selectedItem
                          ? event.target.value.name + '||' + event.target.value.id
                          : 'undefined'
                        if (typeof event.target.value === 'object') {
                          // Code block to handle the case when event.target.value is an object
                          onChange(event.target.value.text)
                        } else if (typeof event.target.value === 'string') {
                          // Code block to handle the case when event.target.value is a string
                          onChange(selectedItem)
                        } else {
                          // Code block for other data types (e.g., numbers, arrays, etc.)
                          onChange(value)
                        }
                      } else {
                        onChange('')
                      }
                    }}
                    onPageChange={pageChange}
                  />
                )}
                rules={{ required: 'Resource is required' }}
              />
              {errors.banks && errors.banks[index] && errors.banks[index].bankName && (
                <span className='spanError text-danger'>
                  {errors.banks[index].bankName.message}
                </span>
              )}

            </div>
            <div className='col-lg-4 col-md-4 col-12 mb-4'>
              <div className='row align-items-center mt-6'>
                <label className='form-label' htmlFor='bsb1'>
                  {intl.formatMessage({ id: 'ECOMMERCE.FIELD.BSB' })}
                  <span className='text-danger'>*</span>
                </label>
                <div className='col-lg-3 col-md-3 col-12'>
                  <input
                    type='text'
                    className={`form-control ${(errors.banks && errors.banks[index] && errors.banks[index].bsb1)
                      ? ' border-danger'
                      : ''
                      }`}
                    maxLength={3}
                    disabled
                    {...register(`banks[${index}].bsb1`)}
                  />
                </div>
                <div className='col-lg-1 col-md-1 col-12'>
                  <label className='form-label' htmlFor='dash'>
                    -
                  </label>
                </div>
                <div className='col-lg-3 col-md-3 col-12'>
                  <input
                    type='text'
                    className={`form-control ${(errors.banks && errors.banks[index] && errors.banks[index].bsb2)
                      ? ' border-danger'
                      : ''
                      }`}
                    maxLength={3}
                    disabled
                    {...register(`banks[${index}].bsb2`)}
                  />
                </div>
                {errors.banks && errors.banks[index] && errors.banks[index].bsb1 && (
                  <span className='spanError text-danger'>{errors.banks[index].bsb1.message}</span>
                )}
                {errors.banks && errors.banks[index] && errors.banks[index].bsb2 && (
                  <span className='spanError text-danger'>{errors.banks[index].bsb2.message}</span>
                )}
              </div>
            </div>
          </div>
          <div className='row align-items-center mt-6'>
            <div className='col-lg-6 col-md-6 col-12 mb-4'>
              <label className='form-label' htmlFor='bankAccountNumber'>
                {intl.formatMessage({ id: 'ECOMMERCE.FIELD.BANKACCOUNTNUMBER' })}
                <span className='text-danger'>*</span>
              </label>
              <input
                type='text'
                inputMode='numeric' // This attribute helps specify the keyboard input mode
                pattern='[0-9]*' // This attribute enforces only numeric characters
                className={`form-control ${(errors.banks && errors.banks[index] && errors.banks[index].bankAccountNumber)
                  ? ' border-danger'
                  : ''
                  }`}
                placeholder={
                  intl.formatMessage({ id: 'ECOMMERCE.FIELD.ENTER' }) +
                  ' ' +
                  intl.formatMessage({ id: 'ECOMMERCE.FIELD.BANKACCOUNTNUMBER' })
                }
                disabled
                onKeyPress={blockNonNumericChars}
                {...register(`banks[${index}].bankAccountNumber`)}
              />
              {errors.banks && errors.banks[index] && errors.banks[index].bankAccountNumber && (
                <span className='spanError text-danger'>
                  {errors.banks[index].bankAccountNumber.message}
                </span>
              )}
            </div>
            <div className='col-lg-6 col-md-6 col-12 mb-4'>
              <label className='form-label' htmlFor='bankAccountName'>
                {intl.formatMessage({ id: 'ECOMMERCE.FIELD.BANKACCOUNTNAME' })}
                <span className='text-danger'>*</span>
              </label>
              <input
                type='text'
                className={`form-control ${(errors.banks && errors.banks[index] && errors.banks[index].bankAccountName)
                  ? ' border-danger'
                  : ''
                  }`}
                disabled
                placeholder={
                  intl.formatMessage({ id: 'ECOMMERCE.FIELD.ENTER' }) +
                  ' ' +
                  intl.formatMessage({ id: 'ECOMMERCE.FIELD.BANKACCOUNTNAME' })
                }
                {...register(`banks[${index}].bankAccountName`)}
              />
              {errors.banks && errors.banks[index] && errors.banks[index].bankAccountName && (
                <span className='spanError text-danger'>
                  {errors.banks[index].bankAccountName.message}
                </span>
              )}
            </div>
          </div>
          <div className='row align-items-center mt-6'>
            <div className='col-12 mb-4'>
              <label className='form-label' htmlFor='bankBranch'>
                {intl.formatMessage({ id: 'ECOMMERCE.FIELD.BANKBRANCH' })}
              </label>
              <input
                type='text'
                className='form-control'
                disabled
                placeholder={
                  intl.formatMessage({ id: 'ECOMMERCE.FIELD.ENTER' }) +
                  ' ' +
                  intl.formatMessage({ id: 'ECOMMERCE.FIELD.BANKBRANCH' })
                }
                {...register(`banks[${index}].bankBranch`)}
              />
            </div>
          </div>
          <div className='row align-items-center mt-6'>
            <div className='col-12 mb-4'>
              <label className='form-label' htmlFor='bankDescription'>
                {intl.formatMessage({ id: 'ECOMMERCE.FIELD.DESCRIPTION' })}
              </label>
              <input
                type='text'
                className='form-control'
                disabled
                placeholder={
                  intl.formatMessage({ id: 'ECOMMERCE.FIELD.ENTER' }) +
                  ' ' +
                  intl.formatMessage({ id: 'ECOMMERCE.FIELD.DESCRIPTION' })
                }
                {...register(`banks[${index}].bankDescription`)}
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default BankDetailsSection
