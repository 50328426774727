import { GridCellProps } from '@progress/kendo-react-grid'
import { AmountFormatter } from '../../../service/utils'

export const AmountFormatCell = (cellProps: any) => {
    const { dataItem, field } = cellProps.props
    const dataValue = dataItem ? (dataItem[field] ? dataItem[field] : '') : ''

    return (
        <td>
            {AmountFormatter(dataValue, cellProps.currency || 'AUD')}
        </td>
    )
}