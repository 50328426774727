import axios from 'axios'

export const FEATUREFLAG_URL = `${process.env.REACT_APP_API_URL}/FeatureFlag`

export const getFeatures = async () => {
  try {
    const {data} = await axios.get(`${FEATUREFLAG_URL}/features`)
    return data
  } catch (error) {
    return error
  }
}