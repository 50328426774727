import React, {useEffect, useState} from 'react'
import {useHistory, useLocation} from 'react-router-dom'
import {useIntl} from 'react-intl'

import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {ClientForm} from './components/partials/ClientForm'
import {IClientsData} from './models/client'
import {getClientById} from './api/ClientApi'

const loadingPanel = (
  <div className='position-fixed k-loading-mask'>
    <span className='k-loading-text'>Loading</span>
    <div className='k-loading-image'></div>
    <div className='k-loading-color'></div>
  </div>
)

const EditClientPage: React.FC = () => {
  const intl = useIntl()
  const history = useHistory()

  const search = useLocation().search
  const idParam: string | null = new URLSearchParams(search).get('id')

  const [selectedClient, setSelectedClient] = useState<IClientsData[] | undefined>(undefined)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const widgetsBreadCrumbs: Array<PageLink> = [
    {
      title: intl.formatMessage({id: 'MENU.CLIENTS'}),
      path: '/client',
      isSeparator: false,
      isActive: false,
    },
  ]

  useEffect(() => {
    if (idParam) {
      setIsLoading(true)
      getClientById(idParam)
        .then((result: any) => {
          setSelectedClient(result)
          setIsLoading(false)
        })
        .catch(() => {
          history.push('/client/list')
          setIsLoading(false)
        })
    }
  }, [idParam])

  return (
    <div className='card card-custom'>
      <div className='card-body'>
        <div className='container-fluid px-0 mb-10'>
          <div className='row justify-content-between align-items-center'>
            <PageTitle breadcrumbs={widgetsBreadCrumbs}>
              {intl.formatMessage({id: 'ACTION.EDITCLIENT'})}
            </PageTitle>
            {isLoading && loadingPanel}
            {selectedClient && <ClientForm clientData={selectedClient} />}
          </div>
        </div>
      </div>
    </div>
  )
}

export default EditClientPage
