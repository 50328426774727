import {FC, useState} from 'react'
import {useIntl} from 'react-intl'
import moment from 'moment'

import {IClientsData} from '../../../models/client'

interface IProps {
  clientData?: IClientsData[]
}

const ClientPoc: FC<IProps> = ({clientData}) => {
  const intl = useIntl()

  const [activeTabIndex, setActiveTabIndex] = useState(0)

  return (
    <div className='row'>
      <div className='col-12 my-8'>
        {clientData?.map((_, index) => (
          <button
            key={index}
            type='button'
            className={`${
              index === activeTabIndex ? 'btn-primary' : 'btn-secondary'
            } btn btn-sm mx-1 my-1`}
            title={`Client ${index + 1}`}
          >
            <span onClick={() => setActiveTabIndex(index)}>
              {(clientData[index] && clientData[index]['lastName']) || '<Last Name>'}{' '}
              {(clientData[index] && clientData[index]['firstName']) || '<First Name>'}
            </span>
          </button>
        ))}
      </div>
      <div className='col-12 col-md-6 col-lg-6'>
        <div className='form-group'>
          <label className='form-label' htmlFor='firstName'>
            {intl.formatMessage({id: 'ECOMMERCE.FIELD.FIRSTNAME'})}
          </label>
          <input
            type='text'
            className='form-control'
            value={clientData?.[activeTabIndex]?.firstName || ''}
            disabled
          />
        </div>
        <div className='form-group'>
          <label className='form-label' htmlFor='englishName'>
            {intl.formatMessage({id: 'ECOMMERCE.FIELD.ENGLISTNAME'})}
          </label>
          <input
            type='text'
            className='form-control'
            value={clientData?.[activeTabIndex]?.englishName || ''}
            disabled
          />
        </div>
        <div className='form-group'>
          <label className='form-label' htmlFor='occupation'>
            {intl.formatMessage({id: 'ECOMMERCE.FIELD.OCCUPATION'})}
          </label>
          <input
            type='text'
            className='form-control'
            value={clientData?.[activeTabIndex]?.occupation || ''}
            disabled
          />
        </div>
        <div className='form-group'>
          <label className='form-label' htmlFor='mobile'>
            {intl.formatMessage({id: 'ECOMMERCE.FIELD.MOBILENUMBER'})}
          </label>
          <input
            type='text'
            className='form-control'
            value={clientData?.[activeTabIndex]?.mobile || ''}
            disabled
          />
        </div>
      </div>
      <div className='col-12 col-md-6 col-lg-6'>
        <div className='form-group'>
          <label className='form-label' htmlFor='lastName'>
            {intl.formatMessage({id: 'ECOMMERCE.FIELD.LASTNAME'})}
          </label>
          <input
            type='text'
            className='form-control'
            value={clientData?.[activeTabIndex]?.lastName || ''}
            disabled
          />
        </div>
        <div className='form-group'>
          <label className='form-label' htmlFor='sex'>
            {intl.formatMessage({id: 'ECOMMERCE.FIELD.SEX'})}
          </label>
          <input
            type='text'
            className='form-control'
            value={clientData?.[activeTabIndex]?.sex || ''}
            disabled
          />
        </div>
        <div className='form-group'>
          <label className='form-label' htmlFor='dateOfBirth'>
            {intl.formatMessage({id: 'ECOMMERCE.FIELD.DATEOFBIRTH'})}
          </label>
          <input
            type='text'
            className='form-control'
            value={moment(clientData?.[activeTabIndex]?.dateOfBirth).format('DD/MM/YYYY') || ''}
            disabled
          />
        </div>
        <div className='form-group'>
          <label className='form-label' htmlFor='email'>
            {intl.formatMessage({id: 'ECOMMERCE.FIELD.EMAIL'})}
          </label>
          <input
            type='text'
            className='form-control'
            value={clientData?.[activeTabIndex]?.email || ''}
            disabled
          />
        </div>
      </div>
    </div>
  )
}

export default ClientPoc
