import React, { useRef } from 'react'
import { Modal, Button } from 'react-bootstrap-v5'
import { PDFDownloadLink, pdf } from '@react-pdf/renderer';

import { IClientsData, PaymentCycle } from '../../../app/modules/client/models/client'
import DirectDebitAgreementPDF from '../../../app/modules/client/components/partials/debit-pdf-form/DirectDebitAgreementPDF'

interface ModalProps {
  show: boolean
  formData: IClientsData
  paymentCycleOptions: PaymentCycle[]
  onAccept: (blobPdf: Blob, fileName: string) => void
  onDecline: () => void
}

const DirectDebitModal: React.FC<ModalProps> = ({
  show,
  formData,
  paymentCycleOptions,
  onAccept,
  onDecline,
}) => {
  const pdfDownloadLinkRef = useRef<HTMLButtonElement | null>(null);

  const formattedDate = () => {
    const day = String(formData.paymentCycleStartDate?.getDate()).padStart(2, '0')
    const month = new Intl.DateTimeFormat('en-US', { month: 'long' }).format(
      formData.paymentCycleStartDate ?? new Date()
    )
    const year = formData.paymentCycleStartDate?.getFullYear()

    const formattedDate = `${day}-${month}-${year}`
    return formattedDate
  }

  // Function to generate the file name
  const generateFileName = () => {
    const currentDate = new Date();
    const formattedDate = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1)
      .toString()
      .padStart(2, '0')}-${currentDate.getDate().toString().padStart(2, '0')}`;

    // Combine all parts of the file name
    const fileName = `Direct Debit Agreement_${formattedDate}_${formData.bankName}_${formData.lastName}_${formData.firstName}.pdf`;

    return fileName;
  };

  const getPdfBlob = async () => {
    const blobPdf = await pdf(<DirectDebitAgreementPDF formData={formData} />).toBlob();
    return blobPdf
  }

  const handleAccept = async () => {
    // Trigger the print button click when Accept is clicked
    if (pdfDownloadLinkRef.current) {
      pdfDownloadLinkRef.current.click();
      const blobPdf = await getPdfBlob()
      onAccept(blobPdf, generateFileName())
    }
  }

  return (
    <Modal show={show} onHide={onDecline} backdrop='static' keyboard={false} centered>
      <Modal.Header>
        <Modal.Title style={{ margin: '0 auto' }}>Direct Debit Agreement</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ maxHeight: '550px', overflowY: 'auto' }}>
        <p>
          'HOSANNA' TRIUMPHANT SHOUT CHILDREN PTY LTD (ABN: 36 618 922 580) uses Zai Australia Pty
          Ltd. to process Direct Debits on its behalf.
        </p>
        <p>
          This is your Direct Debit Request Service Agreement with Zai Australia Pty. Ltd., (User
          ID’s 342203 and 481561) & ABN 96637632645. It explains what your obligations are when
          undertaking a Direct Debit arrangement with us. It also details what our obligations are
          to you as your Direct Debit provider.
        </p>
        <p>
          Please keep this agreement for future reference. It forms part of the terms and conditions
          of your Direct Debit Request (DDR) and should be read in conjunction with your DDR
          authorisation.
        </p>
        <div>
          <h5 style={{ fontSize: 'larger' }}>Definitions</h5>
          <ul>
            <li>
              <strong>account</strong> means the account held at your financial institution from
              which we are authorised to arrange for funds to be debited.
            </li>
            <li>
              <strong>agreement</strong> means this Direct Debit Request Service Agreement between
              you and us.
            </li>
            <li>
              <strong>banking day</strong> means a day other than a Saturday or a Sunday or a public
              holiday listed throughout Australia.
            </li>
            <li>
              <strong>debit day</strong> means the day that payment by you to us is due.
            </li>
            <li>
              <strong>debit payment</strong> means a particular transaction where a debit is made.
            </li>
            <li>
              <strong>direct debit request</strong> means the Direct Debit Request between us and
              you.
            </li>
            <li>
              <strong>us</strong> or <strong>we</strong> means{' '}
              <strong>Zai Australia Pty. Ltd. trading as Zai</strong> (the Debit User) you have
              authorised by requesting a Direct Debit Request.
            </li>
            <li>
              <strong>you</strong> means the customer who has signed or authorised by other means
              the Direct Debit Request.
            </li>
            <li>
              <strong>your financial institution</strong> means the financial institution nominated
              by you on the DDR at which the account is maintained.
            </li>
          </ul>
        </div>

        <div>
          <h5 style={{ fontSize: 'larger' }}>Debiting your account</h5>
          <ol>
            <li>
              By signing a Direct Debit Request or by providing us with a valid instruction, you
              have authorised us to arrange for funds to be debited from your account. You should
              refer to the Direct Debit Request and this agreement for the terms of the arrangement
              between us and you.
            </li>
            <li>
              We will only arrange for funds to be debited from your account as authorised in the
              Direct Debit Request. <br></br>
              <strong>or</strong> <br></br>
              We will only arrange for funds to be debited from your account if we have sent to the
              address nominated by you in the Direct Debit Request, a billing advice which specifies
              the amount payable by you to us and when it is due.
            </li>
            <li>
              If the debit day falls on a day that is not a banking day, we may direct your
              financial institution to debit your account on the following banking day. If you are
              unsure about which day your account has or will be debited you should ask your
              financial institution.
            </li>
          </ol>
        </div>

        <div>
          <h5 style={{ fontSize: 'larger' }}>Amendments by us</h5>
          <p>
            We may vary any details of this agreement or a Direct Debit Request at any time by
            giving you at least fourteen (14) days written notice.
          </p>
        </div>

        <div>
          <h5 style={{ fontSize: 'larger' }}>Amendments by you</h5>
          <p>
            You may change*, stop or defer a debit payment, or terminate this agreement by providing
            us with at least fourteen (14 days) notification by writing to:
          </p>
          <p>
            <strong>
              Accounts Department
              <br />
              Zai
              <br />
              Level 18,
              <br />
              120 Spencer Street,
              <br />
              Melbourne VIC 3000
              <br />
              Australia
            </strong>
          </p>
          <p>
            or by emailing us at <a href='mailto:helpdesk@hellozai.com'>helpdesk@hellozai.com</a>{' '}
            during business hours; or arranging it through your own financial institution, which is
            required to act promptly on your instructions.
          </p>
          <p>
            *Note: in relation to the above reference to ‘change’, your financial institution may
            ‘change’ your debit payment only to the extent of advising us of your new account
            details.
          </p>
        </div>

        <div>
          <h5 style={{ fontSize: 'larger' }}>Your obligations</h5>
          <p>
            It is your responsibility to ensure that there are sufficient clear funds available in
            your account to allow a debit payment to be made in accordance with the Direct Debit
            Request.
          </p>
          <p>If there are insufficient clear funds in your account to meet a debit payment:</p>
          <p>
            (a) you may be charged a fee and/or interest by your financial institution;
            <br />
            (b) you may also incur fees or charges imposed or incurred by us; and
            <br />
            (c) you must arrange for the debit payment to be made by another method or arrange for
            sufficient clear funds to be in your account by an agreed time so that we can process
            the debit payment.
          </p>
          <p>
            You should check your account statement to verify that the amounts debited from your
            account are correct
          </p>
        </div>

        <div>
          <h5 style={{ fontSize: 'larger' }}>Dispute</h5>
          <ol>
            <li>
              If you believe that there has been an error in debiting your account, you should
              notify us directly at <a href='mailto:helpdesk@hellozai.com'>helpdesk@hellozai.com</a>{' '}
              and confirm that notice in writing with us as soon as possible so that we can resolve
              your query more quickly. Alternatively, you can take it up directly with your
              financial institution.
            </li>
            <li>
              If we conclude as a result of our investigations that your account has been
              incorrectly debited we will respond to your query by arranging for your financial
              institution to adjust your account (including interest and charges) accordingly. We
              will also notify you in writing of the amount by which your account has been adjusted.
            </li>
            <li>
              If we conclude as a result of our investigations that your account has not been
              incorrectly debited we will respond to your query by providing you with reasons and
              any evidence for this finding in writing.
            </li>
          </ol>
        </div>

        <div>
          <h5 style={{ fontSize: 'larger' }}>Accounts</h5>
          <p>You should check:</p>
          <ol>
            <li>
              with your financial institution whether direct debiting is available from your account
              as direct debiting is not available on all accounts offered by financial institutions.
            </li>
            <li>
              your account details which you have provided to us are correct by checking them
              against a recent account statement; and
            </li>
            <li>
              with your financial institution before completing the Direct Debit Request if you have
              any queries about how to complete the Direct Debit Request.
            </li>
          </ol>
        </div>

        <div>
          <h5 style={{ fontSize: 'larger' }}>Confidentiality</h5>
          <ol>
            <li>
              We will keep any information (including your account details) in your Direct Debit
              Request confidential. We will make reasonable efforts to keep any such information
              that we have about you secure and to ensure that any of our employees or agents who
              have access to information about you do not make any unauthorised use, modification,
              reproduction, or disclosure of that information.
            </li>
            <li>We will only disclose information that we have about you:</li>
            <ol type='a'>
              <li>to the extent specifically required by law;</li>
              <li>
                or for the purposes of this agreement (including disclosing information in
                connection with any query or claim).
              </li>
            </ol>
          </ol>
        </div>

        <div>
          <h5 style={{ fontSize: 'larger', fontWeight: 'bold' }}>Notice</h5>
          <p>
            If you wish to notify us in writing about anything relating to this agreement, you
            should write to
          </p>
          <p>
            <strong>
              Accounts Department
              <br />
              Zai
              <br />
              Level 18,
              <br />
              120 Spencer Street,
              <br />
              Melbourne VIC 3000
              <br />
              Australia
            </strong>
          </p>
          <p>
            <a href='mailto:helpdesk@hellozai.com'>helpdesk@hellozai.com</a>
          </p>
          <p>
            We will notify you by sending a notice in the ordinary post to the address you have
            given us in the Direct Debit Request.
          </p>
          <p>
            Any notice will be deemed to have been received on the third banking day after posting.
          </p>
        </div>

        <div>
          <br />
          <h5 style={{ fontSize: '17px' }}>
            Request and Authority to debit the account named below to pay Zai Australia Pty. Ltd.
            trading as Zai.
          </h5>
          <br />
          <p>Request and Authority to debit</p>
          <p>
            <strong>
              Your Surname or company name <span style={{ color: 'blue' }}>{formData.lastName}</span>
            </strong>
          </p>
          <br />
          <p>
            <strong>
              Your Given names or ABN/ARBN <span style={{ color: 'blue' }}>{formData.firstName}</span>
            </strong>
          </p>
          <br />
          <p>
            request and authorise{' '}
            <strong>Zai Australia Pty. Ltd. trading as Zai & User ID's 342203 and 481561</strong> to
            arrange, through its own financial institution, a debit to your nominated account any
            amount <strong>Zai Australia Pty. Ltd. trading as Zai</strong>, has deemed payable by
            you. You acknowledge that Zai Australia Pty. Ltd. trading as Zai has different user id’s
            depending on the financial institution it uses.
          </p>
          <p>
            This debit or charge will be made through the Bulk Electronic Clearing System (BECS)
            from your account held at the financial institution you have nominated below and will be
            subject to the terms and conditions of the Direct Debit Request Service Agreement.
          </p>
        </div>

        <div>
          <h5 style={{ fontSize: 'larger', fontWeight: 'bold' }}>Financial details</h5>
          <p>
            <strong>
              Financial institution name <span style={{ color: 'blue' }}>{formData.bankName}</span>
            </strong>
          </p>
          <br />
          <p>
            <strong>
              Address <span style={{ color: 'blue' }}>{formData.bankBranch}</span>
            </strong>
          </p>
          <br />
          <p>
            <strong>
              Name/s on account <span style={{ color: 'blue' }}>{formData.bankAccountName}</span>
            </strong>
          </p>
          <br />
          <p>
            <strong>
              BSB number (Must be 6 Digits){' '}
              <span style={{ color: 'blue' }}>{formData.bsb1 + '-' + formData.bsb2}</span>
            </strong>
          </p>
          <br />
          <p>
            <strong>
              Account number <span style={{ color: 'blue' }}>{formData.bankAccountNumber}</span>
            </strong>
          </p>
          <br />
          <p>
            <strong>
              Direct Debit Amount <span style={{ color: 'blue' }}>$ {formData.debitAmount?.toFixed(2)}</span>
            </strong>
          </p>
          <br />
          <p>
            <strong>
              Payment Cycle{' '}
              <span style={{ color: 'blue' }}>
                {
                  paymentCycleOptions?.find(
                    (option) => option.value === formData.paymentCycle?.toString()
                  )?.label
                }
              </span>
            </strong>
          </p>
          <br />
          <p>
            <strong>
              Start Date <span style={{ color: 'blue' }}>{formattedDate()}</span>
            </strong>
          </p>
          <br />
        </div>

        <div>
          <h5 style={{ fontSize: 'larger', fontWeight: 'bold' }}>Acknowledgment</h5>
          <p>
            By signing and/or providing us with a valid instruction in respect to your Direct Debit
            Request, you have understood and agreed to the terms and conditions governing the debit
            arrangements between you and Zai Australia Pty. Ltd. trading as Zai as set out in this
            Request and in your Direct Debit Request Service Agreement.
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer style={{ justifyContent: 'center' }}>
        <Button
          variant='secondary'
          style={{ marginRight: '10px', border: '1px solid black' }}
          onClick={handleAccept}
        >
          Accept
        </Button>
        <Button variant='secondary' style={{ border: '1px solid black' }} onClick={onDecline}>
          Decline
        </Button>
        {formData &&
          <PDFDownloadLink document={<DirectDebitAgreementPDF formData={formData} />} fileName={generateFileName()}>
            {({ blob, url, loading, error }) =>
              loading ? "Loading..." :
                <Button
                  ref={pdfDownloadLinkRef}
                  variant='secondary'
                  className='d-none'
                >
                  Print
                </Button>
            }
          </PDFDownloadLink>
        }
      </Modal.Footer>
    </Modal >
  )
}

export { DirectDebitModal }
