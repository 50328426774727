import {DatePicker} from '@progress/kendo-react-dateinputs'
import {DropDownList} from '@progress/kendo-react-dropdowns'

import {ChangeEvent, FC, useEffect, useRef, useState} from 'react'
import {Controller} from 'react-hook-form'
import {ErrorMessage} from '@hookform/error-message'

import {Country, IClientsData} from '../../../models/client'
import {countriesData} from '../../../constant/countries'

interface IProps {
  clientData: IClientsData | undefined
  register: any
  control: any
  setValue: any
  intl: any
  errors: any
  getValues: any
  watch: any
  isUploadFileFeatureEnabled: boolean
}

const IdentifyData: FC<IProps> = ({
  clientData,
  register,
  control,
  setValue,
  intl,
  errors,
  getValues,
  watch,
  isUploadFileFeatureEnabled,
}) => {
  const frontImageFileInputRef = useRef<HTMLInputElement | null>(null)
  const backImageFileInputRef = useRef<HTMLInputElement | null>(null)

  const [showBackImage, setShowBackImage] = useState<boolean>(false)
  const [frontImageFile, setFrontImageFile] = useState<any | null>(null)
  const [frontImageFileName, setFrontImageFileName] = useState<string | null>('')
  const [backImageFile, setBackImageFile] = useState<any | null>(null)
  const [backImageFileName, setBackImageFileName] = useState<string | null>('')

  const [previewFrontImage, setPreviewFrontImage] = useState<string>('')
  const [previewBackImage, setPreviewBackImage] = useState<string>('')
  const [previewSavedFrontImage, setPreviewSavedFrontImage] = useState<string>('')
  const [previewSavedBackImage, setPreviewSavedBackImage] = useState<string>('')
  const [minExpiryDate, setMinExpiryDate] = useState<Date>(new Date())
  const [maxIssueDate, setMaxIssueDate] = useState<Date>(new Date())

  useEffect(() => {
    const subscription = watch((data: any) => {
      initImages(data)
    })
    return () => subscription.unsubscribe()
  }, [watch])

  useEffect(() => {
    if (clientData) {
      const identify = clientData.identity.toString()
      setShowBackImage(parseInt(identify) !== 1)
    }
  }, [clientData])

  const initImages = (data: IClientsData) => {
    if (data['frontImageFile'] === null || data['frontImageFile'] === undefined) {
      setPreviewFrontImage('')
      setPreviewSavedFrontImage('')
      setFrontImageFile(null)
      setFrontImageFileName(null)
    } else {
      const file = data['frontImageFile']
      const objectUrl = URL.createObjectURL(file)
      setPreviewFrontImage(objectUrl)
      setPreviewSavedFrontImage(objectUrl)
    }

    if (data['backImageFile'] === null || data['backImageFile'] === undefined) {
      setPreviewBackImage('')
      setPreviewSavedBackImage('')
      setBackImageFile(null)
      setBackImageFileName(null)
    } else {
      const file = data['backImageFile']
      if (file) {
        const objectUrl = URL.createObjectURL(file)
        setPreviewBackImage(objectUrl)
        setPreviewSavedBackImage(objectUrl)
      }
    }

    // Initially Saved Image load
    if (
      !data.hasOwnProperty('frontImageFile') &&
      data['frontImageFileName'] &&
      data.files &&
      data.files.length > 0
    ) {
      data.files.forEach((file) => {
        if (file.cIndex === 1) {
          setPreviewSavedFrontImage(file.presignedUrl)
          setPreviewFrontImage(file.presignedUrl)
        } else {
          setPreviewSavedBackImage(file.presignedUrl)
          setPreviewBackImage(file.presignedUrl)
        }
      })
    }
  }

  const identityCardHandleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value
    setValue('identity', +value)

    if (clientData && +value === 1) {
      setValue('passportCountry', 'AUS')
    }

    const isShowBackImage = value !== '1'
    setShowBackImage(isShowBackImage)
  }

  const onSaveFrontImageFile = (e: any) => {
    setValue('frontImageFile', e.target.files[0])
    setFrontImageFile(e.target.files[0])
    setFrontImageFileName(e.target.files[0].name)

    const objectUrl = URL.createObjectURL(e.target.files[0])
    setPreviewFrontImage(objectUrl)
    setPreviewSavedFrontImage(objectUrl)
  }

  const uploadFrontImageFileHandleClick = () => {
    if (frontImageFileInputRef.current) {
      frontImageFileInputRef.current.click()
    }
  }

  const uploadBackImageFileHandleClick = () => {
    if (backImageFileInputRef.current) {
      backImageFileInputRef.current.click()
    }
  }

  const deleteFrontImageFileHandleClick = () => {
    if (frontImageFileInputRef.current) {
      frontImageFileInputRef.current.value = ''
    }

    const updatedFiles = clientData?.files?.find((f) => f?.cIndex !== 1)
    setValue('files', updatedFiles)
    setValue('frontImageFile', null)
    setValue('frontImageFileName', null)
    setFrontImageFile(null)
    setFrontImageFileName(null)
  }

  const deleteBackImageFileHandleClick = () => {
    if (backImageFileInputRef.current) {
      backImageFileInputRef.current.value = ''
    }

    const updatedFiles = clientData?.files?.find((f) => f?.cIndex !== 2)
    setValue('files', updatedFiles)
    setValue('backImageFile', null)
    setValue('backImageFileName', null)
    setBackImageFile(null)
    setBackImageFileName(null)
  }

  const onSaveBackImageFile = (e: any) => {
    setValue('backImageFile', e.target.files[0])
    setBackImageFile(e.target.files[0])
    setBackImageFileName(e.target.files[0].name)

    const objectUrl = URL.createObjectURL(e.target.files[0])
    setPreviewBackImage(objectUrl)
    setPreviewSavedBackImage(objectUrl)
  }

  // Based on the alternative code structure, hard-coded with several conditional renderings.
  return (
    <div>
      <div className='row align-items-center mt-6 pe-2'>
        <div className='col-lg-12 col-md-12 col-12 mb-4'>
          <label className='form-label' htmlFor='identity'>
            {intl.formatMessage({id: 'ECOMMERCE.FIELD.IDENTITY'})}
            <span className='text-danger'>*</span>
          </label>
          <select
            className={`${errors.identity ? 'border-danger' : ''} form-select`}
            data-kt-select2='true'
            data-placeholder='Select option'
            data-allow-clear='true'
            {...register('identity')}
            onChange={identityCardHandleChange}
          >
            <option value='1'>{intl.formatMessage({id: 'ECOMMERCE.FIELD.PASSPORT'})}</option>
            <option value='2'>{intl.formatMessage({id: 'ECOMMERCE.FIELD.IDENTITYCARD'})}</option>
            <option value='3'>{intl.formatMessage({id: 'ECOMMERCE.FIELD.LICENSE'})}</option>
            <option value='4'>{intl.formatMessage({id: 'ECOMMERCE.FIELD.MEDICARD'})}</option>
          </select>
          <ErrorMessage
            errors={errors}
            name='identity'
            as={<span className='spanError text-danger' />}
          />
        </div>
      </div>
      {+getValues('identity') === 1 && (
        <div className='row align-items-center mt-6 pe-2'>
          <div className='col-lg-4 col-md-4 col-12 mb-4'>
            <label className='form-label' htmlFor='passportCountry'>
              {intl.formatMessage({id: 'ECOMMERCE.FIELD.COUNTRY'})}
              <span className='text-danger'>*</span>
            </label>
            <Controller
              control={control}
              name='passportCountry'
              render={({field: {onChange, value}}) => {
                return (
                  <DropDownList
                    className={`${errors.passportCountry ? ' border-danger' : ''}`}
                    data={countriesData}
                    textField='text'
                    filterable={false}
                    onChange={(e) => {
                      onChange(e.value.id)
                    }}
                    value={value && countriesData.find((x) => x.id === value)}
                    style={{width: '100%'}}
                  />
                )
              }}
            />
            <ErrorMessage
              errors={errors}
              name='passportCountry'
              as={<span className='spanError text-danger' />}
            />
          </div>
        </div>
      )}
      <div className='row align-items-center mt-6 pe-2'>
        <div className='col-lg-6 col-md-6 col-12 mb-4'>
          <label className='form-label' htmlFor='issuedBy'>
            {intl.formatMessage({id: 'ECOMMERCE.FIELD.ISSUEDBY'})}
            <span className='text-danger'>*</span>
          </label>
          <input
            type='text'
            className={`form-control${errors.issuedBy ? ' border-danger' : ''}`}
            placeholder={
              intl.formatMessage({id: 'ECOMMERCE.FIELD.ENTER'}) +
              ' ' +
              intl.formatMessage({id: 'ECOMMERCE.FIELD.ISSUEDBY'})
            }
            {...register('issuedBy')}
          />
          <ErrorMessage
            errors={errors}
            name='issuedBy'
            as={<span className='spanError text-danger' />}
          />
        </div>
        <div className='col-lg-6 col-md-6 col-12 mb-4'>
          <label className='form-label' htmlFor='issuedDate'>
            {intl.formatMessage({id: 'ECOMMERCE.FIELD.ISSUEDDATE'})}
            <span className='text-danger'>*</span>
          </label>
          <Controller
            control={control}
            name='issuedDate'
            render={({field: {value, name, onChange}}) => {
              return (
                <DatePicker
                  format='dd/MM/yyyy'
                  name={name}
                  max={maxIssueDate}
                  validationMessage={intl.formatMessage({
                    id: 'ECOMMERCE.VALIDATION.PLEASEENTERVALIDDATE',
                  })}
                  className={`${errors.issuedDate ? ' border-danger' : ''}`}
                  value={value ? new Date(value || '') : null}
                  onChange={(e) => {
                    onChange(e)
                    const selectedDate = e.value
                    if (selectedDate !== null && selectedDate > minExpiryDate)
                      setMinExpiryDate(selectedDate)
                    else setMinExpiryDate(new Date())
                  }}
                />
              )
            }}
          />
          <ErrorMessage
            errors={errors}
            name='issuedDate'
            as={<span className='spanError text-danger' />}
          />
        </div>
      </div>
      <div className='row align-items-center mt-6 pe-2'>
        <div className='col-lg-6 col-md-6 col-12 mb-4'>
          <label className='form-label' htmlFor='identityNumber'>
            {intl.formatMessage({id: 'ECOMMERCE.FIELD.IDENTITYNUMBER'})}
            <span className='text-danger'>*</span>
          </label>
          <input
            type='text'
            className={`form-control${errors.identityNumber ? ' border-danger' : ''}`}
            placeholder={
              intl.formatMessage({id: 'ECOMMERCE.FIELD.ENTER'}) +
              ' ' +
              intl.formatMessage({id: 'ECOMMERCE.FIELD.IDENTITYNUMBER'})
            }
            {...register('identityNumber')}
          />
          <ErrorMessage
            errors={errors}
            name='identityNumber'
            as={<span className='spanError text-danger' />}
          />
        </div>
        <div className='col-lg-6 col-md-6 col-12 mb-4'>
          <label className='form-label' htmlFor='expiryDate'>
            {intl.formatMessage({id: 'ECOMMERCE.FIELD.EXPIRYDATE'})}
            <span className='text-danger'>*</span>
          </label>
          <Controller
            control={control}
            name='expiryDate'
            render={({field: {value, name, onChange}}) => {
              return (
                <DatePicker
                  className={`${errors.expiryDate ? ' border-danger' : ''}`}
                  format='dd/MM/yyyy'
                  name={name}
                  min={minExpiryDate}
                  onChange={onChange}
                  value={value ? new Date(value || '') : null}
                  validationMessage={intl.formatMessage({
                    id: 'ECOMMERCE.VALIDATION.PLEASEENTERVALIDDATE',
                  })}
                />
              )
            }}
          />
          <ErrorMessage
            errors={errors}
            name='expiryDate'
            as={<span className='spanError text-danger' />}
          />
        </div>
      </div>
      <div className='row align-items-center mt-6 pe-2'>
        <div className='col-lg-6 col-md-6 col-12 mb-4'>
          <label className='form-label' htmlFor='holderName'>
            {intl.formatMessage({id: 'ECOMMERCE.FIELD.HOLDERNAME'})}
          </label>
          <input
            type='text'
            className={`form-control${errors.holderName ? ' border-danger' : ''}`}
            placeholder={
              intl.formatMessage({id: 'ECOMMERCE.FIELD.ENTER'}) +
              ' ' +
              intl.formatMessage({id: 'ECOMMERCE.FIELD.HOLDERNAME'})
            }
            {...register('holderName')}
          />
        </div>

        {isUploadFileFeatureEnabled && (
          <div className='col-lg-6 col-md-6 col-12 mb-4'>
            <label className='form-label' htmlFor='frontImage'>
              {intl.formatMessage({id: 'ECOMMERCE.FIELD.FRONTIMAGE'})}
            </label>
            <span className='text-danger'>*</span>
            {previewSavedFrontImage !== '' ? (
              <div>
                <img src={previewSavedFrontImage} height='80' />
                <input
                  type='button'
                  value={intl.formatMessage({id: 'ECOMMERCE.FIELD.DELETEIMAGE'})}
                  style={{display: 'block'}}
                  onClick={() => {
                    setPreviewSavedFrontImage('')
                    setPreviewFrontImage('')
                    deleteFrontImageFileHandleClick()
                  }}
                />
              </div>
            ) : (
              <>
                <input
                  type='file'
                  accept='.gif,.jpg,.png'
                  className={`${errors.identityNumber ? ' border-danger' : ''}`}
                  ref={frontImageFileInputRef}
                  style={{opacity: '0', width: '20px'}}
                  onChange={onSaveFrontImageFile}
                />
                <ErrorMessage
                  errors={errors}
                  name='frontImageFile'
                  as={<span className='spanError text-danger' />}
                />
                {!frontImageFile && (
                  <input
                    type='button'
                    value={intl.formatMessage({id: 'ECOMMERCE.FIELD.UPLOADIMAGE'})}
                    style={{display: 'block'}}
                    onClick={uploadFrontImageFileHandleClick}
                    className={`${errors.frontImageFile ? ' border-danger' : ''}`}
                  />
                )}
                {previewFrontImage && (
                  <div>
                    <img src={previewFrontImage} height='80' /> {frontImageFileName}
                    <input
                      type='button'
                      value={intl.formatMessage({id: 'ECOMMERCE.FIELD.DELETEIMAGE'})}
                      style={{display: 'block'}}
                      onClick={deleteFrontImageFileHandleClick}
                    />
                  </div>
                )}
              </>
            )}

            {showBackImage && previewSavedBackImage !== '' ? (
              <div>
                <div className='form-label mt-5'>
                  {intl.formatMessage({id: 'ECOMMERCE.FIELD.BACKIMAGE'})}
                </div>
                <img src={previewSavedBackImage} height='80' />
                <input
                  type='button'
                  value={intl.formatMessage({id: 'ECOMMERCE.FIELD.DELETEIMAGE'})}
                  style={{display: 'block'}}
                  onClick={() => {
                    if (previewSavedFrontImage !== '') {
                      setShowBackImage(true)
                    }

                    setPreviewSavedBackImage('')
                    deleteBackImageFileHandleClick()
                  }}
                />
              </div>
            ) : (
              <>
                {showBackImage && (
                  <div>
                    <label className='form-label mt-5' htmlFor='backImage'>
                      {intl.formatMessage({id: 'ECOMMERCE.FIELD.BACKIMAGE'})}
                    </label>
                    <input
                      type='file'
                      accept='.gif,.jpg,.png'
                      ref={backImageFileInputRef}
                      style={{display: 'none'}}
                      onChange={onSaveBackImageFile}
                    />
                    {!backImageFile && (
                      <input
                        type='button'
                        value={intl.formatMessage({id: 'ECOMMERCE.FIELD.UPLOADIMAGE'})}
                        style={{display: 'block'}}
                        onClick={uploadBackImageFileHandleClick}
                      />
                    )}

                    {backImageFile && (
                      <div>
                        <img src={previewBackImage} height='80' /> {backImageFileName}
                        <input
                          type='button'
                          value={intl.formatMessage({id: 'ECOMMERCE.FIELD.DELETEIMAGE'})}
                          style={{display: 'block'}}
                          onClick={deleteBackImageFileHandleClick}
                        />
                      </div>
                    )}
                  </div>
                )}
              </>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default IdentifyData
