import {SortDescriptor} from '@progress/kendo-data-query'
import axios from 'axios'

import {IClientsData} from '../models/client'

export const CLIENT_URL = `${process.env.REACT_APP_API_URL}/Client`
export const BANK_URL = `${process.env.REACT_APP_API_URL}/Bank`
export const BDM_URL = `${process.env.REACT_APP_API_URL}/BDM`
export const TRANSACTION_URL = `${process.env.REACT_APP_API_URL}/transaction`
export const CLIENTVIRTUALACCOUNT_URL = `${process.env.REACT_APP_API_URL}/clientvirtualaccount`
export const PAYIN_URL = `${process.env.REACT_APP_API_URL}/payin`

export const getClientListWithPagination = async (
  skip: number,
  take: number,
  pageSort: SortDescriptor,
  search?: string,
  clientType?: number
) => {
  try {
    const response = await axios.post(`${CLIENT_URL}/get`, {
      skip,
      take,
      search,
      sort: `${pageSort.field} ${pageSort.dir}`,
      clientType: clientType,
    })

    return response?.data ?? []
  } catch (err) {
    return []
  }
}

export const createClient = async (formData: any) => {
  try {
    const {data} = await axios.post(`${CLIENT_URL}/create`, formData)
    return [data, null]
  } catch (error) {
    return [null, error]
  }
}

export const updateClient = async (formData: any[]) => {
  try {
    const {data} = await axios.put(`${CLIENT_URL}/update`, formData)
    return [data, null]
  } catch (error) {
    return [null, error]
  }
}

export const deleteClient = async (unqId: string) => {
  try {
    const {data} = await axios.delete(`${CLIENT_URL}?unqId=${unqId}`)
    return [data, null]
  } catch (error) {
    return [null, error]
  }
}

export const directDebitCreateClient = async (form: any) => {
  try {
    const {data} = await axios.post(`${CLIENT_URL}/directdebit`, form)
    return [data, null]
  } catch (error) {
    return [null, error]
  }
}

export const getClientProfileById = async (companyId: string) => {
  try {
    const {data} = await axios.get(`${CLIENT_URL}/getclientprofilebyid?companyId=${companyId}`)
    return [data, null]
  } catch (error) {
    return [null, error]
  }
}

export const getBankList = async (take: any, skip: any, search: any) => {
  try {
    const params = `take=${take}&skip=${skip}&search=${search}`
    const {data} = await axios.get(`${BANK_URL}/get?${params}`)
    return data
  } catch (error) {
    return [null, error]
  }
}

export const getBDMList = async (take: any, skip: any, search: any) => {
  try {
    const params = `take=${take}&skip=${skip}&search=${search}`
    const {data} = await axios.get(`${BDM_URL}/get?${params}`)
    return data
  } catch (error) {
    return [null, error]
  }
}

export const getClientTransactions = async (clientId: string) => {
  try {
    return await axios.get(`${TRANSACTION_URL}/get`, {
      params: {
        skip: 0,
        take: 99999,
        sort: `id desc`,
        clientId,
      },
    })
  } catch (err) {
    return err
  }
}

export const getClientById = async (id: string) => {
  try {
    const {data} = await axios.get(`${CLIENT_URL}/getbyid?id=${id}`)
    return data
  } catch (error) {
    return error
  }
}

export const createVirtualAccount = async (id: number) => {
  try {
    var result = await axios.post(`${CLIENTVIRTUALACCOUNT_URL}`, {
      clientId: id
    })
    return result
  } catch (error) {
    return error
  }
}

export const getPayIdsByClientPagination = async (id: number, skip: number, take: number) => {
  try {
    const {data} = await axios.get(`${PAYIN_URL}/pagination`, {
      params: {
        clientId: id,
        skip,
        take
      }
    })
    return data
  } catch (error) {
    return error
  }
}
