import React, {useMemo, useState} from 'react'
import {useAsyncFn} from 'react-use'
import {IAlert} from '../../../shared/components/CustomAlert'
import {getMasterUser} from '../../orders/api/ItemApi'
import {AVALIABLECLIENT_DEFAULT} from '../../orders/constant/item-default'
import {IAvailableClient} from '../../orders/models/available-client'
import {getAllClientsWithType} from '../api'
import {TOASTER_DEFAULT, TRANSACTION_FORM_DEFAULT} from '../constants/default'
import {IClientOption, ITransaction} from '../models/transaction-model'
import {transformDataClient} from '../transformers/transaction-transformer'

interface IProps {
  masterUser: IAvailableClient
  selectedData: ITransaction
  setSelectedData: (data: ITransaction) => void
  contextToaster: IAlert
  setContextToaster: (alert: IAlert) => void
  clientOptions: IClientOption[]
  getAllClientsWithTypeAsync: (...args: any[]) => Promise<void>
  getMasterUserAsync: () => Promise<void>
  fetchingClient: boolean
  fetchingMasteUser: boolean
}

export const TransactionContext = React.createContext<IProps>({
  masterUser: AVALIABLECLIENT_DEFAULT,
  selectedData: TRANSACTION_FORM_DEFAULT,
  setSelectedData: (data: ITransaction) => {},
  contextToaster: TOASTER_DEFAULT,
  setContextToaster: (data: IAlert) => {},
  clientOptions: [],
  getAllClientsWithTypeAsync: async (...args: any[]) => {},
  getMasterUserAsync: async () => {},
  fetchingClient: false,
  fetchingMasteUser: false,
})

export const TransactionContextProvider: React.FC = ({children}) => {
  const [selectedData, setSelectedData] = useState<ITransaction>(TRANSACTION_FORM_DEFAULT)
  const [contextToaster, setContextToaster] = useState<IAlert>(TOASTER_DEFAULT)
  const [clientOptions, setClientOptions] = useState<IClientOption[]>([])
  const [masterUser, setMasterUser] = useState<IAvailableClient>(AVALIABLECLIENT_DEFAULT)

  const [{loading: fetchingClient}, getAllClientsWithTypeAsync] = useAsyncFn(
    async (...args) => {
      const [keyword, type, isMasterWalletDefault] = args
      const response = await getAllClientsWithType(keyword, isMasterWalletDefault)
      const clients = transformDataClient(response.items)
      setClientOptions(clients as any)
    },
    [setClientOptions]
  )

  const [{loading: fetchingMasteUser}, getMasterUserAsync] = useAsyncFn(
    async (...args) => {
      const [data, error] = await getMasterUser()
      if (data) {
        setMasterUser(data)
      }
    },
    [setMasterUser]
  )

  const value: IProps = useMemo(() => {
    return {
      masterUser,
      selectedData,
      setSelectedData,
      contextToaster,
      setContextToaster,
      clientOptions,
      getAllClientsWithTypeAsync,
      getMasterUserAsync,
      fetchingClient,
      fetchingMasteUser,
    }
  }, [
    masterUser,
    selectedData,
    setSelectedData,
    contextToaster,
    setContextToaster,
    clientOptions,
    getAllClientsWithTypeAsync,
    getMasterUserAsync,
    fetchingClient,
    fetchingMasteUser,
  ])

  return <TransactionContext.Provider value={value}>{children}</TransactionContext.Provider>
}
