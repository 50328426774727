import {IAlert} from '../../../shared/components/CustomAlert'
import {IClientBankData, IClients, IClientsData, IGridSetup} from '../models/client'
import { PAYINS_FIELD_DEFAULT } from './config-default'

export const CLIENT_FORM_DEFAULT: IClients = {
  color_2: '',
  created_at: '',
  custom_descriptor: '',
  drivers_license: '',
  email: '',
  first_name: '',
  full_name: '',
  government_number: '',
  id: '',
  last_name: '',
  location: '',
  mobile: '',
  updated_at: '',
  addressLine1: '',
  addressLine2: '',
  dateOfBirth: null,
  suburb: '',
  state: '',
  postCode: '',
  country: 'AUS',
  lastName: '',
  firstName: '',
  debitAmount: 0,
  paymentCycle: 0
}

export const CLIENT_DATA_FORM_DEFAULT: IClientsData = {
  id: '',
  clientType: 1,
  firstName: '',
  lastName: '',
  englishName: '',
  sex: 'Male',
  occupation: '',
  dateOfBirth: null,
  mobile: 0,
  email: '',
  addressLine1: '',
  addressLine2: '',
  suburb: '',
  state: '',
  postCode: '',
  country: 'AUS',
  identity: 1,
  issuedBy: '',
  issuedDate: null,
  identityNumber: '',
  created: null,
  expiryDate: null,
  holderName: '',
  bankName: '',
  bsb1: '',
  bsb2: '',
  bankAccountNumber: '',
  bankAccountName: '',
  bankBranch: '',
  bankDescription: '',
  status: 1,
  businessDevelopmentManager: '',
  remarks: '',
  companyName: '',
  abnacn: '',
  walletBalance: 0,
  debitInformation: '',
  debitAmount: 0,
  paymentCycle: 0,
  paymentCycleDayOfMonth: 0,
  paymentCycleDayOfWeek: 0,
  paymentCycleStartDate: null,
  paymentCycleStartDateUtc: null,
  paymentCycleEndDate: null,
  paymentCycleOccurrence: 0,
  paymentCycleDescription: '',
  banks: [],
  isUploadFileFeatureEnabled: false,
  isPlentyPayHideFeatureEnabled: false,
  isClientDataFeatureEnabled: false,
  isIdentityDataFeatureEnabled: false,
  isHideBDMDropdownFeatureEnabled: false,
  isDirectDebitFormFeatureEnabled: false,
  isClientTypeSelectionFeatureEnabled: false,
  isMultiplePOCFeatureEnabled: false,
  passportCountry: 'AUS'
}

export const BANKDATA_DEFAULT: IClientBankData = {
  id: 0,
  bankName: '',
  bankAccountName: '',
  bankAccountNumber: '',
  bankBranch: '',
  bankDescription: '',
  bsb1: '',
  bsb2: '',
  isAllowedNoBankFeatureEnable: false
}

export const TOASTER_DEFAULT: IAlert = {
  message: ``,
  header: ``,
  type: 'success',
}


export const Initial_GridSetup: IGridSetup = {
  sort: [{ field: PAYINS_FIELD_DEFAULT.field, dir: 'desc' }],
  skip: 0,
  take: 10,
  filter: {
      logic: "and",
      filters: [],
  }
}