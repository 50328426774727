import { FC, Fragment, useEffect, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'

import { NppDetail } from './BPayDetail'
import { UserModel } from '../../../auth/models/UserModel'
import { RootState } from '../../../../../setup'
import { CustomAlert, IAlert } from '../../../../shared/components/CustomAlert'
import { getErrorMessage } from '../../../../shared/service/utils'
import { getClientBPayByEmail } from '../../api'
import { IDebitWallet } from '../../models/debit-wallet'
import OrderList from './OrderList'

const loadingPanel = (
  <div className='position-fixed k-loading-mask'>
    <span className='k-loading-text'>Loading</span>
    <div className='k-loading-image'></div>
    <div className='k-loading-color'></div>
  </div>
)

const Form: FC = () => {
  const [debitData, setDebitData] = useState<IDebitWallet | undefined>(undefined)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [formNotification, setformNotification] = useState<IAlert | undefined>()

  const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel

  useEffect(() => {
    fetchData()
  }, [user])

  const fetchData = async () => {
    setIsLoading(true)

    const [data, error] = await getClientBPayByEmail(user.email)
    if (data) {
      setDebitData(data)

      if (data?.errorMessages?.length > 0) {
        setformNotification({
          message: data.errorMessages.join(', '),
          header: `Error Information`,
          type: 'danger',
        })
      }
    } else {
      if (error) {
        const errorMessage = getErrorMessage(error)
        setformNotification({
          message: errorMessage,
          header: `Error BPay Information`,
          type: 'danger',
        })
      }
    }

    setIsLoading(false)
  }

  return (
    <Fragment>
      {isLoading && loadingPanel}
      {formNotification && (
        <CustomAlert {...formNotification} closeToaster={() => setformNotification(undefined)} />
      )}
      {debitData !== null && (
        <Fragment>
          <div className='row mt-5'>
            <div className='col-12 col-lg-8 col-md-8 content-container'>
              <div className='content-container-inner'>
                <OrderList debitData={debitData} />
              </div>
            </div>
            <div className='col-12 col-lg-4 col-md-4 content-container'>
              <div className='content-container-inner'>
                <NppDetail debitData={debitData} />
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </Fragment>
  )
}
export { Form }
