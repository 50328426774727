import axios from 'axios'

export const CLIENT_URL = `${process.env.REACT_APP_API_URL}/Client`

export const getClientBPayByEmail = async (email: string) => {
  try {
    const {data} = await axios.get(
      `${CLIENT_URL}/getClientBPayByEmail?email=${email}`
    )
    return [data, null]
  } catch (error) {
    return [null, error]
  }
}
