import {useIntl} from 'react-intl'
import * as yup from 'yup'

const ClientFormValidationSchema = () => {
  const intl = useIntl()

  return yup.object().shape({
    firstName: yup
      .string()
      .required(intl.formatMessage({id: 'ECOMMERCE.VALIDATION.FIRSTNAMEREQUIRED'})),
    lastName: yup
      .string()
      .required(intl.formatMessage({id: 'ECOMMERCE.VALIDATION.LASTNAMEREQUIRED'})),
    sex: yup
      .string()
      .required(intl.formatMessage({id: 'ECOMMERCE.VALIDATION.SEXREQUIRED'}))
      .oneOf(
        ['Male', 'Female', 'Not To Say'],
        intl.formatMessage({id: 'ECOMMERCE.VALIDATION.SEXREQUIRED'})
      ),
    email: yup
      .string()
      .email(intl.formatMessage({id: 'ECOMMERCE.VALIDATION.WRONGEMAILFORMAT'}))
      .max(50, intl.formatMessage({id: 'ECOMMERCE.VALIDATION.MAXIMUMSIMBOL'}))
      .required(intl.formatMessage({id: 'ECOMMERCE.VALIDATION.EMAILREQUIRED'})),
    addressLine1: yup
      .string()
      .required(intl.formatMessage({id: 'ECOMMERCE.VALIDATION.ADDRESSREQUIRED'})),
    suburb: yup.string().required(intl.formatMessage({id: 'ECOMMERCE.VALIDATION.SUBURBREQUIRED'})),
    state: yup.string().required(intl.formatMessage({id: 'ECOMMERCE.VALIDATION.STATEREQUIRED'})),
    postCode: yup
      .string()
      .required(intl.formatMessage({id: 'ECOMMERCE.VALIDATION.POSTCODEREQUIRED'})),
    dateOfBirth: yup.mixed().when('isClientDataFeatureEnabled', {
      is: false,
      then: yup.mixed().test({
        test: function (value) {
          if (!value)
            return this.createError({
              message: intl.formatMessage({id: 'ECOMMERCE.VALIDATION.DOBREQUIRED'}),
            })
          if (new Date(value) < new Date(1900, 0, 1))
            return this.createError({
              message: intl.formatMessage({id: 'ECOMMERCE.VALIDATION.DOBINVALID'}),
            })
          if (new Date(value) > new Date(2900, 0, 1))
            return this.createError({
              message: intl.formatMessage({id: 'ECOMMERCE.VALIDATION.DOBINVALID'}),
            })
          return true
        },
      }),
    }),
    clientType: yup.mixed().test({
      test: function (value) {
        if (value || value === 0) return true
        return this.createError({
          message: intl.formatMessage({id: 'ECOMMERCE.VALIDATION.CLIENTYPEREQUIRED'}),
        })
      },
    }),
    mobile: yup.mixed().test({
      test: function (value) {
        const digitPattern = /^[0-9]+$/
        if (!value)
          return this.createError({
            message: intl.formatMessage({id: 'ECOMMERCE.VALIDATION.MOBILEREQUIRED'}),
          })
        else if (value.length > 20)
          return this.createError({
            message: intl.formatMessage({id: 'ECOMMERCE.VALIDATION.MOBILEMUSTBE'}),
          })
        else if (!digitPattern.test(value))
          return this.createError({
            message: intl.formatMessage({id: 'ECOMMERCE.VALIDATION.MOBILENUMBERIC'}),
          })
        return true
      },
    }),
    country: yup.mixed().test({
      test: function (value) {
        if (!value)
          return this.createError({
            message: intl.formatMessage({id: 'ECOMMERCE.VALIDATION.COUNTRYREQUIRED'}),
          })
        return true
      },
    }),
    debitAmount: yup.mixed().when('isDirectDebitFormFeatureEnabled', {
      is: function (isDirectDebitFormFeatureEnabled: any) {
        return isDirectDebitFormFeatureEnabled !== undefined && isDirectDebitFormFeatureEnabled
      },
      then: yup
        .number()
        .typeError(intl.formatMessage({id: 'ECOMMERCE.VALIDATION.DEBITAMOUNTINVALID'}))
        .test({
          name: 'debitAmount',
          test: function (value) {
            if (!value || value === 0) {
              return this.createError({
                message: intl.formatMessage({id: 'ECOMMERCE.VALIDATION.DEBITAMOUNTREQUIRED'}),
              })
            }
            return true
          },
        })
        .nullable(),
    }),
    paymentCycle: yup.mixed().when('isDirectDebitFormFeatureEnabled', {
      is: function (isDirectDebitFormFeatureEnabled: any) {
        return isDirectDebitFormFeatureEnabled !== undefined && isDirectDebitFormFeatureEnabled
      },
      then: yup
        .number()
        .typeError(intl.formatMessage({id: 'ECOMMERCE.VALIDATION.DEBITAMOUNTINVALID'}))
        .test({
          name: 'paymentCycle',
          test: function (value) {
            if (!value || value === 0) {
              return this.createError({
                message: intl.formatMessage({id: 'ECOMMERCE.VALIDATION.PAYMENTCYCLEREQUIRED'}),
              })
            }

            return true
          },
        })
        .nullable(),
    }),
    paymentCycleStartDate: yup.mixed().when(['isDirectDebitFormFeatureEnabled', 'paymentCycle'], {
      is: (isDirectDebitFormFeatureEnabled: any, paymentCycle: number) => {
        return isDirectDebitFormFeatureEnabled && paymentCycle > 0
      },
      then: yup
        .date()
        .typeError(intl.formatMessage({id: 'ECOMMERCE.VALIDATION.STARTDATEINVALID'}))
        .min(
          new Date(1900, 0, 1),
          intl.formatMessage({id: 'ECOMMERCE.VALIDATION.STARTDATEINVALID'})
        )
        .max(
          new Date(2900, 0, 1),
          intl.formatMessage({id: 'ECOMMERCE.VALIDATION.STARTDATEINVALID'})
        )
        .required(intl.formatMessage({id: 'ECOMMERCE.VALIDATION.STARTDATEREQUIRED'})),
    }),
    paymentCycleEndDate: yup.mixed().when(['isDirectDebitFormFeatureEnabled', 'paymentCycle'], {
      is: (isDirectDebitFormFeatureEnabled: any, paymentCycle: number) => {
        return isDirectDebitFormFeatureEnabled && paymentCycle > 1
      },
      then: yup
        .date()
        .typeError(intl.formatMessage({id: 'ECOMMERCE.VALIDATION.ENDDATEINVALID'}))
        .min(new Date(1900, 0, 1), intl.formatMessage({id: 'ECOMMERCE.VALIDATION.ENDDATEINVALID'}))
        .max(new Date(2900, 0, 1), intl.formatMessage({id: 'ECOMMERCE.VALIDATION.ENDDATEINVALID'}))
        .required(intl.formatMessage({id: 'ECOMMERCE.VALIDATION.ENDDATEREQUIRED'})),
    }),
    banks: yup
      .array()
      .of(
        yup.object().shape({
          bankName: yup
          .mixed()
          .when(['isAllowedNoBankFeatureEnable'], {
            is: false,
            then: yup.string().required(intl.formatMessage({id: 'ECOMMERCE.VALIDATION.BANKNAMEREQUIRED'})),
            // Otherwise, no validation is applied
            otherwise: yup.string()
          }),
          bsb1: yup
            .mixed()
            .when(['isAllowedNoBankFeatureEnable'], {
              is: false,
              then: yup.string().test({
                test: function (value) {
                  const digitPattern = /^[0-9]+$/
                  if (value == null || value === '')
                    return this.createError({
                      message: intl.formatMessage({id: 'ECOMMERCE.VALIDATION.BSB1REQUIRED'}),
                    })
                  else if (!digitPattern.test(value))
                    return this.createError({
                      message: intl.formatMessage({id: 'ECOMMERCE.VALIDATION.BSB1MUSTBENUMERIC'}),
                    })
                  else if (value.length !== 3)
                    return this.createError({
                      message: intl.formatMessage({id: 'ECOMMERCE.VALIDATION.BSB1MUSTBEREQUIRED'}),
                    })
                  return true
                },
              })
           }),
          bsb2: yup
           .mixed()
           .when(['isAllowedNoBankFeatureEnable'], {
             is: false,
             then: yup.string().test({
               test: function (value) {
                 const digitPattern = /^[0-9]+$/
                 if (value == null || value === '')
                   return this.createError({
                     message: intl.formatMessage({id: 'ECOMMERCE.VALIDATION.BSB2REQUIRED'}),
                   })
                 else if (!digitPattern.test(value))
                   return this.createError({
                     message: intl.formatMessage({id: 'ECOMMERCE.VALIDATION.BSB2MUSTBENUMERIC'}),
                   })
                 else if (value.length !== 3)
                   return this.createError({
                     message: intl.formatMessage({id: 'ECOMMERCE.VALIDATION.BSB2MUSTBEREQUIRED'}),
                   })
                 return true
               },
             }),
          }),
          bankAccountName: yup
            .mixed()
            .when(['isAllowedNoBankFeatureEnable'], {
              is: false,
              then: yup.string().required(intl.formatMessage({id: 'ECOMMERCE.VALIDATION.BANKACCOUNTNAMEREQUIRED'})),
            }),
          bankAccountNumber: yup
            .mixed()
            .when(['isAllowedNoBankFeatureEnable'], {
              is: false,
              then: yup.string()
                .required(intl.formatMessage({id: 'ECOMMERCE.VALIDATION.BANKACCOUNTNUMBERREQUIRED'}))
                .matches(
                  /^\d*$/,
                  intl.formatMessage({
                    id: 'ECOMMERCE.VALIDATION.BANKACCOUNTNUMBERSHOULDONLYCONTAINNUMBERS',
                  })
                ),
            })
        })
      )
      .min(1, 'at least 1'),
  })
}

export default ClientFormValidationSchema
