import React from 'react'
import { useContext, useEffect, useState } from 'react'
import { Resolver, useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { yupResolver } from '@hookform/resolvers/yup'

import { CustomAlert, IAlert } from '../../../../shared/components/CustomAlert'
import { authorizeTransaction } from '../../api'
import { TRANSACTION_FORM_DEFAULT } from '../../constants/default'
import { TransactionContext } from '../../context/TransactionContext'
import { ITransaction } from '../../models/transaction-model'
import { transactionFormValidationSchema } from '../../validators/transaction-form'
import { getErrorMessage } from '../../../../shared/service/utils'
import { RejectTransactionModal } from './RejectTransactionModal'

interface IProps {
  transactionData?: ITransaction
  onClose: (refresh: boolean) => void
}

const AuthorizeForm: React.FC<IProps> = ({ transactionData, onClose }) => {
  const intl = useIntl()
  const { setContextToaster } = useContext(TransactionContext)
  const [formNotification, setformNotification] = useState<IAlert | undefined>()
  const [isProcessing, setIsProcessing] = useState<boolean>(false)
  const [modalDeleteTransactionShow, setModalDeleteTransactionShow] = useState<boolean>(false)


  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: transactionData,
    reValidateMode: 'onSubmit',
    resolver: yupResolver(transactionFormValidationSchema) as unknown as Resolver<ITransaction, any>,
  })

  useEffect(() => {
    if (transactionData) {
      reset({
        ...transactionData,
      })
    } else {
      reset(TRANSACTION_FORM_DEFAULT)
    }
  }, [transactionData, reset])

  const onSubmit = async (values: ITransaction) => {
    const payload: ITransaction = values

    setIsProcessing(true)
    const [data, error] = await authorizeTransaction(payload)
    if (data) {
      setContextToaster({
        message: `Transaction authorized successfully.`,
        header: `${intl.formatMessage({ id: 'ECOMMERCE.FIELD.AUTHORIZETRANSACTION' })}`,
        type: 'primary',
      })
      setIsProcessing(false)
      onClose(true)
    } else {
      const errorMessage = getErrorMessage(error)
      setformNotification({
        message: errorMessage,
        header: `Authorization Error`,
        type: 'danger',
      })

      setIsProcessing(false)
    }
  }

  const onReset = () => {
    reset(TRANSACTION_FORM_DEFAULT)
  }

  const closeDeleteTransactionModal = (refresh: boolean = false) => {
    setModalDeleteTransactionShow(false)
    onClose(true)
  }

  return (
    <React.Fragment>
      {formNotification && (
        <CustomAlert {...formNotification} closeToaster={() => setformNotification(undefined)} />
      )}
      <RejectTransactionModal
        transactionData={transactionData}
        show={modalDeleteTransactionShow}
        handleClose={closeDeleteTransactionModal}
      ></RejectTransactionModal>
      <form onSubmit={handleSubmit(onSubmit)} onReset={onReset} name='transaction'>
        <div className='modal-body'>
          <div className='row align-items-center mt-1 pe-2'>
            <div className='col-lg-6 col-md-6 col-12 mb-1'>
              <label className='form-label' htmlFor='name'>
                {intl.formatMessage({ id: 'ECOMMERCE.FIELD.TRANSACTIONID' })}::
              </label>
            </div>
            <div className='col-lg-6 col-md-6 col-12 mb-1'>
              <input
                type='text'
                className={`form-control${errors.transactionType ? ' border-danger' : ''}`}
                value={transactionData?.id}
                readOnly
              />
            </div>
          </div>
          <div className='row align-items-center mt-1 pe-2'>
            <div className='col-lg-6 col-md-6 col-12 mb-1'>
              <label className='form-label' htmlFor='name'>
                {intl.formatMessage({ id: 'ECOMMERCE.FIELD.CLIENTNAME' })}:
              </label>
            </div>
            <div className='col-lg-6 col-md-6 col-12 mb-1'>
              <input
                type='text'
                className={`form-control${errors.transactionType ? ' border-danger' : ''}`}
                value={transactionData?.clientName}
                readOnly
              />
            </div>
          </div>
          <div className='row align-items-center mt-1 pe-2'>
            <div className='col-lg-6 col-md-6 col-12 mb-1'>
              <label className='form-label' htmlFor='name'>
                {intl.formatMessage({ id: 'ECOMMERCE.FIELD.BANKACCOUNT' })}:
              </label>
            </div>
            <div className='col-lg-6 col-md-6 col-12 mb-1'>
              <input
                type='text'
                className={`form-control${errors.bankAccount ? ' border-danger' : ''}`}
                value={transactionData?.bankAccount}
                readOnly
              />
            </div>
          </div>

          <div className='row align-items-center mt-1 pe-2'>
            <div className='col-lg-6 col-md-6 col-12 mb-1'>
              <label className='form-label' htmlFor='name'>
                {intl.formatMessage({ id: 'ECOMMERCE.FIELD.BANKNAME' })}:
              </label>
            </div>
            <div className='col-lg-6 col-md-6 col-12 mb-1'>
              <input
                type='text'
                className={`form-control${errors.bankName ? ' border-danger' : ''}`}
                value={transactionData?.bankName}
                readOnly
              />
            </div>
          </div>

          <div className='row align-items-center mt-1 pe-2'>
            <div className='col-lg-6 col-md-6 col-12 mb-1'>
              <label className='form-label' htmlFor='name'>
                {intl.formatMessage({ id: 'ECOMMERCE.FIELD.AMOUNT' })}:
              </label>
            </div>
            <div className='col-lg-6 col-md-6 col-12 mb-1'>
              <input
                type='text'
                className={`form-control${errors.transactionType ? ' border-danger' : ''}`}
                value={transactionData?.amountFormatted}
                readOnly
              />
            </div>
          </div>
          <div className='row align-items-center mt-9 pe-2'>
            <div className='col-6 mb-1 '>
              <button
                type='submit'
                className='btn btn-primary col-md-12 p-4'
                disabled={isProcessing}
              >
                {isProcessing
                  ? intl.formatMessage({ id: 'ECOMMERCE.VALIDATION.PLEASEWAIT' })
                  : intl.formatMessage({ id: 'ECOMMERCE.FIELD.AUTHORIZETRANSACTION' })}
              </button>
            </div>
            <div className='col-6 mb-1 '>
              <button
                type='button'
                className='btn btn-danger col-md-12 p-4'
                onClick={() => setModalDeleteTransactionShow(true)}
              >
                {intl.formatMessage({ id: 'ECOMMERCE.ACTION.REJECT' })}
              </button>
            </div>
          </div>
        </div>
      </form>
    </React.Fragment>
  )
}

export { AuthorizeForm }
