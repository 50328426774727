import {Modal} from 'react-bootstrap-v5'
import {ITransaction} from '../../models/transaction-model'
import {AuthorizeForm} from './AuthorizeForm'
import {useIntl} from 'react-intl'
import {deleteTransaction, rejectTransaction} from '../../api'

interface IProps {
  show: boolean
  handleClose: (refresh: boolean) => void
  transactionData?: ITransaction
}

const RejectTransactionModal: React.FC<IProps> = ({show, handleClose, transactionData}) => {
  const intl = useIntl()

  const deleteHandler = () => {
    if (transactionData)
      rejectTransaction(transactionData?.id || 0).then(() => {
        handleClose(true)
      })
  }

  return (
    <Modal show={show} onHide={handleClose} size='lg'>
      <Modal.Header closeButton>
        <Modal.Title>
          {intl.formatMessage({id: 'ECOMMERCE.FIELD.AUTHORIZETRANSACTION'})}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {intl.formatMessage({id: 'ECOMMERCE.ACTION.REJECT'})} #{transactionData?.id}. ?
      </Modal.Body>
      <Modal.Footer>
        <div className='row align-items-center'>
          <div className='col-lg-12 col-md-12 col-12 mb-1 '>
            <button type='button' className='btn btn-primary col-md-12 p-4' onClick={deleteHandler}>
              {intl.formatMessage({id: 'ECOMMERCE.ACTION.REJECT'})}
            </button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  )
}

export {RejectTransactionModal}