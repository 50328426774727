import {Country} from '../models/client'

export const countriesData: Country[] = [
  {text: 'Afghanistan', id: 'AFG'},
  {text: 'Albania', id: 'ALB'},
  {text: 'Algeria', id: 'DZA'},
  {text: 'Andorra', id: 'AND'},
  {text: 'Angola', id: 'AGO'},
  {text: 'Antigua and Barbuda', id: 'ATG'},
  {text: 'Argentina', id: 'ARG'},
  {text: 'Armenia', id: 'ARM'},
  {text: 'Australia', id: 'AUS'},
  {text: 'Austria', id: 'AUT'},
  {text: 'Azerbaijan', id: 'AZE'},
  {text: 'Bahamas', id: 'BHS'},
  {text: 'Bahrain', id: 'BHR'},
  {text: 'Bangladesh', id: 'BGD'},
  {text: 'Barbados', id: 'BRB'},
  {text: 'Belarus', id: 'BLR'},
  {text: 'Belgium', id: 'BEL'},
  {text: 'Belize', id: 'BLZ'},
  {text: 'Benin', id: 'BEN'},
  {text: 'Bhutan', id: 'BTN'},
  {text: 'Bolivia (Plurinational State of)', id: 'BOL'},
  {text: 'Bosnia and Herzegovina', id: 'BIH'},
  {text: 'Botswana', id: 'BWA'},
  {text: 'Brazil', id: 'BRA'},
  {text: 'Brunei Darussalam', id: 'BRN'},
  {text: 'Bulgaria', id: 'BGR'},
  {text: 'Burkina Faso', id: 'BFA'},
  {text: 'Burundi', id: 'BDI'},
  {text: 'Cabo Verde', id: 'CPV'},
  {text: 'Cambodia', id: 'KHM'},
  {text: 'Cameroon', id: 'CMR'},
  {text: 'Canada', id: 'CAN'},
  {text: 'Central African Republic', id: 'CAF'},
  {text: 'Chad', id: 'TCD'},
  {text: 'Chile', id: 'CHL'},
  {text: 'China', id: 'CHN'},
  {text: 'Colombia', id: 'COL'},
  {text: 'Comoros', id: 'COM'},
  {text: 'Congo', id: 'COG'},
  {text: 'Costa Rica', id: 'CRI'},
  {text: "Côte d'Ivoire", id: 'CIV'},
  {text: 'Croatia', id: 'HRV'},
  {text: 'Cuba', id: 'CUB'},
  {text: 'Cyprus', id: 'CYP'},
  {text: 'Czechia', id: 'CZE'},
  {text: "Democratic People's Republic of Korea", id: 'PRK'},
  {text: 'Democratic Republic of the Congo', id: 'COD'},
  {text: 'Denmark', id: 'DNK'},
  {text: 'Djibouti', id: 'DJI'},
  {text: 'Dominica', id: 'DMA'},
  {text: 'Dominican Republic', id: 'DOM'},
  {text: 'Ecuador', id: 'ECU'},
  {text: 'Egypt', id: 'EGY'},
  {text: 'El Salvador', id: 'SLV'},
  {text: 'Equatorial Guinea', id: 'GNQ'},
  {text: 'Eritrea', id: 'ERI'},
  {text: 'Estonia', id: 'EST'},
  {text: 'Eswatini', id: 'SWZ'},
  {text: 'Ethiopia', id: 'ETH'},
  {text: 'Fiji', id: 'FJI'},
  {text: 'Finland', id: 'FIN'},
  {text: 'France', id: 'FRA'},
  {text: 'Gabon', id: 'GAB'},
  {text: 'Gambia', id: 'GMB'},
  {text: 'Georgia', id: 'GEO'},
  {text: 'Germany', id: 'DEU'},
  {text: 'Ghana', id: 'GHA'},
  {text: 'Greece', id: 'GRC'},
  {text: 'Grenada', id: 'GRD'},
  {text: 'Guatemala', id: 'GTM'},
  {text: 'Guinea', id: 'GIN'},
  {text: 'Guinea-Bissau', id: 'GNB'},
  {text: 'Guyana', id: 'GUY'},
  {text: 'Haiti', id: 'HTI'},
  {text: 'Honduras', id: 'HND'},
  {text: 'Hungary', id: 'HUN'},
  {text: 'Iceland', id: 'ISL'},
  {text: 'India', id: 'IND'},
  {text: 'Indonesia', id: 'IDN'},
  {text: 'Iran (Islamic Republic of)', id: 'IRN'},
  {text: 'Iraq', id: 'IRQ'},
  {text: 'Ireland', id: 'IRL'},
  {text: 'Israel', id: 'ISR'},
  {text: 'Italy', id: 'ITA'},
  {text: 'Jamaica', id: 'JAM'},
  {text: 'Japan', id: 'JPN'},
  {text: 'Jordan', id: 'JOR'},
  {text: 'Kazakhstan', id: 'KAZ'},
  {text: 'Kenya', id: 'KEN'},
  {text: 'Kiribati', id: 'KIR'},
  {text: 'Kuwait', id: 'KWT'},
  {text: 'Kyrgyzstan', id: 'KGZ'},
  {text: "Lao People's Democratic Republic", id: 'LAO'},
  {text: 'Latvia', id: 'LVA'},
  {text: 'Lebanon', id: 'LBN'},
  {text: 'Lesotho', id: 'LSO'},
  {text: 'Liberia', id: 'LBR'},
  {text: 'Libya', id: 'LBY'},
  {text: 'Liechtenstein', id: 'LIE'},
  {text: 'Lithuania', id: 'LTU'},
  {text: 'Luxembourg', id: 'LUX'},
  {text: 'Madagascar', id: 'MDG'},
  {text: 'Malawi', id: 'MWI'},
  {text: 'Malaysia', id: 'MYS'},
  {text: 'Maldives', id: 'MDV'},
  {text: 'Mali', id: 'MLI'},
  {text: 'Malta', id: 'MLT'},
  {text: 'Marshall Islands', id: 'MHL'},
  {text: 'Mauritania', id: 'MRT'},
  {text: 'Mauritius', id: 'MUS'},
  {text: 'Mexico', id: 'MEX'},
  {text: 'Micronesia (Federated States of)', id: 'FSM'},
  {text: 'Monaco', id: 'MCO'},
  {text: 'Mongolia', id: 'MNG'},
  {text: 'Montenegro', id: 'MNE'},
  {text: 'Morocco', id: 'MAR'},
  {text: 'Mozambique', id: 'MOZ'},
  {text: 'Myanmar', id: 'MMR'},
  {text: 'Namibia', id: 'NAM'},
  {text: 'Nauru', id: 'NRU'},
  {text: 'Nepal', id: 'NPL'},
  {text: 'Netherlands', id: 'NLD'},
  {text: 'New Zealand', id: 'NZL'},
  {text: 'Nicaragua', id: 'NIC'},
  {text: 'Niger', id: 'NER'},
  {text: 'Nigeria', id: 'NGA'},
  {text: 'North Macedonia', id: 'MKD'},
  {text: 'Norway', id: 'NOR'},
  {text: 'Oman', id: 'OMN'},
  {text: 'Pakistan', id: 'PAK'},
  {text: 'Palau', id: 'PLW'},
  {text: 'Panama', id: 'PAN'},
  {text: 'Papua New Guinea', id: 'PNG'},
  {text: 'Paraguay', id: 'PRY'},
  {text: 'Peru', id: 'PER'},
  {text: 'Philippines', id: 'PHL'},
  {text: 'Poland', id: 'POL'},
  {text: 'Portugal', id: 'PRT'},
  {text: 'Qatar', id: 'QAT'},
  {text: 'Republic of Korea', id: 'KOR'},
  {text: 'Republic of Moldova', id: 'MDA'},
  {text: 'Romania', id: 'ROU'},
  {text: 'Russian Federation', id: 'RUS'},
  {text: 'Rwanda', id: 'RWA'},
  {text: 'Saint Kitts and Nevis', id: 'KNA'},
  {text: 'Saint Lucia', id: 'LCA'},
  {text: 'Saint Vincent and the Grenadines', id: 'VCT'},
  {text: 'Samoa', id: 'WSM'},
  {text: 'San Marino', id: 'SMR'},
  {text: 'Sao Tome and Principe', id: 'STP'},
  {text: 'Saudi Arabia', id: 'SAU'},
  {text: 'Senegal', id: 'SEN'},
  {text: 'Serbia', id: 'SRB'},
  {text: 'Seychelles', id: 'SYC'},
  {text: 'Sierra Leone', id: 'SLE'},
  {text: 'Singapore', id: 'SGP'},
  {text: 'Slovakia', id: 'SVK'},
  {text: 'Slovenia', id: 'SVN'},
  {text: 'Solomon Islands', id: 'SLB'},
  {text: 'Somalia', id: 'SOM'},
  {text: 'South Africa', id: 'ZAF'},
  {text: 'South Sudan', id: 'SSD'},
  {text: 'Spain', id: 'ESP'},
  {text: 'Sri Lanka', id: 'LKA'},
  {text: 'Sudan', id: 'SDN'},
  {text: 'Suriname', id: 'SUR'},
  {text: 'Sweden', id: 'SWE'},
  {text: 'Switzerland', id: 'CHE'},
  {text: 'Syrian Arab Republic', id: 'SYR'},
  {text: 'Tajikistan', id: 'TJK'},
  {text: 'Thailand', id: 'THA'},
  {text: 'Timor-Leste', id: 'TLS'},
  {text: 'Togo', id: 'TGO'},
  {text: 'Tonga', id: 'TON'},
  {text: 'Trinidad and Tobago', id: 'TTO'},
  {text: 'Tunisia', id: 'TUN'},
  {text: 'Turkey', id: 'TUR'},
  {text: 'Turkmenistan', id: 'TKM'},
  {text: 'Tuvalu', id: 'TUV'},
  {text: 'Uganda', id: 'UGA'},
  {text: 'Ukraine', id: 'UKR'},
  {text: 'United Arab Emirates', id: 'ARE'},
  {text: 'United Kingdom of Great Britain and Northern Ireland', id: 'GBR'},
  {text: 'United Republic of Tanzania', id: 'TZA'},
  {text: 'United States of America', id: 'USA'},
  {text: 'Uruguay', id: 'URY'},
  {text: 'Uzbekistan', id: 'UZB'},
  {text: 'Vanuatu', id: 'VUT'},
  {text: 'Venezuela (Bolivarian Republic of)', id: 'VEN'},
  {text: 'Viet Nam', id: 'VNM'},
  {text: 'Yemen', id: 'YEM'},
  {text: 'Zambia', id: 'ZMB'},
  {text: 'Zimbabwe', id: 'ZWE'},
  // Add more countries as needed
]
