import {Grid, GridColumn as Column, GridCellProps} from '@progress/kendo-react-grid'

import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'

import {AmountFormatCell} from '../../../../../shared/components/kendo/format/AmountFormatCell'
import {DateFormatCell} from '../../../../../shared/components/kendo/format/DateFormatCell'
import {IItemsData} from '../../../../orders/models/items'
import {KendoI18Provider} from '../../../../../../_metronic/i18n/kendoI18Provider'

interface IProps {
  clientOrders: IItemsData[] | undefined
}

const ClientOrders: React.FC<IProps> = ({clientOrders}) => {
  const intl = useIntl()
  const [tableData, setTableData] = useState<IItemsData[]>([])

  useEffect(() => {
    if (clientOrders) {
      setTableData(clientOrders)
    }
  }, [clientOrders])

  const amountFormatCell = (dataProps: GridCellProps) => (
    <AmountFormatCell props={dataProps} currency={'$'}></AmountFormatCell>
  )

  const paymentTypeFormatCell = (dataProps: GridCellProps) => (
    <td>{dataProps.dataItem.paymentType === 2 ? 'Express' : dataProps.dataItem.paymentType}</td>
  )

  return (
    <KendoI18Provider>
      <Grid resizable={true} data={tableData}>
        <Column title={intl.formatMessage({id: 'ECOMMERCE.FIELD.ORDERNUMBER'})} field='uuid' />
        <Column
          title={intl.formatMessage({id: 'ECOMMERCE.FIELD.CREATEDDATE'})}
          field='createdDate'
          cell={DateFormatCell}
        />
        <Column
          title={intl.formatMessage({id: 'ECOMMERCE.FIELD.AMOUNT'})}
          field='amount'
          cell={amountFormatCell}
        />
        <Column title={intl.formatMessage({id: 'ECOMMERCE.FIELD.CURRENCY'})} field='currency' />
        <Column title={intl.formatMessage({id: 'ECOMMERCE.FIELD.PAYER'})} field='buyerName' />
        <Column title={intl.formatMessage({id: 'ECOMMERCE.FIELD.RECEIVER'})} field='sellerName' />
        <Column
          title={intl.formatMessage({id: 'ECOMMERCE.FIELD.PAYMENTTYPE'})}
          field='paymentType'
          cell={paymentTypeFormatCell}
        />
      </Grid>
    </KendoI18Provider>
  )
}

export default ClientOrders
