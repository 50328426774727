
import React, { FC, useEffect, useState } from "react";
import { useFeatureFlags } from "../../context/FeatureFlags";

const FeatureFlagProvider: FC<{ featureName: string }> = ({ children, featureName }) => {
    const { features } = useFeatureFlags();
    const [isEnabled, setIsEnabled] = useState<boolean>(false)

    useEffect(() => {
        if (features.some((feature) => feature.name === featureName)) {
            setIsEnabled(true);
        }
    }, [features]);

    return isEnabled ? <>{children}</> : null;
};

export {FeatureFlagProvider}