import {Grid, GridColumn as Column, GridCellProps} from '@progress/kendo-react-grid'

import {FC, useEffect, useState} from 'react'

import {IClientWallet, IClientWalletBpay} from '../../../models/client-wallet'
import {AmountFormatCell} from '../../../../../shared/components/kendo/format/AmountFormatCell'
import {DateFormatCell} from '../../../../../shared/components/kendo/format/DateFormatCell'
import {useIntl} from 'react-intl'
import {KendoI18Provider} from '../../../../../../_metronic/i18n/kendoI18Provider'

interface IProps {
  clientWallet: IClientWallet | undefined
  clientWalletBpay?: IClientWalletBpay
  balance: number
}

const ClientWallet: FC<IProps> = ({clientWallet, balance, clientWalletBpay}) => {
  const intl = useIntl()
  const [tableData, setTableData] = useState<IClientWallet[]>([])

  useEffect(() => {
    if (clientWallet && clientWalletBpay) {
      var newTable = []
      clientWallet.balance = balance
      clientWallet.biller_code = clientWalletBpay.biller_code
      clientWallet.reference = clientWalletBpay.reference
      newTable.push(clientWallet)
      setTableData(newTable)
    }
  }, [clientWallet, clientWalletBpay])

  const amountFormatCell = (dataProps: GridCellProps) => (
    <AmountFormatCell props={dataProps} currency={'$'}></AmountFormatCell>
  )

  const activeFormatCell = (dataProps: GridCellProps) => (
    <td>{dataProps.dataItem.active ? 'Yes' : 'No'}</td>
  )

  return (
    <KendoI18Provider>
      <Grid resizable={true} data={tableData}>
        <Column title='UUID' field='id' />
        <Column
          title={intl.formatMessage({id: 'ECOMMERCE.COMMON.ACTIVE'})}
          field='active'
          cell={activeFormatCell}
        />
        <Column
          title={intl.formatMessage({id: 'ECOMMERCE.FIELD.BALANCE'})}
          field='balance'
          cell={amountFormatCell}
        />
        <Column title={intl.formatMessage({id: 'ECOMMERCE.FIELD.CURRENCY'})} field='currency' />
        <Column
          title={intl.formatMessage({id: 'ECOMMERCE.FIELD.BILLERCODE'})}
          field='biller_code'
        />
        <Column title={intl.formatMessage({id: 'ECOMMERCE.FIELD.REFERENCE'})} field='reference' />
        <Column
          title={intl.formatMessage({id: 'ECOMMERCE.FIELD.CREATEDDATE'})}
          field='created_at'
          cell={DateFormatCell}
        />
        <Column
          title={intl.formatMessage({id: 'ECOMMERCE.FIELD.UPDATEDDATE'})}
          field='updated_at'
          cell={DateFormatCell}
        />
      </Grid>
    </KendoI18Provider>
  )
}

export default ClientWallet
