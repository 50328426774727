import React from 'react'
import {useIntl} from 'react-intl'

import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import ClientProfileWrapper from './components/ClientProfileWrapper'

const ClientProfilePage: React.FC = () => {
  const intl = useIntl()

  const widgetsBreadCrumbs: Array<PageLink> = [
    {
      title: intl.formatMessage({id: 'MENU.CLIENTS'}),
      path: '/client',
      isSeparator: false,
      isActive: false,
    },
    {
      title: 'View',
      path: '/client/profile',
      isSeparator: true,
      isActive: false,
    },
  ]

  return (
    <div className='card card-custom'>
      <div className='card-body'>
        <div className='container-fluid px-0 mb-10'>
          <div className='row justify-content-between align-items-center'>
            <PageTitle breadcrumbs={widgetsBreadCrumbs}>
              {intl.formatMessage({id: 'ACTION.CLIENTPROFILE'})}
            </PageTitle>
            <ClientProfileWrapper />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ClientProfilePage
