import {FC} from 'react'

import {Form} from './partials/Form'

const MasterWalletWrapper: FC = () => {
  return (
    <div className='card card-custom'>
      <div className='card-body'>
        <div className='container-fluid px-0 mb-10'>
          <div className='justify-content-between align-items-center'>
            <Form />
          </div>
        </div>
      </div>
    </div>
  )
}

export default MasterWalletWrapper
