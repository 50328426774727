import {FC} from 'react'
import {useIntl} from 'react-intl'
import {Redirect, Route, Switch} from 'react-router-dom'

import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {ClientContextProvider} from './context/ClientContext'
import ClientList from './components/partials/ClientList'
import CreateClientPage from './CreateClientPage'
import EditClientPage from './EditClientPage'
import ClientProfilePage from './ClientProfilePage'

const widgetsBreadCrumbs: Array<PageLink> = [
  {
    title: 'Clients',
    path: '/client/list',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const ClientPage: FC = () => {
  const intl = useIntl()

  return (
    <Switch>
      <Redirect exact from='/client' to='/client/list' />
      <ClientContextProvider>
        <Route exact path='/client/list'>
          <PageTitle>{intl.formatMessage({id: 'MENU.CLIENTS'})}</PageTitle>
          <ClientList />
        </Route>
        <Route exact path='/client/new'>
          <PageTitle breadcrumbs={widgetsBreadCrumbs}>
            {intl.formatMessage({id: 'ACTION.NEWCLIENT'})}
          </PageTitle>
          <CreateClientPage />
        </Route>
        <Route exact path='/client/edit'>
          <PageTitle breadcrumbs={widgetsBreadCrumbs}>
            {intl.formatMessage({id: 'ACTION.EDITCLIENT'})}
          </PageTitle>
          <EditClientPage />
        </Route>
        <Route exact path='/client/profile'>
          <PageTitle breadcrumbs={widgetsBreadCrumbs}>
            {intl.formatMessage({id: 'ACTION.CLIENTPROFILE'})}
          </PageTitle>
          <ClientProfilePage />
        </Route>
      </ClientContextProvider>
    </Switch>
  )
}

export default ClientPage
