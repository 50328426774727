import React from 'react'
import {
  Calendar,
  CalendarCell,
  CalendarCellProps,
  CalendarProps,
} from '@progress/kendo-react-dateinputs'
import {PaymentCycleType} from '../../../constant/config-default'

interface CustomCalendarProps extends CalendarProps {
  selectedPaymentCycleType: number
  paymentCycleStartDate: Date
}

const CustomCalendar: React.FC<CustomCalendarProps> = ({
  selectedPaymentCycleType,
  paymentCycleStartDate,
  ...props
}) => {
  const customCell = (cellProps: CalendarCellProps) => {
    const style: React.CSSProperties = {}
    const startDate = new Date(paymentCycleStartDate)

    const paymentCycleDayOfWeek = startDate.getDay()
    // Check if the date is on the specified day of the week
    const dayOfWeek = cellProps.value.getDay()

    if (parseInt(selectedPaymentCycleType.toString()) === PaymentCycleType.Weekly) {
      if (dayOfWeek === paymentCycleDayOfWeek && cellProps.value > startDate) {
        style.fontWeight = 'bold'
        style.color = 'red' // Emphasize by changing text color
        return <CalendarCell {...cellProps} style={style} />
      }
    } else if (parseInt(selectedPaymentCycleType.toString()) === PaymentCycleType.Fortnite) {
      // Calculate the difference in days between the current date and startDate
      const timeDifference = cellProps.value.getTime() - startDate.getTime()
      const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24))

      // Check if the days difference is a multiple of 14 (per 2 weeks) and the date is greater than startDate
      if (daysDifference >= 0 && daysDifference % 14 === 0 && cellProps.value > startDate) {
        style.fontWeight = 'bold'
        style.color = 'red' // Emphasize by changing text color
        return <CalendarCell {...cellProps} style={style} />
      }
    } else if (parseInt(selectedPaymentCycleType.toString()) === PaymentCycleType.Monthly) {
      const currentDate = cellProps.value
      const dayOfMonth = startDate.getDate()

      // Calculate the difference in months
      const monthsDifference =
        (currentDate.getFullYear() - startDate.getFullYear()) * 12 +
        currentDate.getMonth() -
        startDate.getMonth()

      // Check if it's the same day of the month as startDate
      if (
        monthsDifference >= 0 &&
        monthsDifference % 1 === 0 &&
        currentDate.getDate() === dayOfMonth
      ) {
        style.fontWeight = 'bold'
        style.color = 'red' // Emphasize by changing text color
        return <CalendarCell {...cellProps} style={style} />
      }
    }
    return <CalendarCell {...cellProps} isDisabled />
  }

  return <Calendar {...props} cell={customCell} />
}

export default CustomCalendar
