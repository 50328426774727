import {
  Grid,
  GridColumn as Column,
  GridCellProps,
  GridFilterChangeEvent,
} from '@progress/kendo-react-grid'

import {useCallback, useContext, useEffect, useState} from 'react'
import {useHistory} from 'react-router-dom'
import {useIntl} from 'react-intl'

import {getClientListWithPagination} from '../../api/ClientApi'
import {GridSetup} from '../../../users/models/user-model'
import {CLIENT_DATA_FORM_DEFAULT} from '../../constant/client-default'
import {FILTER_DEFAULT, Initial_GridSetup} from '../../constant/config-default'
import {IClientsData} from '../../models/client'
import {DateFormatCell} from '../../../../shared/components/kendo/format/DateFormatCell'
import {ClientContext} from '../../context/ClientContext'
import {transformClientFilter, transformClientSort} from '../../transformer/client-transform'
import {hasPermission, toLinQExpression} from '../../../../shared/service/utils'
import {NoValueFormatCell} from '../../../../shared/components/kendo/format/NoValueFormatCell'
import {AmountFormatCell} from '../../../../shared/components/kendo/format/AmountFormatCell'
import {DropDownList, DropDownListChangeEvent} from '@progress/kendo-react-dropdowns'
import {KendoI18Provider} from '../../../../../_metronic/i18n/kendoI18Provider'
import {useFeatureFlags} from '../../../../context/FeatureFlags'
import { usePageData } from '../../../../../_metronic/layout/core'
import { PAGE_PERMISSION } from '../../../roles/constant/role-default'

const loadingPanel = (
  <div className='position-fixed k-loading-mask'>
    <span className='k-loading-text'>Loading</span>
    <div className='k-loading-image'></div>
    <div className='k-loading-color'></div>
  </div>
)

const ClientList: React.FC = () => {
  const intl = useIntl()
  const history = useHistory()
  const {features} = useFeatureFlags()

  const { currentUser: { activePages } } = usePageData();

  const [gridSetup, setGridSetup] = useState<GridSetup>(Initial_GridSetup)
  const [tableData, setTableData] = useState<IClientsData[]>([])
  const [totalRows, setTotalRows] = useState<number>(0)
  const {setSelectedData} = useContext(ClientContext)
  const [isLoading, setIsLoading] = useState(false)
  const [clientType, setClientType] = useState('1')
  const [isDirectDebitFormFeatureEnabled, setIsDirectDebitFormFeatureEnabled] = useState(false)
  const [isClientTypeSelectionFeatureEnabled, setIsClientTypeSelectionFeatureEnabled] =
    useState(false)

  const defaultItem = {text: `${intl.formatMessage({id: 'ECOMMERCE.ACTION'})}`}
  const isCompanyNameVisible = clientType !== '1'

  const fetchData = useCallback(() => {
    setIsLoading(true)
    const {sort, skip, take} = gridSetup
    var filter = transformClientFilter(gridSetup.filter)
    var data = toLinQExpression(filter)
    const sortField = transformClientSort(sort)
    getClientListWithPagination(skip, take, sortField, data || FILTER_DEFAULT, Number(clientType))
      .then((response: any) => {
        setTableData(response.items)
        setTotalRows(response.totalCount)
        setIsLoading(false)
      })
      .catch(() => {
        setIsLoading(false)
      })
  }, [gridSetup, clientType])

  useEffect(() => {
    if (features.some((feature) => feature.name === 'DirectDebitFormFeature')) {
      setIsDirectDebitFormFeatureEnabled(true)
    }
    if (features.some((feature) => feature.name === 'ClientTypeSelectionFeature')) {
      setIsClientTypeSelectionFeatureEnabled(true)
    }
  }, [features])

  useEffect(() => {
    fetchData()
  }, [gridSetup, fetchData, clientType])

  const handlePageChange = (pageProps: any) => {
    setGridSetup({
      ...gridSetup,
      skip: pageProps.page.skip,
      take: pageProps.page.take,
    })
  }

  const handleSortChange = (pageProps: any) => {
    setGridSetup({
      ...gridSetup,
      sort: pageProps.sort,
    })
  }

  const handleFilter = (filter: GridFilterChangeEvent) => {
    setGridSetup({
      ...Initial_GridSetup,
      filter: filter.filter,
    })
  }

  const filterOperators = {
    text: [
      {
        text: 'grid.filterContainsOperator',
        operator: 'contains',
      },
    ],
    boolean: [
      {
        text: 'grid.filterEqOperator',
        operator: 'eq',
      },
    ],
  }

  const handleClientTypeChange = (event: any) => {
    setGridSetup({...gridSetup, skip: 0})
    setClientType(event.target.value)
  }

  const amountFormatCell = (dataProps: GridCellProps) => (
    <AmountFormatCell props={dataProps}></AmountFormatCell>
  )

  const goToViewProfile = (companyUnqId: string) => {
    history.push({
      pathname: '/client/profile',
      search: `?companyId=${companyUnqId}`,
    })
  }

  const goToEditProfile = (id: string) => {
    history.push({
      pathname: '/client/edit',
      search: `?id=${id}`,
    })
  }

  const commandChangeHandler = (event: DropDownListChangeEvent) => {
    const {
      text,
      value: {dataItem},
    } = event.target.value
    if (text === `${intl.formatMessage({id: 'ECOMMERCE.ACTION.EDIT'})}`) {
      setSelectedData(dataItem)
      goToEditProfile(dataItem.unqId)
    }
    if (text === `${intl.formatMessage({id: 'ECOMMERCE.ACTION.VIEWPROFILE'})}`) {
      goToViewProfile(dataItem.companyUnqId)
    }
  }

  const actionCellButtonDropdown = (props: GridCellProps) => {
    let action = [
      {text: `${intl.formatMessage({id: 'ECOMMERCE.ACTION.VIEWPROFILE'})}`, value: props},
    ];


    if(hasPermission(PAGE_PERMISSION.Clients_Edit, activePages)) { 
       action = [
        {text: `${intl.formatMessage({id: 'ECOMMERCE.ACTION.EDIT'})}`, value: props},
        {text: `${intl.formatMessage({id: 'ECOMMERCE.ACTION.VIEWPROFILE'})}`, value: props},
      ];
    }


      return(
      <td className='k-command-cell'>
        <DropDownList
          data={action}
          textField='text'
          defaultItem={defaultItem}
          value={defaultItem}
          onChange={commandChangeHandler}
        />
      </td>
    )
  }

  return (
    <>
      <div className='row justify-content-between align-items-center pb-2'>
        <div className='col-lg-8 col-md-8 col-6 mb-4'>

          {hasPermission(PAGE_PERMISSION.Clients_Add, activePages) && (
              <button
              className='btn btn-primary'
              onClick={() => {
                setSelectedData(CLIENT_DATA_FORM_DEFAULT)
                history.push('/client/new')
              }}
            >
              <i className='bi bi-plus-lg'></i> {intl.formatMessage({id: 'ACTION.NEWCLIENT'})}
            </button>
          )}
        
        </div>
        {!isClientTypeSelectionFeatureEnabled ? (
          <div className='col-lg-4 col-md-4 col-6 align-items-center'>
            <div className='row'>
              <div className='col-6 pt-4 text-end'>
                <label className='form-label'>
                  {intl.formatMessage({id: 'CLIENT.CLIENTTYPE'})} :
                </label>
              </div>
              <div className='col-6'>
                <select
                  className='form-select form-select-solid'
                  data-kt-select2='true'
                  data-placeholder='Select option'
                  data-allow-clear='true'
                  defaultValue={'1'}
                  onChange={handleClientTypeChange}
                >
                  <option value='1'>
                    {intl.formatMessage({id: 'ECOMMERCE.FIELD.INDIVIDUAL'})}
                  </option>
                  <option value='2'>{intl.formatMessage({id: 'ECOMMERCE.FIELD.COMPANY'})}</option>
                </select>
              </div>
            </div>
          </div>
        ) : null}
      </div>
      {isLoading && loadingPanel}
      <KendoI18Provider>
        <Grid
          resizable={true}
          reorderable={true}
          data={tableData}
          total={totalRows}
          onPageChange={handlePageChange}
          sortable={true}
          onSortChange={handleSortChange}
          filterable={true}
          onFilterChange={handleFilter}
          {...gridSetup}
          pageable={true}
          filterOperators={filterOperators}
        >
          <Column
            title={intl.formatMessage({id: 'ECOMMERCE.FIELD.ACTIONS'})}
            cell={actionCellButtonDropdown}
            filterable={false}
          />
          {isCompanyNameVisible && (
            <Column
              title={intl.formatMessage({id: 'ECOMMERCE.FIELD.COMPANYNAME'})}
              field='companyName'
              cell={NoValueFormatCell}
            />
          )}

          <Column title={intl.formatMessage({id: 'ECOMMERCE.FIELD.FIRSTNAME'})} field='firstName' />
          <Column title={intl.formatMessage({id: 'ECOMMERCE.FIELD.LASTNAME'})} field='lastName' />
          <Column title={intl.formatMessage({id: 'ECOMMERCE.FIELD.EMAIL'})} field='email' />
          {isDirectDebitFormFeatureEnabled ? (
            <Column
              title={intl.formatMessage({id: 'ECOMMERCE.FIELD.DEBITAMOUNT'})}
              field='debitAmount'
              cell={amountFormatCell}
            />
          ) : null}
          {isDirectDebitFormFeatureEnabled ? (
            <Column
              title={intl.formatMessage({id: 'ECOMMERCE.FIELD.PAYMENTCYCLE'})}
              field='paymentCycleName'
            />
          ) : null}

          <Column
            title={intl.formatMessage({id: 'ECOMMERCE.FIELD.CREATEDDATE'})}
            field='zaiCreatedDate'
            format={'{0:dd/MM/yyy}'}
            cell={DateFormatCell}
            filterable={false}
          />
          <Column
            title={intl.formatMessage({id: 'ECOMMERCE.FIELD.UPDATEDDATE'})}
            field='zaiLastModified'
            format={'{0:dd/MM/yyy}'}
            cell={DateFormatCell}
            filterable={false}
          />
          <Column
            title={intl.formatMessage({id: 'ECOMMERCE.FIELD.BALANCE'})}
            field='walletBalance'
            cell={amountFormatCell}
          />
        </Grid>
      </KendoI18Provider>
    </>
  )
}

export default ClientList
