import { GridCellProps } from '@progress/kendo-react-grid'

export const NoValueFormatCell = (props: GridCellProps) => {
    const { dataItem } = props;
    const field = props.field || '';
    const dataValue = dataItem ? (dataItem[field] ? dataItem[field] : '') : '';
  
    return (
      <td>
        {dataValue.trim() ? dataValue : 'N/A'}
      </td>
    );
  };