import { useIntl } from "react-intl";
import * as yup from 'yup'

export const BankFormValidationSchema = () => {
  const intl = useIntl()
  return yup.object().shape({
    bankName: yup
      .mixed()
      .when(['isAllowedNoBankFeatureEnable'], {
        is: (isAllowedNoBankFeatureEnable: any) => {
          return !isAllowedNoBankFeatureEnable
        },  
        then: yup.string().required(intl.formatMessage({id: 'ECOMMERCE.VALIDATION.BANKNAMEREQUIRED'}))
      }),
      // .required(intl.formatMessage({id: 'ECOMMERCE.VALIDATION.BANKNAMEREQUIRED'})),
    bsb1: yup
      .mixed()
      .when(['isAllowedNoBankFeatureEnable', 'bankName'], {
        is: (isAllowedNoBankFeatureEnable: any, bsb2: string) => {
          return !isAllowedNoBankFeatureEnable || 
            bsb2.length > 0 
        },    
        then: yup.mixed().test({
          test: function (value) {
            const digitPattern = /^[0-9]+$/
            if (value == null || value === '')
              return this.createError({
                message: intl.formatMessage({id: 'ECOMMERCE.VALIDATION.BSB1REQUIRED'}),
              })
            else if (!digitPattern.test(value))
              return this.createError({
                message: intl.formatMessage({id: 'ECOMMERCE.VALIDATION.BSB1MUSTBENUMERIC'}),
              })
            else if (value.length !== 3)
              return this.createError({
                message: intl.formatMessage({id: 'ECOMMERCE.VALIDATION.BSB1MUSTBEREQUIRED'}),
              })
            return true
          },
        })
     }),
    bsb2: yup
      .mixed()
      .when(['isAllowedNoBankFeatureEnable', 'bsb1'], {
        is: (isAllowedNoBankFeatureEnable: any, bsb1: string) => {
          return !isAllowedNoBankFeatureEnable || 
          bsb1.length > 0 
        },   
        then: yup.mixed().test({
          test: function (value) {
            const digitPattern = /^[0-9]+$/
            if (value == null || value === '')
              return this.createError({
                message: intl.formatMessage({id: 'ECOMMERCE.VALIDATION.BSB2REQUIRED'}),
              })
            else if (!digitPattern.test(value))
              return this.createError({
                message: intl.formatMessage({id: 'ECOMMERCE.VALIDATION.BSB2MUSTBENUMERIC'}),
              })
            else if (value.length !== 3)
              return this.createError({
                message: intl.formatMessage({id: 'ECOMMERCE.VALIDATION.BSB2MUSTBEREQUIRED'}),
              })
            return true
          },
        }),
      }),
    bankAccountName: yup
      .mixed()
      .when(['isAllowedNoBankFeatureEnable', 'bankAccountNumber'], {
        is: (isAllowedNoBankFeatureEnable: any, bankAccountNumber: string) => {
          return !isAllowedNoBankFeatureEnable || bankAccountNumber.length > 0
        },   
        then: yup.string().required(intl.formatMessage({id: 'ECOMMERCE.VALIDATION.BANKACCOUNTNAMEREQUIRED'})),
      }),
      
    bankAccountNumber: yup
      .mixed()
      .when(['isAllowedNoBankFeatureEnable', 'bsb2'], {
        is: (isAllowedNoBankFeatureEnable: any, bsb2: string) => {
          return !isAllowedNoBankFeatureEnable  || bsb2.length > 0 
        },   
        then: yup.string()
          .required(intl.formatMessage({id: 'ECOMMERCE.VALIDATION.BANKACCOUNTNUMBERREQUIRED'}))
          .matches(
            /^\d*$/,
            intl.formatMessage({
              id: 'ECOMMERCE.VALIDATION.BANKACCOUNTNUMBERSHOULDONLYCONTAINNUMBERS',
            })
          ),
      })
  })
}
 