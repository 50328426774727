import {Grid, GridColumn as Column, GridCellProps} from '@progress/kendo-react-grid'

import {useIntl} from 'react-intl'

import {AmountFormatCell} from '../../../../shared/components/kendo/format/AmountFormatCell'
import {UppercaseFirstLetter} from '../../../../shared/components/kendo/format/UppercaseFirstLetter'
import {KendoI18Provider} from '../../../../../_metronic/i18n/kendoI18Provider'
import {DateFormatCell} from '../../../../shared/components/kendo/format/DateFormatCell'
import {IDebitWallet} from '../../models/debit-wallet'

interface IProps {
  debitData: IDebitWallet | undefined
}

const OrderList: React.FC<IProps> = ({debitData}) => {
  const intl = useIntl()

  const amountFormatCell = (dataProps: GridCellProps) => (
    <AmountFormatCell props={dataProps} currency=' '></AmountFormatCell>
  )

  return (
    <KendoI18Provider>
      <h2>{intl.formatMessage({id: 'ECOMMERCE.FIELD.RECENTORDER'})}</h2>
      <Grid data={debitData?.orders} className='mt-4'>
        <Column title={intl.formatMessage({id: 'ECOMMERCE.FIELD.ORDERNUMBER'})} field='uuid' />
        <Column
          title={intl.formatMessage({id: 'ECOMMERCE.FIELD.ORDERDATE'})}
          field='createdDate'
          format={'{0:dd/MM/yyy}'}
          cell={DateFormatCell}
          filterable={false}
        />
        <Column
          title={intl.formatMessage({id: 'ECOMMERCE.FIELD.STATUS'})}
          field='state'
          cell={UppercaseFirstLetter}
        />
        <Column title={intl.formatMessage({id: 'ECOMMERCE.FIELD.RECEIVER'})} field='sellerName' />
        <Column
          title={intl.formatMessage({id: 'ECOMMERCE.FIELD.CURRENCY'})}
          field='internalCurrency'
          filterable={false}
        />
        <Column
          field='amount'
          title={intl.formatMessage({id: 'ECOMMERCE.FIELD.SELL'})}
          cell={amountFormatCell}
          width={150}
          filterable={false}
        />
      </Grid>
    </KendoI18Provider>
  )
}

export default OrderList
