import { FC } from 'react'

import { Form } from './partials/Form'

const MasterWalletWrapper: FC = () => {
  return (
    <div className='card card-custom'>
      <div className='card-body'>
        <div className='my-profile container-fluid px-0 mb-10'>
          <Form />
        </div>
      </div>
    </div>
  )
}

export default MasterWalletWrapper
